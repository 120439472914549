import { toast } from 'react-toastify';
import {
  CreateRecipeInput,
  GetRecipesByOrganisationIdDocument,
  GetRecipesByOrganisationIdQuery,
  GetRecipesByOrganisationIdQueryVariables,
  Recipe,
  RecipesByOrganisationIdEdge,
  UpdateRecipeInput,
  useCreateRecipeMutation,
  useUpdateRecipeMutation,
} from '../../../graphql/generated';
import { updatePagedQuery } from '../../../shared/utilities/ApolloCacheUtilities';
import { recipeUpdateValidator, recipeValidator } from '../validation/RecipeValidator';
import RecipeFormContent from './RecipeFormContent';

type Props = {
  currentOrganisation: string;
  initialValues?: Recipe;
  isOpen: boolean;
  onClose: () => void;
};

export default function RecipeFormDialog({ currentOrganisation, initialValues, isOpen, onClose }: Props) {
  const [createRecipe] = useCreateRecipeMutation();
  const [updateRecipe] = useUpdateRecipeMutation();

  if (initialValues) {
    return (
      <RecipeFormContent<UpdateRecipeInput>
        isEdit
        initialValues={initialValues}
        initialValuesMapper={(initialValues) =>
          recipeUpdateValidator.cast(
            {
              ...initialValues,
              id: initialValues?.id,
              concentrations: initialValues?.concentrations.map((concentration) => ({
                chemicalId: concentration?.chemical.id,
                amount: concentration.amount,
              })),
            },
            { stripUnknown: true },
          ) as UpdateRecipeInput
        }
        validator={recipeUpdateValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          await updateRecipe({
            variables: {
              input: value,
            },
            onCompleted(data) {
              toast(`A recipe with name - ${data.updateRecipe.name} has been successfully updated!`, {
                type: 'success',
              });
            },
          });
        }}
        currentOrganisation={currentOrganisation}
      />
    );
  } else {
    return (
      <RecipeFormContent<CreateRecipeInput>
        currentOrganisation={currentOrganisation}
        initialValues={initialValues}
        initialValuesMapper={() =>
          recipeValidator.cast({
            organisationId: currentOrganisation,
            concentrations: [], // Required to make the list input work for some reason
          }) as CreateRecipeInput
        }
        validator={recipeValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          await createRecipe({
            variables: {
              input: value,
            },
            onCompleted(data) {
              toast(`A new recipe with name - ${data.createRecipe.name} has been successfully created!`, {
                type: 'success',
              });
            },
            update: (_, { data }) =>
              updatePagedQuery<
                Recipe,
                GetRecipesByOrganisationIdQuery,
                GetRecipesByOrganisationIdQueryVariables,
                RecipesByOrganisationIdEdge
              >(
                data?.createRecipe as Recipe,
                GetRecipesByOrganisationIdDocument,
                (query) => (query?.recipesByOrganisationId?.edges as RecipesByOrganisationIdEdge[]) ?? [],
                'recipesByOrganisationId',
                {
                  id: currentOrganisation,
                },
              ),
          });
        }}
      />
    );
  }
}
