import moment from 'moment';
import { useMemo } from 'react';
import { UnitSystem, UnitType, useGetTopSprayersQuery, UserSummary } from '../../../graphql/generated';
import DateOfForm, { DateOfType } from '../../../shared/components/forms/DateOfForm';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';
import RSTable, { RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import { DateTimeWithTimezone } from '../../../shared/utilities/TimeUtilities';
import { userRoleToText } from '../../users/utilities/UserRoleUtilities';
import { GetUnitSymbol } from '../../../shared/utilities/UnitConversionUtilities';

interface TopSprayersTableProps {
  organisationId: string;
}

const SprayersUnitSystemToUnitType: { [key in UnitSystem]: { volume: UnitType } } = {
  [UnitSystem.Imperial]: { volume: UnitType.UsGallon },
  [UnitSystem.Metric]: { volume: UnitType.Litre },
};

export default function TopSprayersTable({ organisationId }: TopSprayersTableProps) {
  const monthOf = useMemo(() => moment(), []);
  const { unitSystem } = useSelectedOrganisationIdStore();

  const { data, fetchMore, refetch } = useGetTopSprayersQuery({
    variables: {
      input: {
        organisationId,
        // Use the start of the month so that new queries aren't made for the same month based on a change in day
        monthOf: monthOf.clone().startOf('month').format(DateTimeWithTimezone),
      },
    },
  });

  const units = SprayersUnitSystemToUnitType[unitSystem];

  const columns: RSTableColumnDefinition<UserSummary>[] = [
    {
      id: 'fullName',
      title: 'Name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      accessor: (x) => x.user.fullName,
    },
    {
      id: 'role',
      title: 'Role',
      accessor: (x) => userRoleToText[x.user.role],
    },
    {
      id: 'volumeSprayed',
      title: `Volume Sprayed (${GetUnitSymbol(units.volume)})`,
      accessor: (x) => x.volume.value.toFixed(2),
    },
  ];

  return (
    <GridTile
      colSpan={3}
      title={'Monthly Top Sprayers'}
      actions={
        <DateOfForm
          initialDate={monthOf}
          type={DateOfType.month}
          onSubmit={({ dateOf }) =>
            refetch({
              input: {
                organisationId,
                monthOf: moment(dateOf).startOf('month').format(DateTimeWithTimezone),
              },
            })
          }
        />
      }
    >
      <RSTable
        idAccessor={(x) => x.user.id}
        onClickPathPrefix={`${localRoutingConstants.users.root}`}
        data={(data?.topSprayers?.edges?.map((e) => e.node) ?? []) as UserSummary[]}
        totalCount={data?.topSprayers?.totalCount}
        columns={columns}
        fetchMore={async (_, pageSize, increment) => {
          await fetchMore({
            variables: {
              first: pageSize,
              after: increment ? (data?.topSprayers?.pageInfo.endCursor as string) : undefined,
            },
          });
        }}
      />
    </GridTile>
  );
}
