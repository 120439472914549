/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Long: any;
  SemanticVersion: any;
};

export type ActivateDeviceInput = {
  activationToken: Scalars['String'];
  deviceId: Scalars['String'];
  organisationId: Scalars['String'];
};

/** A connection to a list of items. */
export type ActiveJobsConnection = {
  __typename?: 'ActiveJobsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ActiveJobsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Job>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ActiveJobsEdge = {
  __typename?: 'ActiveJobsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Job;
};

export type AdminUpdateDeviceInput = {
  alias: Scalars['String'];
  calibrationTimeout: Scalars['Int'];
  calibrationVolume: UnitMeasurementInput;
  firmwareId: Scalars['String'];
  firmwareLockStatus: FirmwareLockStatus;
  gpsAccuracy: UnitMeasurementInput;
  id: Scalars['String'];
  loggingPeriod: Scalars['Int'];
  rinseTimeout: Scalars['Int'];
  rinseVolume: UnitMeasurementInput;
  sleepTimeout: Scalars['Int'];
};

export enum ApplicationType {
  BasalBark = 'BASAL_BARK',
  Broadcast = 'BROADCAST',
  FoliarHighVolume = 'FOLIAR_HIGH_VOLUME',
  FoliarLowVolume = 'FOLIAR_LOW_VOLUME',
  SoilInjection = 'SOIL_INJECTION',
  SpotTreatment = 'SPOT_TREATMENT',
  Unspecified = 'UNSPECIFIED',
}

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
}

export type BatchExportJobsInput = {
  emails: Array<Scalars['String']>;
  jobIds: Array<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  selectAll: Scalars['Boolean'];
};

export type BatchSetOrganisationFirmwareInput = {
  firmwareId: Scalars['String'];
  lockStatus: FirmwareLockStatus;
  organisationIds: Array<Scalars['String']>;
  selectAll: Scalars['Boolean'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type CandidateFirmware = {
  __typename?: 'CandidateFirmware';
  createInformation: CreateInformation;
  firmware: Firmware;
  notifiedAt?: Maybe<Scalars['DateTime']>;
  slot: SlotType;
};

export type Chemical = {
  __typename?: 'Chemical';
  activeIngredient: Scalars['String'];
  deleteInformation?: Maybe<SoftDeleteInformation>;
  description: Scalars['String'];
  id: Scalars['ID'];
  manufacturer: Scalars['String'];
  name: Scalars['String'];
  organisation: Organisation;
  recipes?: Maybe<RecipesConnection>;
  type: ChemicalType;
  version: Scalars['String'];
};

export type ChemicalRecipesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ChemicalFilterInput = {
  activeIngredient?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<ChemicalFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  manufacturer?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ChemicalFilterInput>>;
};

export type ChemicalSortInput = {
  activeIngredient?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  manufacturer?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export enum ChemicalType {
  Liquid = 'LIQUID',
  Solid = 'SOLID',
}

export type ChemicalUsage = {
  __typename?: 'ChemicalUsage';
  chemical: Chemical;
  organisationId: Scalars['String'];
  usage?: Maybe<Array<Maybe<KeyValuePairOfDateTimeAndUnitMeasurement>>>;
};

/** A connection to a list of items. */
export type ChemicalsByOrganisationIdConnection = {
  __typename?: 'ChemicalsByOrganisationIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ChemicalsByOrganisationIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Chemical>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ChemicalsByOrganisationIdEdge = {
  __typename?: 'ChemicalsByOrganisationIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Chemical;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<Scalars['Int']>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableNullableOfSingleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableSingleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<Scalars['Float']>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type CompleteJobInput = {
  id: Scalars['String'];
};

export type Concentration = ConcentrationInterface & {
  __typename?: 'Concentration';
  amount: UnitMeasurement;
  chemical: Chemical;
  chemicalType: ChemicalType;
};

export type ConcentrationInput = {
  amount: UnitMeasurementInput;
  chemicalId: Scalars['String'];
};

export type ConcentrationInterface = {
  amount: UnitMeasurement;
  chemical: Chemical;
  chemicalType: ChemicalType;
};

export type ConcentrationSummary = ConcentrationInterface & {
  __typename?: 'ConcentrationSummary';
  amount: UnitMeasurement;
  chemical: Chemical;
  chemicalType: ChemicalType;
};

export type CoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CreateChemicalInput = {
  activeIngredient: Scalars['String'];
  description: Scalars['String'];
  manufacturer: Scalars['String'];
  name: Scalars['String'];
  organisationId: Scalars['String'];
  type: ChemicalType;
};

export type CreateDeviceInput = {
  alias: Scalars['String'];
  calibrationTimeout: Scalars['Int'];
  calibrationVolume: UnitMeasurementInput;
  firmwareId: Scalars['String'];
  firmwareLockStatus: FirmwareLockStatus;
  gpsAccuracy: UnitMeasurementInput;
  imei: Scalars['String'];
  loggingPeriod: Scalars['Int'];
  rinseTimeout: Scalars['Int'];
  rinseVolume: UnitMeasurementInput;
  sleepTimeout: Scalars['Int'];
};

export type CreateFirmwareInput = {
  description: Scalars['String'];
  version: Scalars['SemanticVersion'];
};

export type CreateFirmwareOutput = {
  __typename?: 'CreateFirmwareOutput';
  createInformation: CreateInformation;
  description: Scalars['String'];
  id: Scalars['ID'];
  slot1ObjectKey: Scalars['String'];
  slot2ObjectKey: Scalars['String'];
  status: FirmwareStatus;
  uploadUrl: Scalars['String'];
  version: Scalars['SemanticVersion'];
};

export type CreateInformation = {
  __typename?: 'CreateInformation';
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
};

export type CreateInformationFilterInput = {
  and?: InputMaybe<Array<CreateInformationFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  createdBy?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CreateInformationFilterInput>>;
};

export type CreateInformationSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<SortEnumType>;
};

export type CreateJobInput = {
  applicationType: ApplicationType;
  description: Scalars['String'];
  deviceAssignments: Array<DeviceAssignmentInput>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  location?: InputMaybe<LocationInput>;
  name: Scalars['String'];
  organisationId: Scalars['String'];
  recipeId?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  weedAssignments: Array<InputMaybe<Scalars['String']>>;
};

export type CreateOrganisationInput = {
  address: Scalars['String'];
  businessNumber: Scalars['String'];
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  unitSystem: UnitSystem;
};

export type CreateRecipeInput = {
  concentrations: Array<ConcentrationInput>;
  description: Scalars['String'];
  name: Scalars['String'];
  organisationId: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organisationId?: InputMaybe<Scalars['String']>;
  role: UserRole;
};

export type CreateWebhookInput = {
  description?: InputMaybe<Scalars['String']>;
  events: Array<WebhookEventKind>;
  name: Scalars['String'];
  organisationId: Scalars['String'];
  status: WebhookStatus;
  url: Scalars['String'];
};

export type CreateWebhookResponse = {
  __typename?: 'CreateWebhookResponse';
  apiKey: Scalars['String'];
  apiKeyHeader: Scalars['String'];
  webhook: Webhook;
};

export type CreateWeedInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  organisationId: Scalars['String'];
  species: Scalars['String'];
  updateImage: Scalars['Boolean'];
};

export type DeleteJobInput = {
  id: Scalars['String'];
};

export type DeleteWebhookInput = {
  id: Scalars['String'];
};

export type Device = {
  __typename?: 'Device';
  activationDate?: Maybe<Scalars['DateTime']>;
  activationToken?: Maybe<Scalars['String']>;
  alias: Scalars['String'];
  authenticationToken?: Maybe<Scalars['String']>;
  calibrationTimeout: Scalars['Int'];
  calibrationVolume: UnitMeasurement;
  firmwareInformation: FirmwareInformation;
  gpsAccuracy: UnitMeasurement;
  id: Scalars['ID'];
  jobs?: Maybe<JobsConnection>;
  lastCalibration?: Maybe<LastCalibrationDto>;
  loggingPeriod: Scalars['Int'];
  organisation?: Maybe<Organisation>;
  registrationDate: Scalars['DateTime'];
  rinseTimeout: Scalars['Int'];
  rinseVolume: UnitMeasurement;
  sleepTimeout: Scalars['Int'];
  version: Scalars['String'];
};

export type DeviceJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeviceAssignment = {
  __typename?: 'DeviceAssignment';
  continuity?: Maybe<Scalars['Float']>;
  device: Device;
  deviceId: Scalars['String'];
  endTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  jobId: Scalars['String'];
  organisationId: Scalars['String'];
  sprayLogs: Array<Maybe<SprayLogDto>>;
  startTime?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
  user: User;
  userId: Scalars['String'];
  volumeDispensed?: Maybe<UnitMeasurement>;
};

export type DeviceAssignmentFilterInput = {
  and?: InputMaybe<Array<DeviceAssignmentFilterInput>>;
  continuity?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  deviceId?: InputMaybe<StringOperationFilterInput>;
  endTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  jobId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceAssignmentFilterInput>>;
  organisationId?: InputMaybe<StringOperationFilterInput>;
  sprayLogs?: InputMaybe<ListFilterInputTypeOfSprayLogSummaryFilterInput>;
  startTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  status?: InputMaybe<JobStatusOperationFilterInput>;
  userId?: InputMaybe<StringOperationFilterInput>;
  volumeDispensed?: InputMaybe<ComparableNullableOfSingleOperationFilterInput>;
  weedAssignments?: InputMaybe<ListStringOperationFilterInput>;
};

export type DeviceAssignmentInput = {
  deviceId: Scalars['String'];
  userId: Scalars['String'];
};

export type DeviceFilterInput = {
  alias?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<DeviceFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DeviceFilterInput>>;
  registrationDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

export type DeviceSortInput = {
  alias?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  registrationDate?: InputMaybe<SortEnumType>;
};

export type DeviceUnassignedSprayLogSummary = {
  __typename?: 'DeviceUnassignedSprayLogSummary';
  device: Device;
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
  volumeDispensed?: Maybe<UnitMeasurement>;
};

/** A connection to a list of items. */
export type DevicesByOrganisationIdConnection = {
  __typename?: 'DevicesByOrganisationIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<DevicesByOrganisationIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Device>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DevicesByOrganisationIdEdge = {
  __typename?: 'DevicesByOrganisationIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Device;
};

/** A connection to a list of items. */
export type DevicesConnection = {
  __typename?: 'DevicesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<DevicesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Device>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DevicesEdge = {
  __typename?: 'DevicesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Device;
};

export type DisableFirmwareInput = {
  id: Scalars['String'];
};

/** A connection to a list of items. */
export type EnabledFirmwareConnection = {
  __typename?: 'EnabledFirmwareConnection';
  /** A list of edges. */
  edges?: Maybe<Array<EnabledFirmwareEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Firmware>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type EnabledFirmwareEdge = {
  __typename?: 'EnabledFirmwareEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Firmware;
};

/** A connection to a list of items. */
export type ExistingJobsConnection = {
  __typename?: 'ExistingJobsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ExistingJobsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Job>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ExistingJobsEdge = {
  __typename?: 'ExistingJobsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Job;
};

export type Firmware = {
  __typename?: 'Firmware';
  createInformation: CreateInformation;
  description: Scalars['String'];
  id: Scalars['ID'];
  slot1ObjectKey: Scalars['String'];
  slot2ObjectKey: Scalars['String'];
  status: FirmwareStatus;
  user: User;
  version: Scalars['SemanticVersion'];
};

/** A connection to a list of items. */
export type FirmwareConnection = {
  __typename?: 'FirmwareConnection';
  /** A list of edges. */
  edges?: Maybe<Array<FirmwareEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Firmware>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type FirmwareEdge = {
  __typename?: 'FirmwareEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Firmware;
};

export type FirmwareFault = {
  __typename?: 'FirmwareFault';
  id: Scalars['Int'];
  message: Scalars['String'];
};

export type FirmwareFilterInput = {
  and?: InputMaybe<Array<FirmwareFilterInput>>;
  createInformation?: InputMaybe<CreateInformationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FirmwareFilterInput>>;
  slot1ObjectKey?: InputMaybe<StringOperationFilterInput>;
  slot2ObjectKey?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<FirmwareStatusOperationFilterInput>;
  version?: InputMaybe<StringOperationFilterInput>;
};

export type FirmwareInformation = {
  __typename?: 'FirmwareInformation';
  candidate?: Maybe<CandidateFirmware>;
  lockStatus: FirmwareLockStatus;
  slot1: SlotInformation;
  slot2: SlotInformation;
};

export enum FirmwareInstallStatus {
  Failed = 'FAILED',
  Faulted = 'FAULTED',
  Installed = 'INSTALLED',
}

export enum FirmwareLockStatus {
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED',
}

export type FirmwareSortInput = {
  createInformation?: InputMaybe<CreateInformationSortInput>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  slot1ObjectKey?: InputMaybe<SortEnumType>;
  slot2ObjectKey?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  version?: InputMaybe<SortEnumType>;
};

export enum FirmwareStatus {
  Available = 'AVAILABLE',
  Deprecated = 'DEPRECATED',
  Error = 'ERROR',
  Uploading = 'UPLOADING',
}

export type FirmwareStatusOperationFilterInput = {
  eq?: InputMaybe<FirmwareStatus>;
  in?: InputMaybe<Array<FirmwareStatus>>;
  neq?: InputMaybe<FirmwareStatus>;
  nin?: InputMaybe<Array<FirmwareStatus>>;
};

export type GetChemicalUsageInput = {
  chemicalId: Scalars['String'];
  monthOf: Scalars['DateTime'];
};

export type GetFirmwareInput = {
  id: Scalars['String'];
};

export type GetRecipeUsageInput = {
  monthOf: Scalars['DateTime'];
  recipeId: Scalars['String'];
};

export type Job = JobInterface & {
  __typename?: 'Job';
  applicationType: ApplicationType;
  areaSprayed?: Maybe<UnitMeasurement>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  deviceAssignments: Array<Maybe<DeviceAssignment>>;
  endTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Location>;
  name: Scalars['String'];
  nozzleWidth?: Maybe<UnitMeasurement>;
  organisation: Organisation;
  recipe?: Maybe<RecipeInterface>;
  sharedOrganisations?: Maybe<Array<Maybe<OrganisationShare>>>;
  startTime?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
  version: Scalars['String'];
  volumeDispensed?: Maybe<UnitMeasurement>;
  weatherInformation: Array<Maybe<WeatherInformationDto>>;
  weedAssignments: Array<Maybe<Weed>>;
};

export type JobDeviceAssignmentsArgs = {
  where?: InputMaybe<DeviceAssignmentFilterInput>;
};

export type JobFilterInput = {
  and?: InputMaybe<Array<JobFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  location?: InputMaybe<LocationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<JobFilterInput>>;
  status?: InputMaybe<JobStatusOperationFilterInput>;
};

export type JobInterface = {
  applicationType: ApplicationType;
  areaSprayed?: Maybe<UnitMeasurement>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  deviceAssignments: Array<Maybe<DeviceAssignment>>;
  endTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Location>;
  name: Scalars['String'];
  nozzleWidth?: Maybe<UnitMeasurement>;
  organisation: Organisation;
  recipe?: Maybe<RecipeInterface>;
  startTime?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
  volumeDispensed?: Maybe<UnitMeasurement>;
  weatherInformation: Array<Maybe<WeatherInformationDto>>;
  weedAssignments: Array<Maybe<Weed>>;
};

export type JobOrgShareResponseInput = {
  jobId: Scalars['String'];
  organisationId: Scalars['String'];
  status: ShareStatus;
};

export type JobSortInput = {
  endTime?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  startTime?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export enum JobStatus {
  Complete = 'COMPLETE',
  Finalised = 'FINALISED',
  Incomplete = 'INCOMPLETE',
}

export type JobStatusOperationFilterInput = {
  eq?: InputMaybe<JobStatus>;
  in?: InputMaybe<Array<JobStatus>>;
  neq?: InputMaybe<JobStatus>;
  nin?: InputMaybe<Array<JobStatus>>;
};

export type JobWithDeviceAssignments = JobInterface & {
  __typename?: 'JobWithDeviceAssignments';
  applicationType: ApplicationType;
  areaSprayed?: Maybe<UnitMeasurement>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  deviceAssignments: Array<Maybe<DeviceAssignment>>;
  endTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Location>;
  name: Scalars['String'];
  nozzleWidth?: Maybe<UnitMeasurement>;
  organisation: Organisation;
  recipe?: Maybe<RecipeInterface>;
  sharedOrganisations?: Maybe<Array<OrganisationShare>>;
  startTime?: Maybe<Scalars['DateTime']>;
  status: JobStatus;
  version: Scalars['String'];
  volumeDispensed?: Maybe<UnitMeasurement>;
  weatherInformation: Array<Maybe<WeatherInformationDto>>;
  weedAssignments: Array<Maybe<Weed>>;
};

/** A connection to a list of items. */
export type JobsByOrganisationIdConnection = {
  __typename?: 'JobsByOrganisationIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<JobsByOrganisationIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Job>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type JobsByOrganisationIdEdge = {
  __typename?: 'JobsByOrganisationIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Job;
};

/** A connection to a list of items. */
export type JobsConnection = {
  __typename?: 'JobsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<JobsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Job>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type JobsEdge = {
  __typename?: 'JobsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Job>;
};

/** A connection to a list of items. */
export type JobsSharedWithOrganisationIdConnection = {
  __typename?: 'JobsSharedWithOrganisationIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<JobsSharedWithOrganisationIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Job>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type JobsSharedWithOrganisationIdEdge = {
  __typename?: 'JobsSharedWithOrganisationIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Job;
};

export type KeyValuePairOfDateTimeAndUnitMeasurement = {
  __typename?: 'KeyValuePairOfDateTimeAndUnitMeasurement';
  key: Scalars['DateTime'];
  value: UnitMeasurement;
};

export type LastCalibration = {
  __typename?: 'LastCalibration';
  ticks: Scalars['Float'];
  time: Scalars['DateTime'];
  volumeDispensed: Scalars['Float'];
};

export type LastCalibrationDto = {
  __typename?: 'LastCalibrationDto';
  ticks: Scalars['Float'];
  time: Scalars['DateTime'];
  volumeDispensed: UnitMeasurement;
};

export type ListFilterInputTypeOfSprayLogSummaryFilterInput = {
  all?: InputMaybe<SprayLogSummaryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<SprayLogSummaryFilterInput>;
  some?: InputMaybe<SprayLogSummaryFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type ListWebhookEventKindOperationFilterInput = {
  all?: InputMaybe<WebhookEventKindOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<WebhookEventKindOperationFilterInput>;
  some?: InputMaybe<WebhookEventKindOperationFilterInput>;
};

export type Location = {
  __typename?: 'Location';
  address: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type LocationFilterInput = {
  address?: InputMaybe<StringOperationFilterInput>;
};

export type LocationInput = {
  address: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type LogCoordinates = {
  __typename?: 'LogCoordinates';
  altitude: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  satellites: Scalars['Int'];
};

export type LogCoordinatesDto = {
  __typename?: 'LogCoordinatesDto';
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: UnitMeasurement;
};

export type LogCoordinatesSummary = {
  __typename?: 'LogCoordinatesSummary';
  distance: Scalars['Float'];
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

export type LogCoordinatesSummaryDistanceArgs = {
  coordinate: LogCoordinatesSummaryInput;
};

export type LogCoordinatesSummaryFilterInput = {
  and?: InputMaybe<Array<LogCoordinatesSummaryFilterInput>>;
  or?: InputMaybe<Array<LogCoordinatesSummaryFilterInput>>;
  x?: InputMaybe<ComparableSingleOperationFilterInput>;
  y?: InputMaybe<ComparableSingleOperationFilterInput>;
  z?: InputMaybe<ComparableSingleOperationFilterInput>;
};

export type LogCoordinatesSummaryInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

/** A connection to a list of items. */
export type ManufacturingDevicesConnection = {
  __typename?: 'ManufacturingDevicesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ManufacturingDevicesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Device>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ManufacturingDevicesEdge = {
  __typename?: 'ManufacturingDevicesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Device;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateDevice: Device;
  adminUpdateDevice: Device;
  batchExportJobs: Scalars['Boolean'];
  batchSetOrganisationFirmware: Array<Organisation>;
  completeJob: Job;
  createChemical: Chemical;
  createDevice: Device;
  createFirmware: CreateFirmwareOutput;
  createJob: Job;
  createOrganisation: Organisation;
  createRecipe: Recipe;
  createUser: User;
  createWebhook: CreateWebhookResponse;
  createWeed: Weed;
  deleteChemical: Chemical;
  deleteJob: Scalars['Boolean'];
  deleteRecipe: Recipe;
  deleteWebhook: Webhook;
  disableFirmware: Firmware;
  disableOrganisation: Organisation;
  disableUser: User;
  enableOrganisation: Organisation;
  enableUser: User;
  resendInvite: User;
  respondToOrgShare: Job;
  revokeOrgShare: Job;
  setDeviceFirmwareByDeviceId: Device;
  setOrganisationFirmware: Organisation;
  shareJobWithOrganisation: Job;
  updateChemical: Chemical;
  updateDevice: Device;
  updateJob: Job;
  updateOrganisation: Organisation;
  updateRecipe: Recipe;
  updateSprayArea: Job;
  updateUser: User;
  updateWebhook: Webhook;
  updateWeed: Weed;
};

export type MutationActivateDeviceArgs = {
  input: ActivateDeviceInput;
};

export type MutationAdminUpdateDeviceArgs = {
  input: AdminUpdateDeviceInput;
};

export type MutationBatchExportJobsArgs = {
  input: BatchExportJobsInput;
};

export type MutationBatchSetOrganisationFirmwareArgs = {
  input: BatchSetOrganisationFirmwareInput;
};

export type MutationCompleteJobArgs = {
  input: CompleteJobInput;
};

export type MutationCreateChemicalArgs = {
  input: CreateChemicalInput;
};

export type MutationCreateDeviceArgs = {
  input: CreateDeviceInput;
};

export type MutationCreateFirmwareArgs = {
  input: CreateFirmwareInput;
};

export type MutationCreateJobArgs = {
  input: CreateJobInput;
};

export type MutationCreateOrganisationArgs = {
  input: CreateOrganisationInput;
};

export type MutationCreateRecipeArgs = {
  input: CreateRecipeInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};

export type MutationCreateWeedArgs = {
  input: CreateWeedInput;
};

export type MutationDeleteChemicalArgs = {
  id: Scalars['String'];
};

export type MutationDeleteJobArgs = {
  input: DeleteJobInput;
};

export type MutationDeleteRecipeArgs = {
  id: Scalars['String'];
};

export type MutationDeleteWebhookArgs = {
  input: DeleteWebhookInput;
};

export type MutationDisableFirmwareArgs = {
  input: DisableFirmwareInput;
};

export type MutationDisableOrganisationArgs = {
  input: ToggleOrganisationInput;
};

export type MutationDisableUserArgs = {
  input: ToggleUserInput;
};

export type MutationEnableOrganisationArgs = {
  input: ToggleOrganisationInput;
};

export type MutationEnableUserArgs = {
  input: ToggleUserInput;
};

export type MutationResendInviteArgs = {
  input: ResendInviteInput;
};

export type MutationRespondToOrgShareArgs = {
  input: JobOrgShareResponseInput;
};

export type MutationRevokeOrgShareArgs = {
  input: RevokeJobOrgShareInput;
};

export type MutationSetDeviceFirmwareByDeviceIdArgs = {
  input: SetDeviceFirmwareByDeviceIdInput;
};

export type MutationSetOrganisationFirmwareArgs = {
  input: SetOrganisationFirmwareInput;
};

export type MutationShareJobWithOrganisationArgs = {
  input: ShareJobInput;
};

export type MutationUpdateChemicalArgs = {
  input: UpdateChemicalInput;
};

export type MutationUpdateDeviceArgs = {
  input: UpdateDeviceInput;
};

export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};

export type MutationUpdateOrganisationArgs = {
  input: UpdateOrganisationInput;
};

export type MutationUpdateRecipeArgs = {
  input: UpdateRecipeInput;
};

export type MutationUpdateSprayAreaArgs = {
  input: UpdateSprayAreaInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};

export type MutationUpdateWeedArgs = {
  input: UpdateWeedInput;
};

/** A connection to a list of items. */
export type MyJobsConnection = {
  __typename?: 'MyJobsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<MyJobsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Job>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type MyJobsEdge = {
  __typename?: 'MyJobsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Job;
};

export type Organisation = {
  __typename?: 'Organisation';
  address: Scalars['String'];
  businessNumber: Scalars['String'];
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  description: Scalars['String'];
  devices: Array<Maybe<Device>>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organisationFirmware?: Maybe<OrganisationFirmware>;
  unitSystem: UnitSystem;
  users: Array<Maybe<User>>;
  version: Scalars['String'];
};

export type OrganisationFilterInput = {
  and?: InputMaybe<Array<OrganisationFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganisationFilterInput>>;
};

export type OrganisationFirmware = {
  __typename?: 'OrganisationFirmware';
  firmware: Firmware;
  lockStatus: FirmwareLockStatus;
};

export type OrganisationShare = {
  __typename?: 'OrganisationShare';
  lastDeclined?: Maybe<Scalars['DateTime']>;
  lastShared: Scalars['DateTime'];
  organisation: Organisation;
  status: ShareStatus;
};

export type OrganisationSortInput = {
  description?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type OrganisationsConnection = {
  __typename?: 'OrganisationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<OrganisationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Organisation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type OrganisationsEdge = {
  __typename?: 'OrganisationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Organisation;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activeJobs?: Maybe<ActiveJobsConnection>;
  chemical?: Maybe<Chemical>;
  chemicalUsage: ChemicalUsage;
  chemicalsByOrganisationId?: Maybe<ChemicalsByOrganisationIdConnection>;
  device?: Maybe<Device>;
  deviceCount: Scalars['Long'];
  devices?: Maybe<DevicesConnection>;
  devicesByOrganisationId?: Maybe<DevicesByOrganisationIdConnection>;
  enabledFirmware?: Maybe<EnabledFirmwareConnection>;
  existingJobs?: Maybe<ExistingJobsConnection>;
  firmware?: Maybe<FirmwareConnection>;
  firmwareById: Firmware;
  job?: Maybe<Job>;
  jobsByOrganisationId?: Maybe<JobsByOrganisationIdConnection>;
  jobsSharedWithOrganisationId?: Maybe<JobsSharedWithOrganisationIdConnection>;
  manufacturerDevice?: Maybe<Device>;
  manufacturingDevices?: Maybe<ManufacturingDevicesConnection>;
  myJobs?: Maybe<MyJobsConnection>;
  organisation?: Maybe<Organisation>;
  organisationCount: Scalars['Long'];
  organisations?: Maybe<OrganisationsConnection>;
  recipe?: Maybe<Recipe>;
  recipeUsage: RecipeUsage;
  recipeUsageSummary: Array<RecipeUsageSummary>;
  recipesByOrganisationId?: Maybe<RecipesByOrganisationIdConnection>;
  sprayLogsForDevice?: Maybe<SprayLogsForDeviceConnection>;
  topSprayers?: Maybe<TopSprayersConnection>;
  unassignedSprayLogs: Array<DeviceUnassignedSprayLogSummary>;
  user?: Maybe<User>;
  userCount: Scalars['Long'];
  users?: Maybe<UsersConnection>;
  usersByOrganisationId?: Maybe<UsersByOrganisationIdConnection>;
  webhookById?: Maybe<Webhook>;
  webhooksByOrganisationId?: Maybe<WebhooksByOrganisationIdConnection>;
  weed?: Maybe<Weed>;
  weedByCoordinates: Array<JobWithDeviceAssignments>;
  weedsByOrganisationId?: Maybe<WeedsByOrganisationIdConnection>;
};

export type QueryActiveJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<JobSortInput>>;
  where?: InputMaybe<JobFilterInput>;
};

export type QueryChemicalArgs = {
  id: Scalars['String'];
};

export type QueryChemicalUsageArgs = {
  input: GetChemicalUsageInput;
};

export type QueryChemicalsByOrganisationIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<ChemicalSortInput>>;
  where?: InputMaybe<ChemicalFilterInput>;
};

export type QueryDeviceArgs = {
  id: Scalars['String'];
};

export type QueryDevicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<DeviceSortInput>>;
  where?: InputMaybe<DeviceFilterInput>;
};

export type QueryDevicesByOrganisationIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<DeviceSortInput>>;
  where?: InputMaybe<DeviceFilterInput>;
};

export type QueryEnabledFirmwareArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<FirmwareSortInput>>;
  where?: InputMaybe<FirmwareFilterInput>;
};

export type QueryExistingJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<JobSortInput>>;
  searchString: Scalars['String'];
  where?: InputMaybe<JobFilterInput>;
};

export type QueryFirmwareArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<FirmwareSortInput>>;
  where?: InputMaybe<FirmwareFilterInput>;
};

export type QueryFirmwareByIdArgs = {
  input: GetFirmwareInput;
};

export type QueryJobArgs = {
  id: Scalars['String'];
  shared?: InputMaybe<Scalars['Boolean']>;
};

export type QueryJobsByOrganisationIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<JobSortInput>>;
  where?: InputMaybe<JobFilterInput>;
};

export type QueryJobsSharedWithOrganisationIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<JobSortInput>>;
  where?: InputMaybe<JobFilterInput>;
};

export type QueryManufacturerDeviceArgs = {
  id: Scalars['String'];
};

export type QueryManufacturingDevicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<DeviceSortInput>>;
  where?: InputMaybe<DeviceFilterInput>;
};

export type QueryMyJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<JobSortInput>>;
  where?: InputMaybe<JobFilterInput>;
};

export type QueryOrganisationArgs = {
  id: Scalars['String'];
};

export type QueryOrganisationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<OrganisationSortInput>>;
  where?: InputMaybe<OrganisationFilterInput>;
};

export type QueryRecipeArgs = {
  id: Scalars['String'];
};

export type QueryRecipeUsageArgs = {
  input: GetRecipeUsageInput;
};

export type QueryRecipeUsageSummaryArgs = {
  input: RecipeUsageSummaryInput;
};

export type QueryRecipesByOrganisationIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<RecipeSortInput>>;
  where?: InputMaybe<RecipeFilterInput>;
};

export type QuerySprayLogsForDeviceArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  deviceId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryTopSprayersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input: TopSprayersInput;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryUnassignedSprayLogsArgs = {
  input: UnassignedSprayLogInput;
};

export type QueryUserArgs = {
  id: Scalars['String'];
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};

export type QueryUsersByOrganisationIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<UserSortInput>>;
  where?: InputMaybe<UserFilterInput>;
};

export type QueryWebhookByIdArgs = {
  id: Scalars['String'];
};

export type QueryWebhooksByOrganisationIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<WebhookSortInput>>;
  organisationId: Scalars['String'];
  where?: InputMaybe<WebhookFilterInput>;
};

export type QueryWeedArgs = {
  id: Scalars['String'];
};

export type QueryWeedByCoordinatesArgs = {
  input: WeedByCoordinatesInput;
};

export type QueryWeedsByOrganisationIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  id: Scalars['String'];
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Array<WeedSortInput>>;
  where?: InputMaybe<WeedFilterInput>;
};

export type Recipe = RecipeInterface & {
  __typename?: 'Recipe';
  concentrations: Array<Concentration>;
  deleteInformation?: Maybe<SoftDeleteInformation>;
  description: Scalars['String'];
  id: Scalars['ID'];
  jobs?: Maybe<JobsConnection>;
  name: Scalars['String'];
  organisation: Organisation;
  version: Scalars['String'];
};

export type RecipeJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type RecipeFilterInput = {
  and?: InputMaybe<Array<RecipeFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RecipeFilterInput>>;
};

export type RecipeInterface = {
  concentrations: Array<Maybe<ConcentrationInterface>>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organisation: Organisation;
};

export type RecipeSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type RecipeSummary = RecipeInterface & {
  __typename?: 'RecipeSummary';
  concentrations: Array<Maybe<ConcentrationSummary>>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organisation: Organisation;
};

export type RecipeUsage = {
  __typename?: 'RecipeUsage';
  organisationId: Scalars['String'];
  recipe: Recipe;
  usage?: Maybe<Array<Maybe<KeyValuePairOfDateTimeAndUnitMeasurement>>>;
};

export type RecipeUsageSummary = {
  __typename?: 'RecipeUsageSummary';
  recipe: RecipeInterface;
  volume?: Maybe<UnitMeasurement>;
};

export type RecipeUsageSummaryInput = {
  monthOf: Scalars['DateTime'];
  organisationId: Scalars['String'];
};

/** A connection to a list of items. */
export type RecipesByOrganisationIdConnection = {
  __typename?: 'RecipesByOrganisationIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RecipesByOrganisationIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Recipe>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RecipesByOrganisationIdEdge = {
  __typename?: 'RecipesByOrganisationIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Recipe;
};

/** A connection to a list of items. */
export type RecipesConnection = {
  __typename?: 'RecipesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RecipesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Maybe<Recipe>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RecipesEdge = {
  __typename?: 'RecipesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Recipe>;
};

export type ResendInviteInput = {
  userId: Scalars['String'];
};

export type RevokeJobOrgShareInput = {
  jobId: Scalars['String'];
  organisationId: Scalars['String'];
};

export type SetDeviceFirmwareByDeviceIdInput = {
  deviceId: Scalars['String'];
  firmwareId: Scalars['String'];
};

export type SetOrganisationFirmwareInput = {
  firmwareId: Scalars['String'];
  lockStatus: FirmwareLockStatus;
  organisationId: Scalars['String'];
};

export type ShareJobInput = {
  emails: Array<Scalars['String']>;
  jobId: Scalars['String'];
};

export enum ShareStatus {
  Accepted = 'ACCEPTED',
  Denied = 'DENIED',
  Waiting = 'WAITING',
}

export type SlotInformation = {
  __typename?: 'SlotInformation';
  fault?: Maybe<FirmwareFault>;
  firmware: Firmware;
  reportedAt: Scalars['DateTime'];
  status: FirmwareInstallStatus;
};

export enum SlotType {
  Slot1 = 'SLOT1',
  Slot2 = 'SLOT2',
}

export type SoftDeleteInformation = {
  __typename?: 'SoftDeleteInformation';
  deletedAt: Scalars['DateTime'];
  deletedBy?: Maybe<User>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SprayLog = {
  __typename?: 'SprayLog';
  coordinates: LogCoordinates;
  deviceId: Scalars['String'];
  sprayMode: SprayMode;
  temperature?: Maybe<UnitMeasurement>;
  timestamp: Scalars['DateTime'];
  volumeDispensed?: Maybe<UnitMeasurement>;
  wasDropped: Scalars['Boolean'];
  weeds: Scalars['Int'];
};

export type SprayLogDto = {
  __typename?: 'SprayLogDto';
  c: LogCoordinatesDto;
  d: Scalars['Boolean'];
  t: Scalars['DateTime'];
  v: UnitMeasurement;
  w: Scalars['Int'];
};

export type SprayLogSummary = {
  __typename?: 'SprayLogSummary';
  c: LogCoordinatesSummary;
  d: Scalars['Boolean'];
  t: Scalars['DateTime'];
  v: Scalars['Float'];
  w: Scalars['Int'];
};

export type SprayLogSummaryFilterInput = {
  and?: InputMaybe<Array<SprayLogSummaryFilterInput>>;
  c?: InputMaybe<LogCoordinatesSummaryFilterInput>;
  d?: InputMaybe<BooleanOperationFilterInput>;
  deviceId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SprayLogSummaryFilterInput>>;
  t?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  v?: InputMaybe<ComparableSingleOperationFilterInput>;
  w?: InputMaybe<ComparableInt32OperationFilterInput>;
};

/** A connection to a list of items. */
export type SprayLogsForDeviceConnection = {
  __typename?: 'SprayLogsForDeviceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SprayLogsForDeviceEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SprayLog>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SprayLogsForDeviceEdge = {
  __typename?: 'SprayLogsForDeviceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SprayLog;
};

export enum SprayMode {
  Calibration = 'CALIBRATION',
  Normal = 'NORMAL',
  PinDrop = 'PIN_DROP',
  Rinse = 'RINSE',
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type ToggleOrganisationInput = {
  organisationId: Scalars['String'];
};

export type ToggleUserInput = {
  userId: Scalars['String'];
};

/** A connection to a list of items. */
export type TopSprayersConnection = {
  __typename?: 'TopSprayersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<TopSprayersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<UserSummary>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TopSprayersEdge = {
  __typename?: 'TopSprayersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: UserSummary;
};

export type TopSprayersInput = {
  monthOf: Scalars['DateTime'];
  organisationId: Scalars['String'];
};

export type UnassignedSprayLogInput = {
  endTime: Scalars['DateTime'];
  organisationId: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export type UnitMeasurement = {
  __typename?: 'UnitMeasurement';
  unit: UnitType;
  value: Scalars['Float'];
};

export type UnitMeasurementInput = {
  unit: UnitType;
  value: Scalars['Float'];
};

export enum UnitSystem {
  Imperial = 'IMPERIAL',
  Metric = 'METRIC',
}

export enum UnitType {
  Acre = 'ACRE',
  Celsius = 'CELSIUS',
  Degree = 'DEGREE',
  Fahrenheit = 'FAHRENHEIT',
  Feet = 'FEET',
  FluidOunce = 'FLUID_OUNCE',
  FluidOuncesPerUsGallon = 'FLUID_OUNCES_PER_US_GALLON',
  Gram = 'GRAM',
  GramsPerHundredLitres = 'GRAMS_PER_HUNDRED_LITRES',
  Hectare = 'HECTARE',
  Kilometer = 'KILOMETER',
  Litre = 'LITRE',
  Metre = 'METRE',
  MetresPerSecond = 'METRES_PER_SECOND',
  Mile = 'MILE',
  MilesPerHour = 'MILES_PER_HOUR',
  Millilitre = 'MILLILITRE',
  MillilitresPerHundredLitres = 'MILLILITRES_PER_HUNDRED_LITRES',
  Ounce = 'OUNCE',
  OuncePerUsGallon = 'OUNCE_PER_US_GALLON',
  Percent = 'PERCENT',
  SquareFeet = 'SQUARE_FEET',
  SquareMetre = 'SQUARE_METRE',
  UsGallon = 'US_GALLON',
}

export type UpdateChemicalInput = {
  activeIngredient: Scalars['String'];
  chemicalId: Scalars['String'];
  description: Scalars['String'];
  manufacturer: Scalars['String'];
  name: Scalars['String'];
  type: ChemicalType;
};

export type UpdateDeviceInput = {
  alias: Scalars['String'];
  gpsAccuracy: UnitMeasurementInput;
  id: Scalars['String'];
  rinseTimeout: Scalars['Int'];
  rinseVolume: UnitMeasurementInput;
  sleepTimeout: Scalars['Int'];
};

export type UpdateJobInput = {
  applicationType: ApplicationType;
  description: Scalars['String'];
  deviceAssignments: Array<DeviceAssignmentInput>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  location?: InputMaybe<LocationInput>;
  name: Scalars['String'];
  recipeId?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  weedAssignments: Array<InputMaybe<Scalars['String']>>;
};

export type UpdateOrganisationInput = {
  address: Scalars['String'];
  businessNumber: Scalars['String'];
  contactEmail: Scalars['String'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  unitSystem: UnitSystem;
};

export type UpdateRecipeInput = {
  concentrations: Array<ConcentrationInput>;
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateSprayAreaInput = {
  jobId: Scalars['String'];
  nozzleWidth: UnitMeasurementInput;
};

export type UpdateUserInput = {
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  role: UserRole;
};

export type UpdateWebhookInput = {
  description?: InputMaybe<Scalars['String']>;
  events: Array<WebhookEventKind>;
  id: Scalars['String'];
  name: Scalars['String'];
  status: WebhookStatus;
  url: Scalars['String'];
};

export type UpdateWeedInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  species: Scalars['String'];
  updateImage: Scalars['Boolean'];
  weedId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  jobs?: Maybe<JobsConnection>;
  lastName: Scalars['String'];
  organisation?: Maybe<Organisation>;
  registrationDate: Scalars['DateTime'];
  role: UserRole;
  userStatus?: Maybe<UserStatus>;
};

export type UserJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  role?: InputMaybe<UserRoleOperationFilterInput>;
  userStatus?: InputMaybe<UserStatusOperationFilterInput>;
};

export enum UserRole {
  Manufacturer = 'MANUFACTURER',
  Operator = 'OPERATOR',
  OrganisationAdmin = 'ORGANISATION_ADMIN',
  SkilledOperator = 'SKILLED_OPERATOR',
  SuperAdmin = 'SUPER_ADMIN',
}

export type UserRoleOperationFilterInput = {
  eq?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  neq?: InputMaybe<UserRole>;
  nin?: InputMaybe<Array<UserRole>>;
};

export type UserSortInput = {
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  role?: InputMaybe<SortEnumType>;
  userStatus?: InputMaybe<SortEnumType>;
};

export enum UserStatus {
  Archived = 'ARCHIVED',
  Compromised = 'COMPROMISED',
  Confirmed = 'CONFIRMED',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
  ResetRequired = 'RESET_REQUIRED',
  Unconfirmed = 'UNCONFIRMED',
  Unknown = 'UNKNOWN',
}

export type UserStatusOperationFilterInput = {
  eq?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  neq?: InputMaybe<UserStatus>;
  nin?: InputMaybe<Array<UserStatus>>;
};

export type UserSummary = {
  __typename?: 'UserSummary';
  user: User;
  volume: UnitMeasurement;
};

/** A connection to a list of items. */
export type UsersByOrganisationIdConnection = {
  __typename?: 'UsersByOrganisationIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UsersByOrganisationIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UsersByOrganisationIdEdge = {
  __typename?: 'UsersByOrganisationIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: User;
};

/** A connection to a list of items. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: User;
};

export type WeatherInformationDto = {
  __typename?: 'WeatherInformationDto';
  deltaT: UnitMeasurement;
  humidity: UnitMeasurement;
  temperature: UnitMeasurement;
  timestamp: Scalars['DateTime'];
  windBearing: UnitMeasurement;
  windSpeed: UnitMeasurement;
};

export type Webhook = {
  __typename?: 'Webhook';
  description?: Maybe<Scalars['String']>;
  events: Array<WebhookEventKind>;
  id: Scalars['String'];
  infrastructureStatus: WebhookInfrastructureStatusDetails;
  name: Scalars['String'];
  organisation: Organisation;
  status: WebhookStatus;
  url: Scalars['String'];
};

export enum WebhookEventKind {
  JobCompletedV1 = 'JOB_COMPLETED_V1',
}

export type WebhookEventKindOperationFilterInput = {
  eq?: InputMaybe<WebhookEventKind>;
  in?: InputMaybe<Array<WebhookEventKind>>;
  neq?: InputMaybe<WebhookEventKind>;
  nin?: InputMaybe<Array<WebhookEventKind>>;
};

export type WebhookFilterInput = {
  and?: InputMaybe<Array<WebhookFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  events?: InputMaybe<ListWebhookEventKindOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  infrastructureStatus?: InputMaybe<WebhookInfrastructureStatusDetailsFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WebhookFilterInput>>;
  organisationId?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<WebhookStatusOperationFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
};

export enum WebhookInfrastructureStatus {
  DeploymentComplete = 'DEPLOYMENT_COMPLETE',
  DeploymentFailed = 'DEPLOYMENT_FAILED',
  DeploymentInProgress = 'DEPLOYMENT_IN_PROGRESS',
}

export type WebhookInfrastructureStatusDetails = {
  __typename?: 'WebhookInfrastructureStatusDetails';
  detail?: Maybe<Scalars['String']>;
  status: WebhookInfrastructureStatus;
};

export type WebhookInfrastructureStatusDetailsFilterInput = {
  and?: InputMaybe<Array<WebhookInfrastructureStatusDetailsFilterInput>>;
  detail?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WebhookInfrastructureStatusDetailsFilterInput>>;
  status?: InputMaybe<WebhookInfrastructureStatusOperationFilterInput>;
};

export type WebhookInfrastructureStatusDetailsSortInput = {
  detail?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
};

export type WebhookInfrastructureStatusOperationFilterInput = {
  eq?: InputMaybe<WebhookInfrastructureStatus>;
  in?: InputMaybe<Array<WebhookInfrastructureStatus>>;
  neq?: InputMaybe<WebhookInfrastructureStatus>;
  nin?: InputMaybe<Array<WebhookInfrastructureStatus>>;
};

export type WebhookSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  infrastructureStatus?: InputMaybe<WebhookInfrastructureStatusDetailsSortInput>;
  name?: InputMaybe<SortEnumType>;
  organisationId?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  url?: InputMaybe<SortEnumType>;
};

export enum WebhookStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export type WebhookStatusOperationFilterInput = {
  eq?: InputMaybe<WebhookStatus>;
  in?: InputMaybe<Array<WebhookStatus>>;
  neq?: InputMaybe<WebhookStatus>;
  nin?: InputMaybe<Array<WebhookStatus>>;
};

/** A connection to a list of items. */
export type WebhooksByOrganisationIdConnection = {
  __typename?: 'WebhooksByOrganisationIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<WebhooksByOrganisationIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Webhook>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type WebhooksByOrganisationIdEdge = {
  __typename?: 'WebhooksByOrganisationIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Webhook;
};

export type Weed = {
  __typename?: 'Weed';
  description: Scalars['String'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  jobs?: Maybe<JobsConnection>;
  name: Scalars['String'];
  organisation: Organisation;
  species: Scalars['String'];
};

export type WeedJobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type WeedByCoordinatesInput = {
  coordinates: CoordinatesInput;
  endTime: Scalars['DateTime'];
  radius: UnitMeasurementInput;
  startTime: Scalars['DateTime'];
  weedId: Scalars['String'];
};

export type WeedFilterInput = {
  and?: InputMaybe<Array<WeedFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WeedFilterInput>>;
  species?: InputMaybe<StringOperationFilterInput>;
};

export type WeedSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  species?: InputMaybe<SortEnumType>;
};

/** A connection to a list of items. */
export type WeedsByOrganisationIdConnection = {
  __typename?: 'WeedsByOrganisationIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<WeedsByOrganisationIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Weed>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type WeedsByOrganisationIdEdge = {
  __typename?: 'WeedsByOrganisationIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Weed;
};

export type CreateChemicalMutationVariables = Exact<{
  input: CreateChemicalInput;
}>;

export type CreateChemicalMutation = {
  __typename?: 'Mutation';
  createChemical: {
    __typename?: 'Chemical';
    id: string;
    name: string;
    activeIngredient: string;
    manufacturer: string;
    description: string;
    type: ChemicalType;
    organisation: { __typename?: 'Organisation'; id: string; name: string };
  };
};

export type DeleteChemicalMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteChemicalMutation = {
  __typename?: 'Mutation';
  deleteChemical: {
    __typename?: 'Chemical';
    id: string;
    name: string;
    deleteInformation?: { __typename?: 'SoftDeleteInformation'; deletedAt: any };
  };
};

export type UpdateChemicalMutationVariables = Exact<{
  input: UpdateChemicalInput;
}>;

export type UpdateChemicalMutation = {
  __typename?: 'Mutation';
  updateChemical: {
    __typename?: 'Chemical';
    id: string;
    name: string;
    activeIngredient: string;
    manufacturer: string;
    description: string;
    type: ChemicalType;
    organisation: { __typename?: 'Organisation'; id: string; name: string };
  };
};

export type ChemicalSearchQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChemicalFilterInput>;
}>;

export type ChemicalSearchQuery = {
  __typename?: 'Query';
  chemicalsByOrganisationId?: {
    __typename?: 'ChemicalsByOrganisationIdConnection';
    nodes?: Array<{ __typename?: 'Chemical'; id: string; name: string; type: ChemicalType }>;
  };
};

export type GetChemicalQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetChemicalQuery = {
  __typename?: 'Query';
  chemical?: {
    __typename?: 'Chemical';
    id: string;
    name: string;
    activeIngredient: string;
    manufacturer: string;
    description: string;
    type: ChemicalType;
    organisation: { __typename?: 'Organisation'; id: string };
    recipes?: {
      __typename?: 'RecipesConnection';
      totalCount: number;
      edges?: Array<{
        __typename?: 'RecipesEdge';
        node?: {
          __typename?: 'Recipe';
          id: string;
          name: string;
          description: string;
          deleteInformation?: { __typename?: 'SoftDeleteInformation'; deletedAt: any };
        };
      }>;
      pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
    };
    deleteInformation?: { __typename?: 'SoftDeleteInformation'; deletedAt: any };
  };
};

export type GetChemicalFromJobQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetChemicalFromJobQuery = {
  __typename?: 'Query';
  job?: {
    __typename?: 'Job';
    completedAt?: any;
    recipe?:
      | {
          __typename?: 'Recipe';
          concentrations: Array<{
            __typename?: 'Concentration';
            chemical: {
              __typename?: 'Chemical';
              id: string;
              name: string;
              activeIngredient: string;
              manufacturer: string;
              description: string;
              type: ChemicalType;
            };
          }>;
        }
      | {
          __typename?: 'RecipeSummary';
          concentrations: Array<{
            __typename?: 'ConcentrationSummary';
            chemical: {
              __typename?: 'Chemical';
              id: string;
              name: string;
              activeIngredient: string;
              manufacturer: string;
              description: string;
              type: ChemicalType;
            };
          }>;
        };
  };
};

export type GetChemicalUsageQueryVariables = Exact<{
  input: GetChemicalUsageInput;
}>;

export type GetChemicalUsageQuery = {
  __typename?: 'Query';
  chemicalUsage: {
    __typename?: 'ChemicalUsage';
    usage?: Array<{
      __typename?: 'KeyValuePairOfDateTimeAndUnitMeasurement';
      key: any;
      value: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
    }>;
  };
};

export type GetChemicalsByOrganisationIdQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ChemicalFilterInput>;
  order?: InputMaybe<Array<ChemicalSortInput> | ChemicalSortInput>;
}>;

export type GetChemicalsByOrganisationIdQuery = {
  __typename?: 'Query';
  chemicalsByOrganisationId?: {
    __typename?: 'ChemicalsByOrganisationIdConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ChemicalsByOrganisationIdEdge';
      node: {
        __typename?: 'Chemical';
        id: string;
        name: string;
        activeIngredient: string;
        manufacturer: string;
        description: string;
        type: ChemicalType;
        organisation: { __typename?: 'Organisation'; id: string };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type ActivateDeviceMutationVariables = Exact<{
  input: ActivateDeviceInput;
}>;

export type ActivateDeviceMutation = {
  __typename?: 'Mutation';
  activateDevice: {
    __typename?: 'Device';
    id: string;
    alias: string;
    calibrationTimeout: number;
    rinseTimeout: number;
    loggingPeriod: number;
    sleepTimeout: number;
    registrationDate: any;
    activationDate?: any;
    organisation?: { __typename?: 'Organisation'; id: string };
    calibrationVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    rinseVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    gpsAccuracy: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
  };
};

export type AdminUpdateDeviceMutationVariables = Exact<{
  input: AdminUpdateDeviceInput;
}>;

export type AdminUpdateDeviceMutation = {
  __typename?: 'Mutation';
  adminUpdateDevice: {
    __typename?: 'Device';
    id: string;
    alias: string;
    calibrationTimeout: number;
    rinseTimeout: number;
    loggingPeriod: number;
    sleepTimeout: number;
    registrationDate: any;
    activationDate?: any;
    organisation?: { __typename?: 'Organisation'; id: string };
    calibrationVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    rinseVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    gpsAccuracy: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    firmwareInformation: {
      __typename?: 'FirmwareInformation';
      lockStatus: FirmwareLockStatus;
      candidate?: { __typename?: 'CandidateFirmware'; firmware: { __typename?: 'Firmware'; id: string; version: any } };
    };
  };
};

export type CreateDeviceMutationVariables = Exact<{
  input: CreateDeviceInput;
}>;

export type CreateDeviceMutation = {
  __typename?: 'Mutation';
  createDevice: {
    __typename?: 'Device';
    id: string;
    alias: string;
    activationToken?: string;
    authenticationToken?: string;
    calibrationTimeout: number;
    rinseTimeout: number;
    loggingPeriod: number;
    sleepTimeout: number;
    registrationDate: any;
    activationDate?: any;
    organisation?: { __typename?: 'Organisation'; id: string };
    calibrationVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    rinseVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    gpsAccuracy: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
  };
};

export type SetDeviceFirmwareByDeviceIdMutationVariables = Exact<{
  input: SetDeviceFirmwareByDeviceIdInput;
}>;

export type SetDeviceFirmwareByDeviceIdMutation = {
  __typename?: 'Mutation';
  setDeviceFirmwareByDeviceId: {
    __typename?: 'Device';
    id: string;
    alias: string;
    activationToken?: string;
    authenticationToken?: string;
    calibrationTimeout: number;
    rinseTimeout: number;
    loggingPeriod: number;
    sleepTimeout: number;
    registrationDate: any;
    activationDate?: any;
    organisation?: { __typename?: 'Organisation'; id: string };
    calibrationVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    rinseVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    gpsAccuracy: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    firmwareInformation: {
      __typename?: 'FirmwareInformation';
      lockStatus: FirmwareLockStatus;
      candidate?: { __typename?: 'CandidateFirmware'; firmware: { __typename?: 'Firmware'; id: string; version: any } };
    };
  };
};

export type UpdateDeviceMutationVariables = Exact<{
  input: UpdateDeviceInput;
}>;

export type UpdateDeviceMutation = {
  __typename?: 'Mutation';
  updateDevice: {
    __typename?: 'Device';
    id: string;
    alias: string;
    calibrationTimeout: number;
    rinseTimeout: number;
    loggingPeriod: number;
    sleepTimeout: number;
    registrationDate: any;
    activationDate?: any;
    organisation?: { __typename?: 'Organisation'; id: string };
    calibrationVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    rinseVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    gpsAccuracy: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
  };
};

export type AdminGetDevicesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DeviceFilterInput>;
  order?: InputMaybe<Array<DeviceSortInput> | DeviceSortInput>;
}>;

export type AdminGetDevicesQuery = {
  __typename?: 'Query';
  devices?: {
    __typename?: 'DevicesConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DevicesEdge';
      node: {
        __typename?: 'Device';
        id: string;
        alias: string;
        registrationDate: any;
        activationDate?: any;
        organisation?: { __typename?: 'Organisation'; id: string; name: string };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type DevicesSearchQueryVariables = Exact<{
  organisationId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceFilterInput>;
}>;

export type DevicesSearchQuery = {
  __typename?: 'Query';
  devicesByOrganisationId?: {
    __typename?: 'DevicesByOrganisationIdConnection';
    nodes?: Array<{ __typename?: 'Device'; id: string; alias: string }>;
  };
};

export type GetDeviceQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetDeviceQuery = {
  __typename?: 'Query';
  device?: {
    __typename?: 'Device';
    id: string;
    alias: string;
    calibrationTimeout: number;
    rinseTimeout: number;
    loggingPeriod: number;
    sleepTimeout: number;
    activationDate?: any;
    organisation?: {
      __typename?: 'Organisation';
      id: string;
      organisationFirmware?: {
        __typename?: 'OrganisationFirmware';
        lockStatus: FirmwareLockStatus;
        firmware: { __typename?: 'Firmware'; id: string };
      };
    };
    calibrationVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    rinseVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    gpsAccuracy: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
    firmwareInformation: {
      __typename?: 'FirmwareInformation';
      lockStatus: FirmwareLockStatus;
      slot1: {
        __typename?: 'SlotInformation';
        reportedAt: any;
        firmware: { __typename?: 'Firmware'; version: any; id: string };
      };
      slot2: {
        __typename?: 'SlotInformation';
        reportedAt: any;
        firmware: { __typename?: 'Firmware'; version: any; id: string };
      };
      candidate?: { __typename?: 'CandidateFirmware'; firmware: { __typename?: 'Firmware'; version: any; id: string } };
    };
    lastCalibration?: {
      __typename?: 'LastCalibrationDto';
      time: any;
      ticks: number;
      volumeDispensed: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    };
    jobs?: {
      __typename?: 'JobsConnection';
      totalCount: number;
      edges?: Array<{
        __typename?: 'JobsEdge';
        node?: {
          __typename?: 'Job';
          id: string;
          name: string;
          startTime?: any;
          endTime?: any;
          deviceAssignments: Array<{
            __typename?: 'DeviceAssignment';
            device: { __typename?: 'Device'; id: string };
            user: { __typename?: 'User'; id: string; fullName: string };
          }>;
          recipe?:
            | { __typename?: 'Recipe'; id: string; name: string }
            | { __typename?: 'RecipeSummary'; id: string; name: string };
        };
      }>;
      pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
    };
  };
};

export type GetDevicesByOrganisationIdQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DeviceFilterInput>;
  order?: InputMaybe<Array<DeviceSortInput> | DeviceSortInput>;
}>;

export type GetDevicesByOrganisationIdQuery = {
  __typename?: 'Query';
  devicesByOrganisationId?: {
    __typename?: 'DevicesByOrganisationIdConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'DevicesByOrganisationIdEdge';
      node: {
        __typename?: 'Device';
        id: string;
        alias: string;
        calibrationTimeout: number;
        rinseTimeout: number;
        loggingPeriod: number;
        sleepTimeout: number;
        activationDate?: any;
        organisation?: { __typename?: 'Organisation'; name: string };
        calibrationVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
        rinseVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
        gpsAccuracy: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type GetManufacturingDevicesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<DeviceFilterInput>;
  order?: InputMaybe<Array<DeviceSortInput> | DeviceSortInput>;
}>;

export type GetManufacturingDevicesQuery = {
  __typename?: 'Query';
  manufacturingDevices?: {
    __typename?: 'ManufacturingDevicesConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ManufacturingDevicesEdge';
      node: {
        __typename?: 'Device';
        id: string;
        alias: string;
        activationToken?: string;
        authenticationToken?: string;
        calibrationTimeout: number;
        rinseTimeout: number;
        loggingPeriod: number;
        sleepTimeout: number;
        registrationDate: any;
        activationDate?: any;
        organisation?: { __typename?: 'Organisation'; id: string };
        calibrationVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
        rinseVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
        gpsAccuracy: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type GetSprayLogsForDeviceQueryVariables = Exact<{
  deviceId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetSprayLogsForDeviceQuery = {
  __typename?: 'Query';
  sprayLogsForDevice?: {
    __typename?: 'SprayLogsForDeviceConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'SprayLogsForDeviceEdge';
      node: {
        __typename?: 'SprayLog';
        timestamp: any;
        wasDropped: boolean;
        weeds: number;
        sprayMode: SprayMode;
        coordinates: {
          __typename?: 'LogCoordinates';
          latitude: number;
          longitude: number;
          altitude: number;
          satellites: number;
        };
        volumeDispensed?: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
        temperature?: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type ManufacturerGetDeviceQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type ManufacturerGetDeviceQuery = {
  __typename?: 'Query';
  manufacturerDevice?: {
    __typename?: 'Device';
    id: string;
    alias: string;
    activationToken?: string;
    authenticationToken?: string;
    calibrationTimeout: number;
    rinseTimeout: number;
    loggingPeriod: number;
    sleepTimeout: number;
    registrationDate: any;
    activationDate?: any;
    organisation?: { __typename?: 'Organisation'; id: string };
    calibrationVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    rinseVolume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    gpsAccuracy: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    firmwareInformation: {
      __typename?: 'FirmwareInformation';
      lockStatus: FirmwareLockStatus;
      slot1: {
        __typename?: 'SlotInformation';
        reportedAt: any;
        firmware: { __typename?: 'Firmware'; version: any; id: string };
      };
      slot2: {
        __typename?: 'SlotInformation';
        reportedAt: any;
        firmware: { __typename?: 'Firmware'; version: any; id: string };
      };
      candidate?: { __typename?: 'CandidateFirmware'; firmware: { __typename?: 'Firmware'; version: any; id: string } };
    };
  };
};

export type CreateFirmwareMutationVariables = Exact<{
  input: CreateFirmwareInput;
}>;

export type CreateFirmwareMutation = {
  __typename?: 'Mutation';
  createFirmware: {
    __typename?: 'CreateFirmwareOutput';
    id: string;
    version: any;
    description: string;
    uploadUrl: string;
  };
};

export type DisableFirmwareMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DisableFirmwareMutation = {
  __typename?: 'Mutation';
  disableFirmware: { __typename?: 'Firmware'; id: string; status: FirmwareStatus };
};

export type AdminGetAllAvailableFirmwareQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FirmwareFilterInput>;
  order?: InputMaybe<Array<FirmwareSortInput> | FirmwareSortInput>;
}>;

export type AdminGetAllAvailableFirmwareQuery = {
  __typename?: 'Query';
  enabledFirmware?: {
    __typename?: 'EnabledFirmwareConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'EnabledFirmwareEdge';
      node: {
        __typename?: 'Firmware';
        id: string;
        status: FirmwareStatus;
        version: any;
        description: string;
        slot1ObjectKey: string;
        slot2ObjectKey: string;
        createInformation: { __typename?: 'CreateInformation'; createdBy: string; createdAt: any };
        user: { __typename?: 'User'; fullName: string };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type AdminGetAllFirmwareQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<FirmwareFilterInput>;
  order?: InputMaybe<Array<FirmwareSortInput> | FirmwareSortInput>;
}>;

export type AdminGetAllFirmwareQuery = {
  __typename?: 'Query';
  firmware?: {
    __typename?: 'FirmwareConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'FirmwareEdge';
      node: {
        __typename?: 'Firmware';
        id: string;
        status: FirmwareStatus;
        version: any;
        description: string;
        createInformation: { __typename?: 'CreateInformation'; createdBy: string; createdAt: any };
        user: { __typename?: 'User'; fullName: string };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type AdminGetFirmwareByIdQueryVariables = Exact<{
  input: GetFirmwareInput;
}>;

export type AdminGetFirmwareByIdQuery = {
  __typename?: 'Query';
  firmwareById: {
    __typename?: 'Firmware';
    id: string;
    status: FirmwareStatus;
    version: any;
    description: string;
    createInformation: { __typename?: 'CreateInformation'; createdBy: string; createdAt: any };
    user: { __typename?: 'User'; fullName: string };
  };
};

export type FirmwareSearchQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FirmwareFilterInput>;
}>;

export type FirmwareSearchQuery = {
  __typename?: 'Query';
  enabledFirmware?: {
    __typename?: 'EnabledFirmwareConnection';
    nodes?: Array<{ __typename?: 'Firmware'; id: string; version: any }>;
  };
};

export type GetFirmwareByIdQueryVariables = Exact<{
  input: GetFirmwareInput;
}>;

export type GetFirmwareByIdQuery = {
  __typename?: 'Query';
  firmwareById: { __typename?: 'Firmware'; id: string; version: any; description: string };
};

export type BatchExportJobsMutationVariables = Exact<{
  emails: Array<Scalars['String']> | Scalars['String'];
  jobIds: Array<Scalars['String']> | Scalars['String'];
  selectAll: Scalars['Boolean'];
  organizationId?: InputMaybe<Scalars['String']>;
}>;

export type BatchExportJobsMutation = { __typename?: 'Mutation'; batchExportJobs: boolean };

export type CompleteJobMutationVariables = Exact<{
  input: CompleteJobInput;
}>;

export type CompleteJobMutation = {
  __typename?: 'Mutation';
  completeJob: {
    __typename?: 'Job';
    id: string;
    name: string;
    description: string;
    startTime?: any;
    endTime?: any;
    status: JobStatus;
    completedAt?: any;
    applicationType: ApplicationType;
    organisation: { __typename?: 'Organisation'; id: string };
    location?: { __typename?: 'Location'; latitude: number; longitude: number; address: string };
    deviceAssignments: Array<{
      __typename?: 'DeviceAssignment';
      user: { __typename?: 'User'; id: string; fullName: string };
      device: { __typename?: 'Device'; id: string; alias: string };
      sprayLogs: Array<{
        __typename?: 'SprayLogDto';
        d: boolean;
        w: number;
        c: {
          __typename?: 'LogCoordinatesDto';
          x: number;
          y: number;
          z: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
        };
        v: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      }>;
    }>;
    weedAssignments: Array<{ __typename?: 'Weed'; id: string; name: string }>;
    recipe?:
      | {
          __typename?: 'Recipe';
          id: string;
          name: string;
          concentrations: Array<{
            __typename?: 'Concentration';
            chemical: { __typename?: 'Chemical'; id: string; name: string; type: ChemicalType };
            amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
          }>;
        }
      | {
          __typename?: 'RecipeSummary';
          id: string;
          name: string;
          concentrations: Array<{
            __typename?: 'ConcentrationSummary';
            chemical: { __typename?: 'Chemical'; id: string; name: string; type: ChemicalType };
            amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
          }>;
        };
    volumeDispensed?: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    weatherInformation: Array<{
      __typename?: 'WeatherInformationDto';
      timestamp: any;
      temperature: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      humidity: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      windSpeed: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      windBearing: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      deltaT: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    }>;
  };
};

export type CreateJobMutationVariables = Exact<{
  input: CreateJobInput;
}>;

export type CreateJobMutation = {
  __typename?: 'Mutation';
  createJob: {
    __typename?: 'Job';
    id: string;
    name: string;
    description: string;
    startTime?: any;
    endTime?: any;
    applicationType: ApplicationType;
    status: JobStatus;
    organisation: { __typename?: 'Organisation'; id: string };
    location?: { __typename?: 'Location'; latitude: number; longitude: number; address: string };
    deviceAssignments: Array<{
      __typename?: 'DeviceAssignment';
      user: { __typename?: 'User'; id: string; fullName: string };
      device: { __typename?: 'Device'; id: string; alias: string };
    }>;
    weedAssignments: Array<{ __typename?: 'Weed'; id: string; name: string }>;
    recipe?:
      | { __typename?: 'Recipe'; id: string; name: string }
      | { __typename?: 'RecipeSummary'; id: string; name: string };
  };
};

export type DeleteJobMutationVariables = Exact<{
  input: DeleteJobInput;
}>;

export type DeleteJobMutation = { __typename?: 'Mutation'; deleteJob: boolean };

export type JobUpdateSprayAreaMutationVariables = Exact<{
  input: UpdateSprayAreaInput;
}>;

export type JobUpdateSprayAreaMutation = {
  __typename?: 'Mutation';
  updateSprayArea: {
    __typename?: 'Job';
    id: string;
    areaSprayed?: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    nozzleWidth?: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
  };
};

export type RespondToOrgShareMutationVariables = Exact<{
  jobId: Scalars['String'];
  organisationId: Scalars['String'];
  status: ShareStatus;
}>;

export type RespondToOrgShareMutation = {
  __typename?: 'Mutation';
  respondToOrgShare: { __typename?: 'Job'; id: string };
};

export type RevokeOrgShareMutationVariables = Exact<{
  jobId: Scalars['String'];
  organisationId: Scalars['String'];
}>;

export type RevokeOrgShareMutation = {
  __typename?: 'Mutation';
  revokeOrgShare: { __typename?: 'Job'; id: string; organisation: { __typename?: 'Organisation'; id: string } };
};

export type ShareJobWithOrganisationMutationVariables = Exact<{
  emails: Array<Scalars['String']> | Scalars['String'];
  jobId: Scalars['String'];
}>;

export type ShareJobWithOrganisationMutation = {
  __typename?: 'Mutation';
  shareJobWithOrganisation: {
    __typename?: 'Job';
    id: string;
    sharedOrganisations?: Array<{
      __typename?: 'OrganisationShare';
      status: ShareStatus;
      lastShared: any;
      organisation: { __typename?: 'Organisation'; id: string; name: string };
    }>;
  };
};

export type UpdateJobMutationVariables = Exact<{
  input: UpdateJobInput;
}>;

export type UpdateJobMutation = {
  __typename?: 'Mutation';
  updateJob: {
    __typename?: 'Job';
    id: string;
    name: string;
    description: string;
    startTime?: any;
    endTime?: any;
    applicationType: ApplicationType;
    organisation: { __typename?: 'Organisation'; id: string };
    location?: { __typename?: 'Location'; latitude: number; longitude: number; address: string };
    deviceAssignments: Array<{
      __typename?: 'DeviceAssignment';
      user: { __typename?: 'User'; id: string; fullName: string };
      device: { __typename?: 'Device'; id: string; alias: string };
    }>;
    weedAssignments: Array<{ __typename?: 'Weed'; id: string; name: string }>;
    recipe?:
      | { __typename?: 'Recipe'; id: string; name: string }
      | { __typename?: 'RecipeSummary'; id: string; name: string };
  };
};

export type GetActiveJobsQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetActiveJobsQuery = {
  __typename?: 'Query';
  activeJobs?: {
    __typename?: 'ActiveJobsConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ActiveJobsEdge';
      node: { __typename?: 'Job'; id: string; name: string; description: string; startTime?: any };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type GetCreateJobContentQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetCreateJobContentQuery = {
  __typename?: 'Query';
  devicesByOrganisationId?: {
    __typename?: 'DevicesByOrganisationIdConnection';
    edges?: Array<{
      __typename?: 'DevicesByOrganisationIdEdge';
      node: { __typename?: 'Device'; id: string; alias: string };
    }>;
  };
  usersByOrganisationId?: {
    __typename?: 'UsersByOrganisationIdConnection';
    edges?: Array<{
      __typename?: 'UsersByOrganisationIdEdge';
      node: { __typename?: 'User'; id: string; fullName: string };
    }>;
  };
  recipesByOrganisationId?: {
    __typename?: 'RecipesByOrganisationIdConnection';
    edges?: Array<{
      __typename?: 'RecipesByOrganisationIdEdge';
      node: { __typename?: 'Recipe'; id: string; name: string };
    }>;
  };
  weedsByOrganisationId?: {
    __typename?: 'WeedsByOrganisationIdConnection';
    edges?: Array<{
      __typename?: 'WeedsByOrganisationIdEdge';
      node: { __typename?: 'Weed'; id: string; name: string };
    }>;
  };
};

export type ExistingJobsQueryVariables = Exact<{
  id: Scalars['String'];
  first: Scalars['Int'];
  searchString: Scalars['String'];
}>;

export type ExistingJobsQuery = {
  __typename?: 'Query';
  existingJobs?: {
    __typename?: 'ExistingJobsConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'ExistingJobsEdge';
      node: {
        __typename?: 'Job';
        id: string;
        name: string;
        description: string;
        startTime?: any;
        endTime?: any;
        status: JobStatus;
        applicationType: ApplicationType;
        organisation: { __typename?: 'Organisation'; id: string };
        location?: { __typename?: 'Location'; address: string; latitude: number; longitude: number };
        weedAssignments: Array<{ __typename?: 'Weed'; id: string; name: string }>;
        deviceAssignments: Array<{
          __typename?: 'DeviceAssignment';
          device: { __typename?: 'Device'; id: string; alias: string };
          user: { __typename?: 'User'; id: string; fullName: string };
        }>;
        recipe?:
          | { __typename?: 'Recipe'; id: string; name: string }
          | { __typename?: 'RecipeSummary'; id: string; name: string };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type GetJobQueryVariables = Exact<{
  id: Scalars['String'];
  shared?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetJobQuery = {
  __typename?: 'Query';
  job?: {
    __typename?: 'Job';
    id: string;
    name: string;
    description: string;
    startTime?: any;
    endTime?: any;
    status: JobStatus;
    completedAt?: any;
    applicationType: ApplicationType;
    organisation: { __typename?: 'Organisation'; id: string; name: string };
    location?: { __typename?: 'Location'; latitude: number; longitude: number; address: string };
    areaSprayed?: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    nozzleWidth?: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    deviceAssignments: Array<{
      __typename?: 'DeviceAssignment';
      id: string;
      user: { __typename?: 'User'; id: string; fullName: string };
      device: { __typename?: 'Device'; id: string; alias: string };
      sprayLogs: Array<{
        __typename?: 'SprayLogDto';
        w: number;
        t: any;
        c: { __typename?: 'LogCoordinatesDto'; x: number; y: number };
        v: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      }>;
      volumeDispensed?: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    }>;
    sharedOrganisations?: Array<{
      __typename?: 'OrganisationShare';
      status: ShareStatus;
      organisation: { __typename?: 'Organisation'; id: string; name: string };
    }>;
    weedAssignments: Array<{ __typename?: 'Weed'; id: string; name: string }>;
    recipe?:
      | {
          __typename?: 'Recipe';
          id: string;
          name: string;
          concentrations: Array<{
            __typename?: 'Concentration';
            chemical: { __typename?: 'Chemical'; id: string; name: string; type: ChemicalType };
            amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
          }>;
        }
      | {
          __typename?: 'RecipeSummary';
          id: string;
          name: string;
          concentrations: Array<{
            __typename?: 'ConcentrationSummary';
            chemical: { __typename?: 'Chemical'; id: string; name: string; type: ChemicalType };
            amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
          }>;
        };
    volumeDispensed?: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    weatherInformation: Array<{
      __typename?: 'WeatherInformationDto';
      timestamp: any;
      temperature: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      humidity: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
      windSpeed: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      windBearing: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      deltaT: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
    }>;
  };
};

export type JobsByOrganisationIdQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<JobFilterInput>;
  order?: InputMaybe<Array<JobSortInput> | JobSortInput>;
}>;

export type JobsByOrganisationIdQuery = {
  __typename?: 'Query';
  jobsByOrganisationId?: {
    __typename?: 'JobsByOrganisationIdConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'JobsByOrganisationIdEdge';
      node: {
        __typename?: 'Job';
        id: string;
        name: string;
        description: string;
        startTime?: any;
        endTime?: any;
        status: JobStatus;
        organisation: { __typename?: 'Organisation'; id: string };
        location?: { __typename?: 'Location'; address: string; latitude: number; longitude: number };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type JobsByUserIdQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<JobFilterInput>;
  order?: InputMaybe<Array<JobSortInput> | JobSortInput>;
}>;

export type JobsByUserIdQuery = {
  __typename?: 'Query';
  myJobs?: {
    __typename?: 'MyJobsConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'MyJobsEdge';
      node: {
        __typename?: 'Job';
        id: string;
        name: string;
        description: string;
        startTime?: any;
        endTime?: any;
        status: JobStatus;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type JobsSharedWithOrganisationIdQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type JobsSharedWithOrganisationIdQuery = {
  __typename?: 'Query';
  jobsSharedWithOrganisationId?: {
    __typename?: 'JobsSharedWithOrganisationIdConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'JobsSharedWithOrganisationIdEdge';
      node: {
        __typename?: 'Job';
        id: string;
        name: string;
        description: string;
        startTime?: any;
        endTime?: any;
        status: JobStatus;
        organisation: { __typename?: 'Organisation'; id: string };
        location?: { __typename?: 'Location'; address: string; latitude: number; longitude: number };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type AdminCreateOrganisationMutationVariables = Exact<{
  input: CreateOrganisationInput;
}>;

export type AdminCreateOrganisationMutation = {
  __typename?: 'Mutation';
  createOrganisation: {
    __typename?: 'Organisation';
    id: string;
    businessNumber: string;
    name: string;
    description: string;
    address: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    enabled: boolean;
    unitSystem: UnitSystem;
  };
};

export type AdminDisableOrganisationMutationVariables = Exact<{
  input: ToggleOrganisationInput;
}>;

export type AdminDisableOrganisationMutation = {
  __typename?: 'Mutation';
  disableOrganisation: {
    __typename?: 'Organisation';
    id: string;
    businessNumber: string;
    name: string;
    description: string;
    address: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    enabled: boolean;
    users: Array<{
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      role: UserRole;
      enabled: boolean;
    }>;
    devices: Array<{ __typename?: 'Device'; id: string; alias: string }>;
  };
};

export type AdminEnableOrganisationMutationVariables = Exact<{
  input: ToggleOrganisationInput;
}>;

export type AdminEnableOrganisationMutation = {
  __typename?: 'Mutation';
  enableOrganisation: {
    __typename?: 'Organisation';
    id: string;
    businessNumber: string;
    name: string;
    description: string;
    address: string;
    contactName: string;
    contactPhone: string;
    contactEmail: string;
    enabled: boolean;
    users: Array<{
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      role: UserRole;
      enabled: boolean;
    }>;
    devices: Array<{ __typename?: 'Device'; id: string; alias: string }>;
  };
};

export type AdminUpdateOrganisationMutationVariables = Exact<{
  input: UpdateOrganisationInput;
}>;

export type AdminUpdateOrganisationMutation = {
  __typename?: 'Mutation';
  updateOrganisation: {
    __typename?: 'Organisation';
    id: string;
    businessNumber: string;
    name: string;
    description: string;
    address: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    unitSystem: UnitSystem;
  };
};

export type BatchSetOrganisationFirmwareMutationVariables = Exact<{
  input: BatchSetOrganisationFirmwareInput;
}>;

export type BatchSetOrganisationFirmwareMutation = {
  __typename?: 'Mutation';
  batchSetOrganisationFirmware: Array<{
    __typename?: 'Organisation';
    id: string;
    organisationFirmware?: {
      __typename?: 'OrganisationFirmware';
      lockStatus: FirmwareLockStatus;
      firmware: {
        __typename?: 'Firmware';
        id: string;
        status: FirmwareStatus;
        version: any;
        description: string;
        createInformation: { __typename?: 'CreateInformation'; createdBy: string; createdAt: any };
      };
    };
  }>;
};

export type SetOrganisationFirmwareMutationVariables = Exact<{
  input: SetOrganisationFirmwareInput;
}>;

export type SetOrganisationFirmwareMutation = {
  __typename?: 'Mutation';
  setOrganisationFirmware: {
    __typename?: 'Organisation';
    id: string;
    enabled: boolean;
    organisationFirmware?: {
      __typename?: 'OrganisationFirmware';
      lockStatus: FirmwareLockStatus;
      firmware: {
        __typename?: 'Firmware';
        id: string;
        status: FirmwareStatus;
        version: any;
        description: string;
        createInformation: { __typename?: 'CreateInformation'; createdBy: string; createdAt: any };
      };
    };
  };
};

export type AdminGetOrganisationQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type AdminGetOrganisationQuery = {
  __typename?: 'Query';
  organisation?: {
    __typename?: 'Organisation';
    id: string;
    businessNumber: string;
    name: string;
    description: string;
    address: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    unitSystem: UnitSystem;
    enabled: boolean;
    users: Array<{
      __typename?: 'User';
      id: string;
      firstName: string;
      lastName: string;
      role: UserRole;
      enabled: boolean;
    }>;
    devices: Array<{ __typename?: 'Device'; id: string; alias: string }>;
    organisationFirmware?: {
      __typename?: 'OrganisationFirmware';
      lockStatus: FirmwareLockStatus;
      firmware: { __typename?: 'Firmware'; id: string; version: any; description: string };
    };
  };
};

export type AdminGetOrganisationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OrganisationFilterInput>;
  order?: InputMaybe<Array<OrganisationSortInput> | OrganisationSortInput>;
}>;

export type AdminGetOrganisationsQuery = {
  __typename?: 'Query';
  organisations?: {
    __typename?: 'OrganisationsConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'OrganisationsEdge';
      node: {
        __typename?: 'Organisation';
        id: string;
        name: string;
        description: string;
        enabled: boolean;
        users: Array<{ __typename?: 'User'; id: string }>;
        devices: Array<{ __typename?: 'Device'; id: string }>;
        organisationFirmware?: {
          __typename?: 'OrganisationFirmware';
          lockStatus: FirmwareLockStatus;
          firmware: {
            __typename?: 'Firmware';
            id: string;
            status: FirmwareStatus;
            version: any;
            description: string;
            createInformation: { __typename?: 'CreateInformation'; createdBy: string; createdAt: any };
          };
        };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type GetOrganisationQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetOrganisationQuery = {
  __typename?: 'Query';
  organisation?: {
    __typename?: 'Organisation';
    id: string;
    businessNumber: string;
    name: string;
    description: string;
    address: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    unitSystem: UnitSystem;
  };
};

export type OrganisationSearchQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrganisationFilterInput>;
  order?: InputMaybe<Array<OrganisationSortInput> | OrganisationSortInput>;
}>;

export type OrganisationSearchQuery = {
  __typename?: 'Query';
  organisations?: {
    __typename?: 'OrganisationsConnection';
    nodes?: Array<{ __typename?: 'Organisation'; id: string; name: string; unitSystem: UnitSystem }>;
  };
};

export type CreateRecipeMutationVariables = Exact<{
  input: CreateRecipeInput;
}>;

export type CreateRecipeMutation = {
  __typename?: 'Mutation';
  createRecipe: {
    __typename?: 'Recipe';
    id: string;
    name: string;
    description: string;
    organisation: { __typename?: 'Organisation'; id: string };
    concentrations: Array<{
      __typename?: 'Concentration';
      chemical: { __typename?: 'Chemical'; id: string; name: string; description: string; type: ChemicalType };
      amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
    }>;
  };
};

export type DeleteRecipeMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteRecipeMutation = {
  __typename?: 'Mutation';
  deleteRecipe: {
    __typename?: 'Recipe';
    id: string;
    name: string;
    deleteInformation?: { __typename?: 'SoftDeleteInformation'; deletedAt: any };
  };
};

export type UpdateRecipeMutationVariables = Exact<{
  input: UpdateRecipeInput;
}>;

export type UpdateRecipeMutation = {
  __typename?: 'Mutation';
  updateRecipe: {
    __typename?: 'Recipe';
    id: string;
    name: string;
    description: string;
    organisation: { __typename?: 'Organisation'; id: string };
    concentrations: Array<{
      __typename?: 'Concentration';
      chemical: { __typename?: 'Chemical'; id: string; name: string; description: string; type: ChemicalType };
      amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
    }>;
  };
};

export type GetRecipeQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetRecipeQuery = {
  __typename?: 'Query';
  recipe?: {
    __typename?: 'Recipe';
    id: string;
    name: string;
    description: string;
    organisation: { __typename?: 'Organisation'; id: string };
    concentrations: Array<{
      __typename?: 'Concentration';
      chemical: { __typename?: 'Chemical'; id: string; name: string; type: ChemicalType };
      amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
    }>;
    jobs?: {
      __typename?: 'JobsConnection';
      totalCount: number;
      edges?: Array<{
        __typename?: 'JobsEdge';
        node?: { __typename?: 'Job'; id: string; name: string; description: string; startTime?: any };
      }>;
      pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
    };
    deleteInformation?: { __typename?: 'SoftDeleteInformation'; deletedAt: any };
  };
};

export type GetRecipeFromJobQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetRecipeFromJobQuery = {
  __typename?: 'Query';
  job?: {
    __typename?: 'Job';
    completedAt?: any;
    recipe?:
      | {
          __typename?: 'Recipe';
          id: string;
          name: string;
          description: string;
          organisation: { __typename?: 'Organisation'; id: string };
          concentrations: Array<{
            __typename?: 'Concentration';
            chemical: { __typename?: 'Chemical'; id: string; name: string; type: ChemicalType };
            amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
          }>;
        }
      | {
          __typename?: 'RecipeSummary';
          id: string;
          name: string;
          description: string;
          organisation: { __typename?: 'Organisation'; id: string };
          concentrations: Array<{
            __typename?: 'ConcentrationSummary';
            chemical: { __typename?: 'Chemical'; id: string; name: string; type: ChemicalType };
            amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
          }>;
        };
  };
};

export type GetRecipeUsageQueryVariables = Exact<{
  input: GetRecipeUsageInput;
}>;

export type GetRecipeUsageQuery = {
  __typename?: 'Query';
  recipeUsage: {
    __typename?: 'RecipeUsage';
    usage?: Array<{
      __typename?: 'KeyValuePairOfDateTimeAndUnitMeasurement';
      key: any;
      value: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
    }>;
  };
};

export type GetRecipeUsageSummaryQueryVariables = Exact<{
  input: RecipeUsageSummaryInput;
}>;

export type GetRecipeUsageSummaryQuery = {
  __typename?: 'Query';
  recipeUsageSummary: Array<{
    __typename?: 'RecipeUsageSummary';
    recipe:
      | {
          __typename?: 'Recipe';
          id: string;
          name: string;
          concentrations: Array<{
            __typename?: 'Concentration';
            chemical: { __typename?: 'Chemical'; id: string; name: string; type: ChemicalType };
            amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
          }>;
        }
      | {
          __typename?: 'RecipeSummary';
          id: string;
          name: string;
          concentrations: Array<{
            __typename?: 'ConcentrationSummary';
            chemical: { __typename?: 'Chemical'; id: string; name: string; type: ChemicalType };
            amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
          }>;
        };
    volume?: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
  }>;
};

export type GetRecipesByOrganisationIdQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<RecipeFilterInput>;
  order?: InputMaybe<Array<RecipeSortInput> | RecipeSortInput>;
}>;

export type GetRecipesByOrganisationIdQuery = {
  __typename?: 'Query';
  recipesByOrganisationId?: {
    __typename?: 'RecipesByOrganisationIdConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'RecipesByOrganisationIdEdge';
      node: {
        __typename?: 'Recipe';
        id: string;
        name: string;
        description: string;
        organisation: { __typename?: 'Organisation'; id: string };
        concentrations: Array<{
          __typename?: 'Concentration';
          chemical: { __typename?: 'Chemical'; id: string; name: string; type: ChemicalType };
          amount: { __typename?: 'UnitMeasurement'; unit: UnitType; value: number };
        }>;
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type RecipeSearchQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RecipeFilterInput>;
}>;

export type RecipeSearchQuery = {
  __typename?: 'Query';
  recipesByOrganisationId?: {
    __typename?: 'RecipesByOrganisationIdConnection';
    nodes?: Array<{ __typename?: 'Recipe'; id: string; name: string }>;
  };
};

export type GetUnassignedSprayLogsQueryVariables = Exact<{
  input: UnassignedSprayLogInput;
}>;

export type GetUnassignedSprayLogsQuery = {
  __typename?: 'Query';
  unassignedSprayLogs: Array<{
    __typename?: 'DeviceUnassignedSprayLogSummary';
    startTime: any;
    endTime: any;
    device: { __typename?: 'Device'; id: string; alias: string };
    volumeDispensed?: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
  }>;
};

export type AdminGetBusinessOverviewQueryVariables = Exact<{ [key: string]: never }>;

export type AdminGetBusinessOverviewQuery = {
  __typename?: 'Query';
  organisationCount: any;
  userCount: any;
  deviceCount: any;
};

export type AdminCreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type AdminCreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    role: UserRole;
    enabled: boolean;
    organisation?: { __typename?: 'Organisation'; id: string };
  };
};

export type AdminDisableUserMutationVariables = Exact<{
  input: ToggleUserInput;
}>;

export type AdminDisableUserMutation = {
  __typename?: 'Mutation';
  disableUser: { __typename?: 'User'; id: string; enabled: boolean; firstName: string; lastName: string };
};

export type AdminEnableUserMutationVariables = Exact<{
  input: ToggleUserInput;
}>;

export type AdminEnableUserMutation = {
  __typename?: 'Mutation';
  enableUser: { __typename?: 'User'; id: string; enabled: boolean; firstName: string; lastName: string };
};

export type AdminResendInviteMutationVariables = Exact<{
  input: ResendInviteInput;
}>;

export type AdminResendInviteMutation = {
  __typename?: 'Mutation';
  resendInvite: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: UserRole;
    enabled: boolean;
    userStatus?: UserStatus;
    registrationDate: any;
    organisation?: { __typename?: 'Organisation'; id: string; name: string };
  };
};

export type AdminUpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type AdminUpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    role: UserRole;
    enabled: boolean;
    organisation?: { __typename?: 'Organisation'; id: string };
  };
};

export type AdminGetUserQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type AdminGetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: UserRole;
    enabled: boolean;
    userStatus?: UserStatus;
    registrationDate: any;
    organisation?: { __typename?: 'Organisation'; id: string; name: string };
    jobs?: {
      __typename?: 'JobsConnection';
      totalCount: number;
      edges?: Array<{
        __typename?: 'JobsEdge';
        node?: {
          __typename?: 'Job';
          id: string;
          name: string;
          startTime?: any;
          endTime?: any;
          deviceAssignments: Array<{
            __typename?: 'DeviceAssignment';
            device: { __typename?: 'Device'; id: string; alias: string };
            user: { __typename?: 'User'; id: string };
          }>;
          recipe?:
            | { __typename?: 'Recipe'; id: string; name: string }
            | { __typename?: 'RecipeSummary'; id: string; name: string };
        };
      }>;
      pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
    };
  };
};

export type AdminGetUsersQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UserFilterInput>;
  order?: InputMaybe<Array<UserSortInput> | UserSortInput>;
}>;

export type AdminGetUsersQuery = {
  __typename?: 'Query';
  usersByOrganisationId?: {
    __typename?: 'UsersByOrganisationIdConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'UsersByOrganisationIdEdge';
      node: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        role: UserRole;
        enabled: boolean;
        userStatus?: UserStatus;
        organisation?: { __typename?: 'Organisation'; id: string };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type GetTopSprayersQueryVariables = Exact<{
  input: TopSprayersInput;
}>;

export type GetTopSprayersQuery = {
  __typename?: 'Query';
  topSprayers?: {
    __typename?: 'TopSprayersConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'TopSprayersEdge';
      node: {
        __typename?: 'UserSummary';
        user: { __typename?: 'User'; id: string; fullName: string; role: UserRole };
        volume: { __typename?: 'UnitMeasurement'; value: number; unit: UnitType };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type GetUserQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    role: UserRole;
    jobs?: {
      __typename?: 'JobsConnection';
      totalCount: number;
      edges?: Array<{
        __typename?: 'JobsEdge';
        node?: {
          __typename?: 'Job';
          id: string;
          name: string;
          startTime?: any;
          endTime?: any;
          deviceAssignments: Array<{
            __typename?: 'DeviceAssignment';
            device: { __typename?: 'Device'; id: string; alias: string };
            user: { __typename?: 'User'; id: string };
          }>;
          recipe?:
            | { __typename?: 'Recipe'; id: string; name: string }
            | { __typename?: 'RecipeSummary'; id: string; name: string };
        };
      }>;
      pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
    };
  };
};

export type SuperAdminGetAllUsersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<UserFilterInput>;
  order?: InputMaybe<Array<UserSortInput> | UserSortInput>;
}>;

export type SuperAdminGetAllUsersQuery = {
  __typename?: 'Query';
  users?: {
    __typename?: 'UsersConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'UsersEdge';
      node: {
        __typename?: 'User';
        id: string;
        fullName: string;
        email: string;
        role: UserRole;
        enabled: boolean;
        userStatus?: UserStatus;
        organisation?: { __typename?: 'Organisation'; name: string; id: string };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type UserSearchQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFilterInput>;
}>;

export type UserSearchQuery = {
  __typename?: 'Query';
  usersByOrganisationId?: {
    __typename?: 'UsersByOrganisationIdConnection';
    nodes?: Array<{ __typename?: 'User'; id: string; fullName: string }>;
  };
};

export type CreateWebhookMutationVariables = Exact<{
  input: CreateWebhookInput;
}>;

export type CreateWebhookMutation = {
  __typename?: 'Mutation';
  createWebhook: {
    __typename?: 'CreateWebhookResponse';
    apiKey: string;
    apiKeyHeader: string;
    webhook: {
      __typename?: 'Webhook';
      id: string;
      name: string;
      url: string;
      events: Array<WebhookEventKind>;
      description?: string;
      status: WebhookStatus;
    };
  };
};

export type DeleteWebhookMutationVariables = Exact<{
  input: DeleteWebhookInput;
}>;

export type DeleteWebhookMutation = { __typename?: 'Mutation'; deleteWebhook: { __typename?: 'Webhook'; id: string } };

export type UpdateWebhookMutationVariables = Exact<{
  input: UpdateWebhookInput;
}>;

export type UpdateWebhookMutation = {
  __typename?: 'Mutation';
  updateWebhook: {
    __typename?: 'Webhook';
    id: string;
    name: string;
    url: string;
    events: Array<WebhookEventKind>;
    description?: string;
    status: WebhookStatus;
  };
};

export type WebhookByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type WebhookByIdQuery = {
  __typename?: 'Query';
  webhookById?: {
    __typename?: 'Webhook';
    id: string;
    name: string;
    description?: string;
    events: Array<WebhookEventKind>;
    url: string;
    status: WebhookStatus;
    infrastructureStatus: {
      __typename?: 'WebhookInfrastructureStatusDetails';
      status: WebhookInfrastructureStatus;
      detail?: string;
    };
  };
};

export type WebhooksByOrganisationIdQueryVariables = Exact<{
  organisationId: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<WebhookFilterInput>;
  order?: InputMaybe<Array<WebhookSortInput> | WebhookSortInput>;
}>;

export type WebhooksByOrganisationIdQuery = {
  __typename?: 'Query';
  webhooksByOrganisationId?: {
    __typename?: 'WebhooksByOrganisationIdConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'WebhooksByOrganisationIdEdge';
      node: {
        __typename?: 'Webhook';
        id: string;
        name: string;
        description?: string;
        url: string;
        status: WebhookStatus;
        infrastructureStatus: {
          __typename?: 'WebhookInfrastructureStatusDetails';
          status: WebhookInfrastructureStatus;
          detail?: string;
        };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export type CreateWeedMutationVariables = Exact<{
  input: CreateWeedInput;
}>;

export type CreateWeedMutation = {
  __typename?: 'Mutation';
  createWeed: {
    __typename?: 'Weed';
    id: string;
    name: string;
    species: string;
    description: string;
    imageUrl?: string;
    organisation: { __typename?: 'Organisation'; id: string };
  };
};

export type UpdateWeedMutationVariables = Exact<{
  input: UpdateWeedInput;
}>;

export type UpdateWeedMutation = {
  __typename?: 'Mutation';
  updateWeed: {
    __typename?: 'Weed';
    id: string;
    name: string;
    species: string;
    description: string;
    imageUrl?: string;
    organisation: { __typename?: 'Organisation'; id: string };
  };
};

export type GetWeedQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;

export type GetWeedQuery = {
  __typename?: 'Query';
  weed?: {
    __typename?: 'Weed';
    id: string;
    name: string;
    species: string;
    description: string;
    imageUrl?: string;
    organisation: { __typename?: 'Organisation'; id: string };
    jobs?: {
      __typename?: 'JobsConnection';
      totalCount: number;
      edges?: Array<{
        __typename?: 'JobsEdge';
        node?: { __typename?: 'Job'; id: string; name: string; description: string; startTime?: any };
      }>;
      pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
    };
  };
};

export type WeedSearchQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WeedFilterInput>;
}>;

export type WeedSearchQuery = {
  __typename?: 'Query';
  weedsByOrganisationId?: {
    __typename?: 'WeedsByOrganisationIdConnection';
    nodes?: Array<{ __typename?: 'Weed'; id: string; name: string }>;
  };
};

export type WeedByCoordinatesQueryVariables = Exact<{
  input: WeedByCoordinatesInput;
}>;

export type WeedByCoordinatesQuery = {
  __typename?: 'Query';
  weedByCoordinates: Array<{
    __typename?: 'JobWithDeviceAssignments';
    id: string;
    name: string;
    recipe?:
      | { __typename?: 'Recipe'; id: string; name: string }
      | { __typename?: 'RecipeSummary'; id: string; name: string };
    deviceAssignments: Array<{
      __typename?: 'DeviceAssignment';
      device: { __typename?: 'Device'; id: string; alias: string };
      user: { __typename?: 'User'; id: string; fullName: string };
      sprayLogs: Array<{ __typename?: 'SprayLogDto'; c: { __typename?: 'LogCoordinatesDto'; x: number; y: number } }>;
    }>;
  }>;
};

export type GetWeedsByOrganisationIdQueryVariables = Exact<{
  id: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<WeedFilterInput>;
  order?: InputMaybe<Array<WeedSortInput> | WeedSortInput>;
}>;

export type GetWeedsByOrganisationIdQuery = {
  __typename?: 'Query';
  weedsByOrganisationId?: {
    __typename?: 'WeedsByOrganisationIdConnection';
    totalCount: number;
    edges?: Array<{
      __typename?: 'WeedsByOrganisationIdEdge';
      node: {
        __typename?: 'Weed';
        id: string;
        name: string;
        species: string;
        description: string;
        imageUrl?: string;
        organisation: { __typename?: 'Organisation'; id: string };
      };
    }>;
    pageInfo: { __typename?: 'PageInfo'; startCursor?: string; endCursor?: string };
  };
};

export const CreateChemicalDocument = gql`
  mutation createChemical($input: CreateChemicalInput!) {
    createChemical(input: $input) {
      id
      organisation {
        id
        name
      }
      name
      activeIngredient
      manufacturer
      description
      type
    }
  }
`;
export type CreateChemicalMutationFn = Apollo.MutationFunction<CreateChemicalMutation, CreateChemicalMutationVariables>;

/**
 * __useCreateChemicalMutation__
 *
 * To run a mutation, you first call `useCreateChemicalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChemicalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChemicalMutation, { data, loading, error }] = useCreateChemicalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChemicalMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateChemicalMutation, CreateChemicalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChemicalMutation, CreateChemicalMutationVariables>(CreateChemicalDocument, options);
}
export type CreateChemicalMutationHookResult = ReturnType<typeof useCreateChemicalMutation>;
export type CreateChemicalMutationResult = Apollo.MutationResult<CreateChemicalMutation>;
export type CreateChemicalMutationOptions = Apollo.BaseMutationOptions<
  CreateChemicalMutation,
  CreateChemicalMutationVariables
>;
export const DeleteChemicalDocument = gql`
  mutation deleteChemical($id: String!) {
    deleteChemical(id: $id) {
      id
      name
      deleteInformation {
        deletedAt
      }
    }
  }
`;
export type DeleteChemicalMutationFn = Apollo.MutationFunction<DeleteChemicalMutation, DeleteChemicalMutationVariables>;

/**
 * __useDeleteChemicalMutation__
 *
 * To run a mutation, you first call `useDeleteChemicalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChemicalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChemicalMutation, { data, loading, error }] = useDeleteChemicalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChemicalMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteChemicalMutation, DeleteChemicalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteChemicalMutation, DeleteChemicalMutationVariables>(DeleteChemicalDocument, options);
}
export type DeleteChemicalMutationHookResult = ReturnType<typeof useDeleteChemicalMutation>;
export type DeleteChemicalMutationResult = Apollo.MutationResult<DeleteChemicalMutation>;
export type DeleteChemicalMutationOptions = Apollo.BaseMutationOptions<
  DeleteChemicalMutation,
  DeleteChemicalMutationVariables
>;
export const UpdateChemicalDocument = gql`
  mutation updateChemical($input: UpdateChemicalInput!) {
    updateChemical(input: $input) {
      id
      organisation {
        id
        name
      }
      name
      activeIngredient
      manufacturer
      description
      type
    }
  }
`;
export type UpdateChemicalMutationFn = Apollo.MutationFunction<UpdateChemicalMutation, UpdateChemicalMutationVariables>;

/**
 * __useUpdateChemicalMutation__
 *
 * To run a mutation, you first call `useUpdateChemicalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChemicalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChemicalMutation, { data, loading, error }] = useUpdateChemicalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChemicalMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateChemicalMutation, UpdateChemicalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateChemicalMutation, UpdateChemicalMutationVariables>(UpdateChemicalDocument, options);
}
export type UpdateChemicalMutationHookResult = ReturnType<typeof useUpdateChemicalMutation>;
export type UpdateChemicalMutationResult = Apollo.MutationResult<UpdateChemicalMutation>;
export type UpdateChemicalMutationOptions = Apollo.BaseMutationOptions<
  UpdateChemicalMutation,
  UpdateChemicalMutationVariables
>;
export const ChemicalSearchDocument = gql`
  query chemicalSearch($id: String!, $first: Int, $where: ChemicalFilterInput) {
    chemicalsByOrganisationId(id: $id, first: $first, where: $where) {
      nodes {
        id
        name
        type
      }
    }
  }
`;

/**
 * __useChemicalSearchQuery__
 *
 * To run a query within a React component, call `useChemicalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useChemicalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChemicalSearchQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useChemicalSearchQuery(
  baseOptions: Apollo.QueryHookOptions<ChemicalSearchQuery, ChemicalSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChemicalSearchQuery, ChemicalSearchQueryVariables>(ChemicalSearchDocument, options);
}
export function useChemicalSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChemicalSearchQuery, ChemicalSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChemicalSearchQuery, ChemicalSearchQueryVariables>(ChemicalSearchDocument, options);
}
export type ChemicalSearchQueryHookResult = ReturnType<typeof useChemicalSearchQuery>;
export type ChemicalSearchLazyQueryHookResult = ReturnType<typeof useChemicalSearchLazyQuery>;
export type ChemicalSearchQueryResult = Apollo.QueryResult<ChemicalSearchQuery, ChemicalSearchQueryVariables>;
export const GetChemicalDocument = gql`
  query getChemical($id: String!, $first: Int, $after: String) {
    chemical(id: $id) {
      id
      organisation {
        id
      }
      name
      activeIngredient
      manufacturer
      description
      recipes(first: $first, after: $after) {
        edges {
          node {
            id
            name
            description
            deleteInformation {
              deletedAt
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
        }
        totalCount
      }
      deleteInformation {
        deletedAt
      }
      type
    }
  }
`;

/**
 * __useGetChemicalQuery__
 *
 * To run a query within a React component, call `useGetChemicalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChemicalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChemicalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetChemicalQuery(baseOptions: Apollo.QueryHookOptions<GetChemicalQuery, GetChemicalQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChemicalQuery, GetChemicalQueryVariables>(GetChemicalDocument, options);
}
export function useGetChemicalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChemicalQuery, GetChemicalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChemicalQuery, GetChemicalQueryVariables>(GetChemicalDocument, options);
}
export type GetChemicalQueryHookResult = ReturnType<typeof useGetChemicalQuery>;
export type GetChemicalLazyQueryHookResult = ReturnType<typeof useGetChemicalLazyQuery>;
export type GetChemicalQueryResult = Apollo.QueryResult<GetChemicalQuery, GetChemicalQueryVariables>;
export const GetChemicalFromJobDocument = gql`
  query getChemicalFromJob($id: String!) {
    job(id: $id) {
      recipe {
        concentrations {
          chemical {
            id
            name
            activeIngredient
            manufacturer
            description
            type
          }
        }
      }
      completedAt
    }
  }
`;

/**
 * __useGetChemicalFromJobQuery__
 *
 * To run a query within a React component, call `useGetChemicalFromJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChemicalFromJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChemicalFromJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChemicalFromJobQuery(
  baseOptions: Apollo.QueryHookOptions<GetChemicalFromJobQuery, GetChemicalFromJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChemicalFromJobQuery, GetChemicalFromJobQueryVariables>(
    GetChemicalFromJobDocument,
    options,
  );
}
export function useGetChemicalFromJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChemicalFromJobQuery, GetChemicalFromJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChemicalFromJobQuery, GetChemicalFromJobQueryVariables>(
    GetChemicalFromJobDocument,
    options,
  );
}
export type GetChemicalFromJobQueryHookResult = ReturnType<typeof useGetChemicalFromJobQuery>;
export type GetChemicalFromJobLazyQueryHookResult = ReturnType<typeof useGetChemicalFromJobLazyQuery>;
export type GetChemicalFromJobQueryResult = Apollo.QueryResult<
  GetChemicalFromJobQuery,
  GetChemicalFromJobQueryVariables
>;
export const GetChemicalUsageDocument = gql`
  query getChemicalUsage($input: GetChemicalUsageInput!) {
    chemicalUsage(input: $input) {
      usage {
        key
        value {
          unit
          value
        }
      }
    }
  }
`;

/**
 * __useGetChemicalUsageQuery__
 *
 * To run a query within a React component, call `useGetChemicalUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChemicalUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChemicalUsageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChemicalUsageQuery(
  baseOptions: Apollo.QueryHookOptions<GetChemicalUsageQuery, GetChemicalUsageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChemicalUsageQuery, GetChemicalUsageQueryVariables>(GetChemicalUsageDocument, options);
}
export function useGetChemicalUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChemicalUsageQuery, GetChemicalUsageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChemicalUsageQuery, GetChemicalUsageQueryVariables>(GetChemicalUsageDocument, options);
}
export type GetChemicalUsageQueryHookResult = ReturnType<typeof useGetChemicalUsageQuery>;
export type GetChemicalUsageLazyQueryHookResult = ReturnType<typeof useGetChemicalUsageLazyQuery>;
export type GetChemicalUsageQueryResult = Apollo.QueryResult<GetChemicalUsageQuery, GetChemicalUsageQueryVariables>;
export const GetChemicalsByOrganisationIdDocument = gql`
  query getChemicalsByOrganisationId(
    $id: String!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: ChemicalFilterInput
    $order: [ChemicalSortInput!]
  ) {
    chemicalsByOrganisationId(
      id: $id
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      order: $order
    ) {
      edges {
        node {
          id
          organisation {
            id
          }
          name
          activeIngredient
          manufacturer
          description
          type
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetChemicalsByOrganisationIdQuery__
 *
 * To run a query within a React component, call `useGetChemicalsByOrganisationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChemicalsByOrganisationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChemicalsByOrganisationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetChemicalsByOrganisationIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetChemicalsByOrganisationIdQuery, GetChemicalsByOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChemicalsByOrganisationIdQuery, GetChemicalsByOrganisationIdQueryVariables>(
    GetChemicalsByOrganisationIdDocument,
    options,
  );
}
export function useGetChemicalsByOrganisationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChemicalsByOrganisationIdQuery,
    GetChemicalsByOrganisationIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChemicalsByOrganisationIdQuery, GetChemicalsByOrganisationIdQueryVariables>(
    GetChemicalsByOrganisationIdDocument,
    options,
  );
}
export type GetChemicalsByOrganisationIdQueryHookResult = ReturnType<typeof useGetChemicalsByOrganisationIdQuery>;
export type GetChemicalsByOrganisationIdLazyQueryHookResult = ReturnType<
  typeof useGetChemicalsByOrganisationIdLazyQuery
>;
export type GetChemicalsByOrganisationIdQueryResult = Apollo.QueryResult<
  GetChemicalsByOrganisationIdQuery,
  GetChemicalsByOrganisationIdQueryVariables
>;
export const ActivateDeviceDocument = gql`
  mutation activateDevice($input: ActivateDeviceInput!) {
    activateDevice(input: $input) {
      id
      organisation {
        id
      }
      alias
      calibrationVolume {
        value
        unit
      }
      calibrationTimeout
      rinseVolume {
        value
        unit
      }
      rinseTimeout
      loggingPeriod
      sleepTimeout
      gpsAccuracy {
        value
        unit
      }
      registrationDate
      activationDate
    }
  }
`;
export type ActivateDeviceMutationFn = Apollo.MutationFunction<ActivateDeviceMutation, ActivateDeviceMutationVariables>;

/**
 * __useActivateDeviceMutation__
 *
 * To run a mutation, you first call `useActivateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateDeviceMutation, { data, loading, error }] = useActivateDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateDeviceMutation, ActivateDeviceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateDeviceMutation, ActivateDeviceMutationVariables>(ActivateDeviceDocument, options);
}
export type ActivateDeviceMutationHookResult = ReturnType<typeof useActivateDeviceMutation>;
export type ActivateDeviceMutationResult = Apollo.MutationResult<ActivateDeviceMutation>;
export type ActivateDeviceMutationOptions = Apollo.BaseMutationOptions<
  ActivateDeviceMutation,
  ActivateDeviceMutationVariables
>;
export const AdminUpdateDeviceDocument = gql`
  mutation adminUpdateDevice($input: AdminUpdateDeviceInput!) {
    adminUpdateDevice(input: $input) {
      id
      organisation {
        id
      }
      alias
      calibrationVolume {
        value
        unit
      }
      calibrationTimeout
      rinseVolume {
        value
        unit
      }
      rinseTimeout
      loggingPeriod
      sleepTimeout
      gpsAccuracy {
        value
        unit
      }
      registrationDate
      activationDate
      firmwareInformation {
        lockStatus
        candidate {
          firmware {
            id
            version
          }
        }
      }
    }
  }
`;
export type AdminUpdateDeviceMutationFn = Apollo.MutationFunction<
  AdminUpdateDeviceMutation,
  AdminUpdateDeviceMutationVariables
>;

/**
 * __useAdminUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useAdminUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateDeviceMutation, { data, loading, error }] = useAdminUpdateDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUpdateDeviceMutation, AdminUpdateDeviceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateDeviceMutation, AdminUpdateDeviceMutationVariables>(
    AdminUpdateDeviceDocument,
    options,
  );
}
export type AdminUpdateDeviceMutationHookResult = ReturnType<typeof useAdminUpdateDeviceMutation>;
export type AdminUpdateDeviceMutationResult = Apollo.MutationResult<AdminUpdateDeviceMutation>;
export type AdminUpdateDeviceMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateDeviceMutation,
  AdminUpdateDeviceMutationVariables
>;
export const CreateDeviceDocument = gql`
  mutation createDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      id
      organisation {
        id
      }
      alias
      activationToken
      authenticationToken
      calibrationVolume {
        value
        unit
      }
      calibrationTimeout
      rinseVolume {
        value
        unit
      }
      rinseTimeout
      loggingPeriod
      sleepTimeout
      gpsAccuracy {
        value
        unit
      }
      registrationDate
      activationDate
    }
  }
`;
export type CreateDeviceMutationFn = Apollo.MutationFunction<CreateDeviceMutation, CreateDeviceMutationVariables>;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDeviceMutation, CreateDeviceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument, options);
}
export type CreateDeviceMutationHookResult = ReturnType<typeof useCreateDeviceMutation>;
export type CreateDeviceMutationResult = Apollo.MutationResult<CreateDeviceMutation>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<
  CreateDeviceMutation,
  CreateDeviceMutationVariables
>;
export const SetDeviceFirmwareByDeviceIdDocument = gql`
  mutation setDeviceFirmwareByDeviceId($input: SetDeviceFirmwareByDeviceIdInput!) {
    setDeviceFirmwareByDeviceId(input: $input) {
      id
      organisation {
        id
      }
      alias
      activationToken
      authenticationToken
      calibrationVolume {
        value
        unit
      }
      calibrationTimeout
      rinseVolume {
        value
        unit
      }
      rinseTimeout
      loggingPeriod
      sleepTimeout
      gpsAccuracy {
        value
        unit
      }
      registrationDate
      activationDate
      firmwareInformation {
        lockStatus
        candidate {
          firmware {
            id
            version
          }
        }
      }
    }
  }
`;
export type SetDeviceFirmwareByDeviceIdMutationFn = Apollo.MutationFunction<
  SetDeviceFirmwareByDeviceIdMutation,
  SetDeviceFirmwareByDeviceIdMutationVariables
>;

/**
 * __useSetDeviceFirmwareByDeviceIdMutation__
 *
 * To run a mutation, you first call `useSetDeviceFirmwareByDeviceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDeviceFirmwareByDeviceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDeviceFirmwareByDeviceIdMutation, { data, loading, error }] = useSetDeviceFirmwareByDeviceIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDeviceFirmwareByDeviceIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDeviceFirmwareByDeviceIdMutation,
    SetDeviceFirmwareByDeviceIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetDeviceFirmwareByDeviceIdMutation, SetDeviceFirmwareByDeviceIdMutationVariables>(
    SetDeviceFirmwareByDeviceIdDocument,
    options,
  );
}
export type SetDeviceFirmwareByDeviceIdMutationHookResult = ReturnType<typeof useSetDeviceFirmwareByDeviceIdMutation>;
export type SetDeviceFirmwareByDeviceIdMutationResult = Apollo.MutationResult<SetDeviceFirmwareByDeviceIdMutation>;
export type SetDeviceFirmwareByDeviceIdMutationOptions = Apollo.BaseMutationOptions<
  SetDeviceFirmwareByDeviceIdMutation,
  SetDeviceFirmwareByDeviceIdMutationVariables
>;
export const UpdateDeviceDocument = gql`
  mutation updateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      id
      organisation {
        id
      }
      alias
      calibrationVolume {
        value
        unit
      }
      calibrationTimeout
      rinseVolume {
        value
        unit
      }
      rinseTimeout
      loggingPeriod
      sleepTimeout
      gpsAccuracy {
        value
        unit
      }
      registrationDate
      activationDate
    }
  }
`;
export type UpdateDeviceMutationFn = Apollo.MutationFunction<UpdateDeviceMutation, UpdateDeviceMutationVariables>;

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceMutation, { data, loading, error }] = useUpdateDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDeviceMutation, UpdateDeviceMutationVariables>(UpdateDeviceDocument, options);
}
export type UpdateDeviceMutationHookResult = ReturnType<typeof useUpdateDeviceMutation>;
export type UpdateDeviceMutationResult = Apollo.MutationResult<UpdateDeviceMutation>;
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<
  UpdateDeviceMutation,
  UpdateDeviceMutationVariables
>;
export const AdminGetDevicesDocument = gql`
  query adminGetDevices($first: Int, $after: String, $where: DeviceFilterInput, $order: [DeviceSortInput!]) {
    devices(first: $first, after: $after, where: $where, order: $order) {
      edges {
        node {
          id
          organisation {
            id
            name
          }
          alias
          registrationDate
          activationDate
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useAdminGetDevicesQuery__
 *
 * To run a query within a React component, call `useAdminGetDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetDevicesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAdminGetDevicesQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminGetDevicesQuery, AdminGetDevicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetDevicesQuery, AdminGetDevicesQueryVariables>(AdminGetDevicesDocument, options);
}
export function useAdminGetDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetDevicesQuery, AdminGetDevicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetDevicesQuery, AdminGetDevicesQueryVariables>(AdminGetDevicesDocument, options);
}
export type AdminGetDevicesQueryHookResult = ReturnType<typeof useAdminGetDevicesQuery>;
export type AdminGetDevicesLazyQueryHookResult = ReturnType<typeof useAdminGetDevicesLazyQuery>;
export type AdminGetDevicesQueryResult = Apollo.QueryResult<AdminGetDevicesQuery, AdminGetDevicesQueryVariables>;
export const DevicesSearchDocument = gql`
  query devicesSearch($organisationId: String!, $first: Int, $where: DeviceFilterInput) {
    devicesByOrganisationId(id: $organisationId, first: $first, where: $where) {
      nodes {
        id
        alias
      }
    }
  }
`;

/**
 * __useDevicesSearchQuery__
 *
 * To run a query within a React component, call `useDevicesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesSearchQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDevicesSearchQuery(
  baseOptions: Apollo.QueryHookOptions<DevicesSearchQuery, DevicesSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DevicesSearchQuery, DevicesSearchQueryVariables>(DevicesSearchDocument, options);
}
export function useDevicesSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DevicesSearchQuery, DevicesSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DevicesSearchQuery, DevicesSearchQueryVariables>(DevicesSearchDocument, options);
}
export type DevicesSearchQueryHookResult = ReturnType<typeof useDevicesSearchQuery>;
export type DevicesSearchLazyQueryHookResult = ReturnType<typeof useDevicesSearchLazyQuery>;
export type DevicesSearchQueryResult = Apollo.QueryResult<DevicesSearchQuery, DevicesSearchQueryVariables>;
export const GetDeviceDocument = gql`
  query getDevice($id: String!, $first: Int, $after: String) {
    device(id: $id) {
      id
      organisation {
        id
        organisationFirmware {
          lockStatus
          firmware {
            id
          }
        }
      }
      alias
      calibrationVolume {
        value
        unit
      }
      calibrationTimeout
      rinseVolume {
        value
        unit
      }
      rinseTimeout
      loggingPeriod
      sleepTimeout
      gpsAccuracy {
        unit
        value
      }
      activationDate
      firmwareInformation {
        slot1 {
          firmware {
            version
            id
          }
          reportedAt
        }
        slot2 {
          firmware {
            version
            id
          }
          reportedAt
        }
        candidate {
          firmware {
            version
            id
          }
        }
        lockStatus
      }
      lastCalibration {
        time
        ticks
        volumeDispensed {
          value
          unit
        }
      }
      jobs(first: $first, after: $after) {
        edges {
          node {
            id
            name
            startTime
            endTime
            deviceAssignments(where: { deviceId: { eq: $id } }) {
              device {
                id
              }
              user {
                id
                fullName
              }
            }
            recipe {
              id
              name
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
`;

/**
 * __useGetDeviceQuery__
 *
 * To run a query within a React component, call `useGetDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetDeviceQuery(baseOptions: Apollo.QueryHookOptions<GetDeviceQuery, GetDeviceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDeviceQuery, GetDeviceQueryVariables>(GetDeviceDocument, options);
}
export function useGetDeviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceQuery, GetDeviceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDeviceQuery, GetDeviceQueryVariables>(GetDeviceDocument, options);
}
export type GetDeviceQueryHookResult = ReturnType<typeof useGetDeviceQuery>;
export type GetDeviceLazyQueryHookResult = ReturnType<typeof useGetDeviceLazyQuery>;
export type GetDeviceQueryResult = Apollo.QueryResult<GetDeviceQuery, GetDeviceQueryVariables>;
export const GetDevicesByOrganisationIdDocument = gql`
  query getDevicesByOrganisationId(
    $id: String!
    $first: Int
    $after: String
    $where: DeviceFilterInput
    $order: [DeviceSortInput!]
  ) {
    devicesByOrganisationId(id: $id, first: $first, after: $after, where: $where, order: $order) {
      edges {
        node {
          id
          organisation {
            name
          }
          alias
          calibrationVolume {
            value
            unit
          }
          calibrationTimeout
          rinseVolume {
            value
            unit
          }
          rinseTimeout
          loggingPeriod
          sleepTimeout
          gpsAccuracy {
            unit
            value
          }
          activationDate
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetDevicesByOrganisationIdQuery__
 *
 * To run a query within a React component, call `useGetDevicesByOrganisationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesByOrganisationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesByOrganisationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetDevicesByOrganisationIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetDevicesByOrganisationIdQuery, GetDevicesByOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDevicesByOrganisationIdQuery, GetDevicesByOrganisationIdQueryVariables>(
    GetDevicesByOrganisationIdDocument,
    options,
  );
}
export function useGetDevicesByOrganisationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDevicesByOrganisationIdQuery, GetDevicesByOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDevicesByOrganisationIdQuery, GetDevicesByOrganisationIdQueryVariables>(
    GetDevicesByOrganisationIdDocument,
    options,
  );
}
export type GetDevicesByOrganisationIdQueryHookResult = ReturnType<typeof useGetDevicesByOrganisationIdQuery>;
export type GetDevicesByOrganisationIdLazyQueryHookResult = ReturnType<typeof useGetDevicesByOrganisationIdLazyQuery>;
export type GetDevicesByOrganisationIdQueryResult = Apollo.QueryResult<
  GetDevicesByOrganisationIdQuery,
  GetDevicesByOrganisationIdQueryVariables
>;
export const GetManufacturingDevicesDocument = gql`
  query getManufacturingDevices($first: Int, $after: String, $where: DeviceFilterInput, $order: [DeviceSortInput!]) {
    manufacturingDevices(first: $first, after: $after, where: $where, order: $order) {
      edges {
        node {
          id
          organisation {
            id
          }
          alias
          activationToken
          authenticationToken
          calibrationVolume {
            value
            unit
          }
          calibrationTimeout
          rinseVolume {
            value
            unit
          }
          rinseTimeout
          loggingPeriod
          sleepTimeout
          gpsAccuracy {
            value
            unit
          }
          registrationDate
          activationDate
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetManufacturingDevicesQuery__
 *
 * To run a query within a React component, call `useGetManufacturingDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManufacturingDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManufacturingDevicesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetManufacturingDevicesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetManufacturingDevicesQuery, GetManufacturingDevicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManufacturingDevicesQuery, GetManufacturingDevicesQueryVariables>(
    GetManufacturingDevicesDocument,
    options,
  );
}
export function useGetManufacturingDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManufacturingDevicesQuery, GetManufacturingDevicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManufacturingDevicesQuery, GetManufacturingDevicesQueryVariables>(
    GetManufacturingDevicesDocument,
    options,
  );
}
export type GetManufacturingDevicesQueryHookResult = ReturnType<typeof useGetManufacturingDevicesQuery>;
export type GetManufacturingDevicesLazyQueryHookResult = ReturnType<typeof useGetManufacturingDevicesLazyQuery>;
export type GetManufacturingDevicesQueryResult = Apollo.QueryResult<
  GetManufacturingDevicesQuery,
  GetManufacturingDevicesQueryVariables
>;
export const GetSprayLogsForDeviceDocument = gql`
  query getSprayLogsForDevice($deviceId: String!, $first: Int, $after: String) {
    sprayLogsForDevice(first: $first, after: $after, deviceId: $deviceId) {
      edges {
        node {
          timestamp
          coordinates {
            latitude
            longitude
            altitude
            satellites
          }
          volumeDispensed {
            value
            unit
          }
          wasDropped
          temperature {
            value
            unit
          }
          weeds
          sprayMode
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetSprayLogsForDeviceQuery__
 *
 * To run a query within a React component, call `useGetSprayLogsForDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSprayLogsForDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSprayLogsForDeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetSprayLogsForDeviceQuery(
  baseOptions: Apollo.QueryHookOptions<GetSprayLogsForDeviceQuery, GetSprayLogsForDeviceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSprayLogsForDeviceQuery, GetSprayLogsForDeviceQueryVariables>(
    GetSprayLogsForDeviceDocument,
    options,
  );
}
export function useGetSprayLogsForDeviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSprayLogsForDeviceQuery, GetSprayLogsForDeviceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSprayLogsForDeviceQuery, GetSprayLogsForDeviceQueryVariables>(
    GetSprayLogsForDeviceDocument,
    options,
  );
}
export type GetSprayLogsForDeviceQueryHookResult = ReturnType<typeof useGetSprayLogsForDeviceQuery>;
export type GetSprayLogsForDeviceLazyQueryHookResult = ReturnType<typeof useGetSprayLogsForDeviceLazyQuery>;
export type GetSprayLogsForDeviceQueryResult = Apollo.QueryResult<
  GetSprayLogsForDeviceQuery,
  GetSprayLogsForDeviceQueryVariables
>;
export const ManufacturerGetDeviceDocument = gql`
  query manufacturerGetDevice($id: String!) {
    manufacturerDevice(id: $id) {
      id
      organisation {
        id
      }
      alias
      activationToken
      authenticationToken
      calibrationVolume {
        value
        unit
      }
      calibrationTimeout
      rinseVolume {
        value
        unit
      }
      rinseTimeout
      loggingPeriod
      sleepTimeout
      gpsAccuracy {
        value
        unit
      }
      registrationDate
      activationDate
      firmwareInformation {
        slot1 {
          firmware {
            version
            id
          }
          reportedAt
        }
        slot2 {
          firmware {
            version
            id
          }
          reportedAt
        }
        candidate {
          firmware {
            version
            id
          }
        }
        lockStatus
      }
    }
  }
`;

/**
 * __useManufacturerGetDeviceQuery__
 *
 * To run a query within a React component, call `useManufacturerGetDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufacturerGetDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManufacturerGetDeviceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useManufacturerGetDeviceQuery(
  baseOptions: Apollo.QueryHookOptions<ManufacturerGetDeviceQuery, ManufacturerGetDeviceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManufacturerGetDeviceQuery, ManufacturerGetDeviceQueryVariables>(
    ManufacturerGetDeviceDocument,
    options,
  );
}
export function useManufacturerGetDeviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManufacturerGetDeviceQuery, ManufacturerGetDeviceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManufacturerGetDeviceQuery, ManufacturerGetDeviceQueryVariables>(
    ManufacturerGetDeviceDocument,
    options,
  );
}
export type ManufacturerGetDeviceQueryHookResult = ReturnType<typeof useManufacturerGetDeviceQuery>;
export type ManufacturerGetDeviceLazyQueryHookResult = ReturnType<typeof useManufacturerGetDeviceLazyQuery>;
export type ManufacturerGetDeviceQueryResult = Apollo.QueryResult<
  ManufacturerGetDeviceQuery,
  ManufacturerGetDeviceQueryVariables
>;
export const CreateFirmwareDocument = gql`
  mutation createFirmware($input: CreateFirmwareInput!) {
    createFirmware(input: $input) {
      id
      version
      description
      uploadUrl
    }
  }
`;
export type CreateFirmwareMutationFn = Apollo.MutationFunction<CreateFirmwareMutation, CreateFirmwareMutationVariables>;

/**
 * __useCreateFirmwareMutation__
 *
 * To run a mutation, you first call `useCreateFirmwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFirmwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFirmwareMutation, { data, loading, error }] = useCreateFirmwareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFirmwareMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFirmwareMutation, CreateFirmwareMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFirmwareMutation, CreateFirmwareMutationVariables>(CreateFirmwareDocument, options);
}
export type CreateFirmwareMutationHookResult = ReturnType<typeof useCreateFirmwareMutation>;
export type CreateFirmwareMutationResult = Apollo.MutationResult<CreateFirmwareMutation>;
export type CreateFirmwareMutationOptions = Apollo.BaseMutationOptions<
  CreateFirmwareMutation,
  CreateFirmwareMutationVariables
>;
export const DisableFirmwareDocument = gql`
  mutation disableFirmware($id: String!) {
    disableFirmware(input: { id: $id }) {
      id
      status
    }
  }
`;
export type DisableFirmwareMutationFn = Apollo.MutationFunction<
  DisableFirmwareMutation,
  DisableFirmwareMutationVariables
>;

/**
 * __useDisableFirmwareMutation__
 *
 * To run a mutation, you first call `useDisableFirmwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableFirmwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableFirmwareMutation, { data, loading, error }] = useDisableFirmwareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableFirmwareMutation(
  baseOptions?: Apollo.MutationHookOptions<DisableFirmwareMutation, DisableFirmwareMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DisableFirmwareMutation, DisableFirmwareMutationVariables>(
    DisableFirmwareDocument,
    options,
  );
}
export type DisableFirmwareMutationHookResult = ReturnType<typeof useDisableFirmwareMutation>;
export type DisableFirmwareMutationResult = Apollo.MutationResult<DisableFirmwareMutation>;
export type DisableFirmwareMutationOptions = Apollo.BaseMutationOptions<
  DisableFirmwareMutation,
  DisableFirmwareMutationVariables
>;
export const AdminGetAllAvailableFirmwareDocument = gql`
  query adminGetAllAvailableFirmware(
    $first: Int
    $after: String
    $where: FirmwareFilterInput
    $order: [FirmwareSortInput!]
  ) {
    enabledFirmware(first: $first, after: $after, where: $where, order: $order) {
      edges {
        node {
          id
          status
          createInformation {
            createdBy
            createdAt
          }
          version
          description
          slot1ObjectKey
          slot2ObjectKey
          user {
            fullName
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useAdminGetAllAvailableFirmwareQuery__
 *
 * To run a query within a React component, call `useAdminGetAllAvailableFirmwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAllAvailableFirmwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAllAvailableFirmwareQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAdminGetAllAvailableFirmwareQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminGetAllAvailableFirmwareQuery, AdminGetAllAvailableFirmwareQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetAllAvailableFirmwareQuery, AdminGetAllAvailableFirmwareQueryVariables>(
    AdminGetAllAvailableFirmwareDocument,
    options,
  );
}
export function useAdminGetAllAvailableFirmwareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetAllAvailableFirmwareQuery,
    AdminGetAllAvailableFirmwareQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetAllAvailableFirmwareQuery, AdminGetAllAvailableFirmwareQueryVariables>(
    AdminGetAllAvailableFirmwareDocument,
    options,
  );
}
export type AdminGetAllAvailableFirmwareQueryHookResult = ReturnType<typeof useAdminGetAllAvailableFirmwareQuery>;
export type AdminGetAllAvailableFirmwareLazyQueryHookResult = ReturnType<
  typeof useAdminGetAllAvailableFirmwareLazyQuery
>;
export type AdminGetAllAvailableFirmwareQueryResult = Apollo.QueryResult<
  AdminGetAllAvailableFirmwareQuery,
  AdminGetAllAvailableFirmwareQueryVariables
>;
export const AdminGetAllFirmwareDocument = gql`
  query adminGetAllFirmware($first: Int, $after: String, $where: FirmwareFilterInput, $order: [FirmwareSortInput!]) {
    firmware(first: $first, after: $after, where: $where, order: $order) {
      edges {
        node {
          id
          status
          createInformation {
            createdBy
            createdAt
          }
          version
          description
          user {
            fullName
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useAdminGetAllFirmwareQuery__
 *
 * To run a query within a React component, call `useAdminGetAllFirmwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAllFirmwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAllFirmwareQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAdminGetAllFirmwareQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminGetAllFirmwareQuery, AdminGetAllFirmwareQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetAllFirmwareQuery, AdminGetAllFirmwareQueryVariables>(
    AdminGetAllFirmwareDocument,
    options,
  );
}
export function useAdminGetAllFirmwareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAllFirmwareQuery, AdminGetAllFirmwareQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetAllFirmwareQuery, AdminGetAllFirmwareQueryVariables>(
    AdminGetAllFirmwareDocument,
    options,
  );
}
export type AdminGetAllFirmwareQueryHookResult = ReturnType<typeof useAdminGetAllFirmwareQuery>;
export type AdminGetAllFirmwareLazyQueryHookResult = ReturnType<typeof useAdminGetAllFirmwareLazyQuery>;
export type AdminGetAllFirmwareQueryResult = Apollo.QueryResult<
  AdminGetAllFirmwareQuery,
  AdminGetAllFirmwareQueryVariables
>;
export const AdminGetFirmwareByIdDocument = gql`
  query adminGetFirmwareById($input: GetFirmwareInput!) {
    firmwareById(input: $input) {
      id
      status
      createInformation {
        createdBy
        createdAt
      }
      version
      description
      user {
        fullName
      }
    }
  }
`;

/**
 * __useAdminGetFirmwareByIdQuery__
 *
 * To run a query within a React component, call `useAdminGetFirmwareByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetFirmwareByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetFirmwareByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminGetFirmwareByIdQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetFirmwareByIdQuery, AdminGetFirmwareByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetFirmwareByIdQuery, AdminGetFirmwareByIdQueryVariables>(
    AdminGetFirmwareByIdDocument,
    options,
  );
}
export function useAdminGetFirmwareByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetFirmwareByIdQuery, AdminGetFirmwareByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetFirmwareByIdQuery, AdminGetFirmwareByIdQueryVariables>(
    AdminGetFirmwareByIdDocument,
    options,
  );
}
export type AdminGetFirmwareByIdQueryHookResult = ReturnType<typeof useAdminGetFirmwareByIdQuery>;
export type AdminGetFirmwareByIdLazyQueryHookResult = ReturnType<typeof useAdminGetFirmwareByIdLazyQuery>;
export type AdminGetFirmwareByIdQueryResult = Apollo.QueryResult<
  AdminGetFirmwareByIdQuery,
  AdminGetFirmwareByIdQueryVariables
>;
export const FirmwareSearchDocument = gql`
  query firmwareSearch($first: Int, $where: FirmwareFilterInput) {
    enabledFirmware(first: $first, where: $where) {
      nodes {
        id
        version
      }
    }
  }
`;

/**
 * __useFirmwareSearchQuery__
 *
 * To run a query within a React component, call `useFirmwareSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirmwareSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirmwareSearchQuery({
 *   variables: {
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFirmwareSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<FirmwareSearchQuery, FirmwareSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirmwareSearchQuery, FirmwareSearchQueryVariables>(FirmwareSearchDocument, options);
}
export function useFirmwareSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirmwareSearchQuery, FirmwareSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirmwareSearchQuery, FirmwareSearchQueryVariables>(FirmwareSearchDocument, options);
}
export type FirmwareSearchQueryHookResult = ReturnType<typeof useFirmwareSearchQuery>;
export type FirmwareSearchLazyQueryHookResult = ReturnType<typeof useFirmwareSearchLazyQuery>;
export type FirmwareSearchQueryResult = Apollo.QueryResult<FirmwareSearchQuery, FirmwareSearchQueryVariables>;
export const GetFirmwareByIdDocument = gql`
  query getFirmwareById($input: GetFirmwareInput!) {
    firmwareById(input: $input) {
      id
      version
      description
    }
  }
`;

/**
 * __useGetFirmwareByIdQuery__
 *
 * To run a query within a React component, call `useGetFirmwareByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirmwareByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirmwareByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFirmwareByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetFirmwareByIdQuery, GetFirmwareByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFirmwareByIdQuery, GetFirmwareByIdQueryVariables>(GetFirmwareByIdDocument, options);
}
export function useGetFirmwareByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFirmwareByIdQuery, GetFirmwareByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFirmwareByIdQuery, GetFirmwareByIdQueryVariables>(GetFirmwareByIdDocument, options);
}
export type GetFirmwareByIdQueryHookResult = ReturnType<typeof useGetFirmwareByIdQuery>;
export type GetFirmwareByIdLazyQueryHookResult = ReturnType<typeof useGetFirmwareByIdLazyQuery>;
export type GetFirmwareByIdQueryResult = Apollo.QueryResult<GetFirmwareByIdQuery, GetFirmwareByIdQueryVariables>;
export const BatchExportJobsDocument = gql`
  mutation BatchExportJobs($emails: [String!]!, $jobIds: [String!]!, $selectAll: Boolean!, $organizationId: String) {
    batchExportJobs(input: { emails: $emails, jobIds: $jobIds, selectAll: $selectAll, organizationId: $organizationId })
  }
`;
export type BatchExportJobsMutationFn = Apollo.MutationFunction<
  BatchExportJobsMutation,
  BatchExportJobsMutationVariables
>;

/**
 * __useBatchExportJobsMutation__
 *
 * To run a mutation, you first call `useBatchExportJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchExportJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchExportJobsMutation, { data, loading, error }] = useBatchExportJobsMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      jobIds: // value for 'jobIds'
 *      selectAll: // value for 'selectAll'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useBatchExportJobsMutation(
  baseOptions?: Apollo.MutationHookOptions<BatchExportJobsMutation, BatchExportJobsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BatchExportJobsMutation, BatchExportJobsMutationVariables>(
    BatchExportJobsDocument,
    options,
  );
}
export type BatchExportJobsMutationHookResult = ReturnType<typeof useBatchExportJobsMutation>;
export type BatchExportJobsMutationResult = Apollo.MutationResult<BatchExportJobsMutation>;
export type BatchExportJobsMutationOptions = Apollo.BaseMutationOptions<
  BatchExportJobsMutation,
  BatchExportJobsMutationVariables
>;
export const CompleteJobDocument = gql`
  mutation completeJob($input: CompleteJobInput!) {
    completeJob(input: $input) {
      id
      organisation {
        id
      }
      name
      description
      location {
        latitude
        longitude
        address
      }
      startTime
      endTime
      deviceAssignments {
        user {
          id
          fullName
        }
        device {
          id
          alias
        }
        sprayLogs {
          c {
            x
            y
            z {
              unit
              value
            }
          }
          v {
            value
            unit
          }
          d
          w
        }
      }
      weedAssignments {
        id
        name
      }
      recipe {
        id
        name
        concentrations {
          chemical {
            id
            name
            type
          }
          amount {
            unit
            value
          }
        }
      }
      status
      volumeDispensed {
        value
        unit
      }
      weatherInformation {
        temperature {
          value
          unit
        }
        humidity {
          value
          unit
        }
        windSpeed {
          value
          unit
        }
        windBearing {
          value
          unit
        }
        deltaT {
          value
          unit
        }
        timestamp
      }
      completedAt
      applicationType
    }
  }
`;
export type CompleteJobMutationFn = Apollo.MutationFunction<CompleteJobMutation, CompleteJobMutationVariables>;

/**
 * __useCompleteJobMutation__
 *
 * To run a mutation, you first call `useCompleteJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeJobMutation, { data, loading, error }] = useCompleteJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteJobMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteJobMutation, CompleteJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteJobMutation, CompleteJobMutationVariables>(CompleteJobDocument, options);
}
export type CompleteJobMutationHookResult = ReturnType<typeof useCompleteJobMutation>;
export type CompleteJobMutationResult = Apollo.MutationResult<CompleteJobMutation>;
export type CompleteJobMutationOptions = Apollo.BaseMutationOptions<CompleteJobMutation, CompleteJobMutationVariables>;
export const CreateJobDocument = gql`
  mutation createJob($input: CreateJobInput!) {
    createJob(input: $input) {
      id
      organisation {
        id
      }
      name
      description
      location {
        latitude
        longitude
        address
      }
      startTime
      endTime
      deviceAssignments {
        user {
          id
          fullName
        }
        device {
          id
          alias
        }
      }
      weedAssignments {
        id
        name
      }
      recipe {
        id
        name
      }
      applicationType
      status
    }
  }
`;
export type CreateJobMutationFn = Apollo.MutationFunction<CreateJobMutation, CreateJobMutationVariables>;

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, options);
}
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = Apollo.MutationResult<CreateJobMutation>;
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<CreateJobMutation, CreateJobMutationVariables>;
export const DeleteJobDocument = gql`
  mutation deleteJob($input: DeleteJobInput!) {
    deleteJob(input: $input)
  }
`;
export type DeleteJobMutationFn = Apollo.MutationFunction<DeleteJobMutation, DeleteJobMutationVariables>;

/**
 * __useDeleteJobMutation__
 *
 * To run a mutation, you first call `useDeleteJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobMutation, { data, loading, error }] = useDeleteJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteJobMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteJobMutation, DeleteJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteJobMutation, DeleteJobMutationVariables>(DeleteJobDocument, options);
}
export type DeleteJobMutationHookResult = ReturnType<typeof useDeleteJobMutation>;
export type DeleteJobMutationResult = Apollo.MutationResult<DeleteJobMutation>;
export type DeleteJobMutationOptions = Apollo.BaseMutationOptions<DeleteJobMutation, DeleteJobMutationVariables>;
export const JobUpdateSprayAreaDocument = gql`
  mutation jobUpdateSprayArea($input: UpdateSprayAreaInput!) {
    updateSprayArea(input: $input) {
      id
      areaSprayed {
        value
        unit
      }
      nozzleWidth {
        value
        unit
      }
    }
  }
`;
export type JobUpdateSprayAreaMutationFn = Apollo.MutationFunction<
  JobUpdateSprayAreaMutation,
  JobUpdateSprayAreaMutationVariables
>;

/**
 * __useJobUpdateSprayAreaMutation__
 *
 * To run a mutation, you first call `useJobUpdateSprayAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobUpdateSprayAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobUpdateSprayAreaMutation, { data, loading, error }] = useJobUpdateSprayAreaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJobUpdateSprayAreaMutation(
  baseOptions?: Apollo.MutationHookOptions<JobUpdateSprayAreaMutation, JobUpdateSprayAreaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JobUpdateSprayAreaMutation, JobUpdateSprayAreaMutationVariables>(
    JobUpdateSprayAreaDocument,
    options,
  );
}
export type JobUpdateSprayAreaMutationHookResult = ReturnType<typeof useJobUpdateSprayAreaMutation>;
export type JobUpdateSprayAreaMutationResult = Apollo.MutationResult<JobUpdateSprayAreaMutation>;
export type JobUpdateSprayAreaMutationOptions = Apollo.BaseMutationOptions<
  JobUpdateSprayAreaMutation,
  JobUpdateSprayAreaMutationVariables
>;
export const RespondToOrgShareDocument = gql`
  mutation respondToOrgShare($jobId: String!, $organisationId: String!, $status: ShareStatus!) {
    respondToOrgShare(input: { jobId: $jobId, organisationId: $organisationId, status: $status }) {
      id
    }
  }
`;
export type RespondToOrgShareMutationFn = Apollo.MutationFunction<
  RespondToOrgShareMutation,
  RespondToOrgShareMutationVariables
>;

/**
 * __useRespondToOrgShareMutation__
 *
 * To run a mutation, you first call `useRespondToOrgShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToOrgShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToOrgShareMutation, { data, loading, error }] = useRespondToOrgShareMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      organisationId: // value for 'organisationId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useRespondToOrgShareMutation(
  baseOptions?: Apollo.MutationHookOptions<RespondToOrgShareMutation, RespondToOrgShareMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RespondToOrgShareMutation, RespondToOrgShareMutationVariables>(
    RespondToOrgShareDocument,
    options,
  );
}
export type RespondToOrgShareMutationHookResult = ReturnType<typeof useRespondToOrgShareMutation>;
export type RespondToOrgShareMutationResult = Apollo.MutationResult<RespondToOrgShareMutation>;
export type RespondToOrgShareMutationOptions = Apollo.BaseMutationOptions<
  RespondToOrgShareMutation,
  RespondToOrgShareMutationVariables
>;
export const RevokeOrgShareDocument = gql`
  mutation revokeOrgShare($jobId: String!, $organisationId: String!) {
    revokeOrgShare(input: { jobId: $jobId, organisationId: $organisationId }) {
      id
      organisation {
        id
      }
    }
  }
`;
export type RevokeOrgShareMutationFn = Apollo.MutationFunction<RevokeOrgShareMutation, RevokeOrgShareMutationVariables>;

/**
 * __useRevokeOrgShareMutation__
 *
 * To run a mutation, you first call `useRevokeOrgShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeOrgShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeOrgShareMutation, { data, loading, error }] = useRevokeOrgShareMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useRevokeOrgShareMutation(
  baseOptions?: Apollo.MutationHookOptions<RevokeOrgShareMutation, RevokeOrgShareMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RevokeOrgShareMutation, RevokeOrgShareMutationVariables>(RevokeOrgShareDocument, options);
}
export type RevokeOrgShareMutationHookResult = ReturnType<typeof useRevokeOrgShareMutation>;
export type RevokeOrgShareMutationResult = Apollo.MutationResult<RevokeOrgShareMutation>;
export type RevokeOrgShareMutationOptions = Apollo.BaseMutationOptions<
  RevokeOrgShareMutation,
  RevokeOrgShareMutationVariables
>;
export const ShareJobWithOrganisationDocument = gql`
  mutation shareJobWithOrganisation($emails: [String!]!, $jobId: String!) {
    shareJobWithOrganisation(input: { jobId: $jobId, emails: $emails }) {
      id
      sharedOrganisations {
        status
        organisation {
          id
          name
        }
        lastShared
      }
    }
  }
`;
export type ShareJobWithOrganisationMutationFn = Apollo.MutationFunction<
  ShareJobWithOrganisationMutation,
  ShareJobWithOrganisationMutationVariables
>;

/**
 * __useShareJobWithOrganisationMutation__
 *
 * To run a mutation, you first call `useShareJobWithOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareJobWithOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareJobWithOrganisationMutation, { data, loading, error }] = useShareJobWithOrganisationMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useShareJobWithOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<ShareJobWithOrganisationMutation, ShareJobWithOrganisationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShareJobWithOrganisationMutation, ShareJobWithOrganisationMutationVariables>(
    ShareJobWithOrganisationDocument,
    options,
  );
}
export type ShareJobWithOrganisationMutationHookResult = ReturnType<typeof useShareJobWithOrganisationMutation>;
export type ShareJobWithOrganisationMutationResult = Apollo.MutationResult<ShareJobWithOrganisationMutation>;
export type ShareJobWithOrganisationMutationOptions = Apollo.BaseMutationOptions<
  ShareJobWithOrganisationMutation,
  ShareJobWithOrganisationMutationVariables
>;
export const UpdateJobDocument = gql`
  mutation updateJob($input: UpdateJobInput!) {
    updateJob(input: $input) {
      id
      organisation {
        id
      }
      name
      description
      location {
        latitude
        longitude
        address
      }
      startTime
      endTime
      deviceAssignments {
        user {
          id
          fullName
        }
        device {
          id
          alias
        }
      }
      weedAssignments {
        id
        name
      }
      recipe {
        id
        name
      }
      applicationType
    }
  }
`;
export type UpdateJobMutationFn = Apollo.MutationFunction<UpdateJobMutation, UpdateJobMutationVariables>;

/**
 * __useUpdateJobMutation__
 *
 * To run a mutation, you first call `useUpdateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobMutation, { data, loading, error }] = useUpdateJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJobMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateJobMutation, UpdateJobMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateJobMutation, UpdateJobMutationVariables>(UpdateJobDocument, options);
}
export type UpdateJobMutationHookResult = ReturnType<typeof useUpdateJobMutation>;
export type UpdateJobMutationResult = Apollo.MutationResult<UpdateJobMutation>;
export type UpdateJobMutationOptions = Apollo.BaseMutationOptions<UpdateJobMutation, UpdateJobMutationVariables>;
export const GetActiveJobsDocument = gql`
  query getActiveJobs($id: String!, $first: Int, $after: String) {
    activeJobs(id: $id, first: $first, after: $after) {
      edges {
        node {
          id
          name
          description
          startTime
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetActiveJobsQuery__
 *
 * To run a query within a React component, call `useGetActiveJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveJobsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetActiveJobsQuery(
  baseOptions: Apollo.QueryHookOptions<GetActiveJobsQuery, GetActiveJobsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveJobsQuery, GetActiveJobsQueryVariables>(GetActiveJobsDocument, options);
}
export function useGetActiveJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActiveJobsQuery, GetActiveJobsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActiveJobsQuery, GetActiveJobsQueryVariables>(GetActiveJobsDocument, options);
}
export type GetActiveJobsQueryHookResult = ReturnType<typeof useGetActiveJobsQuery>;
export type GetActiveJobsLazyQueryHookResult = ReturnType<typeof useGetActiveJobsLazyQuery>;
export type GetActiveJobsQueryResult = Apollo.QueryResult<GetActiveJobsQuery, GetActiveJobsQueryVariables>;
export const GetCreateJobContentDocument = gql`
  query getCreateJobContent($id: String!) {
    devicesByOrganisationId(id: $id) {
      edges {
        node {
          id
          alias
        }
      }
    }
    usersByOrganisationId(id: $id) {
      edges {
        node {
          id
          fullName
        }
      }
    }
    recipesByOrganisationId(id: $id) {
      edges {
        node {
          id
          name
        }
      }
    }
    weedsByOrganisationId(id: $id) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetCreateJobContentQuery__
 *
 * To run a query within a React component, call `useGetCreateJobContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreateJobContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreateJobContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCreateJobContentQuery(
  baseOptions: Apollo.QueryHookOptions<GetCreateJobContentQuery, GetCreateJobContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCreateJobContentQuery, GetCreateJobContentQueryVariables>(
    GetCreateJobContentDocument,
    options,
  );
}
export function useGetCreateJobContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCreateJobContentQuery, GetCreateJobContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCreateJobContentQuery, GetCreateJobContentQueryVariables>(
    GetCreateJobContentDocument,
    options,
  );
}
export type GetCreateJobContentQueryHookResult = ReturnType<typeof useGetCreateJobContentQuery>;
export type GetCreateJobContentLazyQueryHookResult = ReturnType<typeof useGetCreateJobContentLazyQuery>;
export type GetCreateJobContentQueryResult = Apollo.QueryResult<
  GetCreateJobContentQuery,
  GetCreateJobContentQueryVariables
>;
export const ExistingJobsDocument = gql`
  query existingJobs($id: String!, $first: Int!, $searchString: String!) {
    existingJobs(id: $id, searchString: $searchString, first: $first) {
      edges {
        node {
          id
          organisation {
            id
          }
          name
          description
          startTime
          endTime
          status
          location {
            address
            latitude
            longitude
          }
          weedAssignments {
            id
            name
          }
          deviceAssignments {
            device {
              id
              alias
            }
            user {
              id
              fullName
            }
          }
          recipe {
            id
            name
          }
          applicationType
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useExistingJobsQuery__
 *
 * To run a query within a React component, call `useExistingJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistingJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistingJobsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      searchString: // value for 'searchString'
 *   },
 * });
 */
export function useExistingJobsQuery(
  baseOptions: Apollo.QueryHookOptions<ExistingJobsQuery, ExistingJobsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExistingJobsQuery, ExistingJobsQueryVariables>(ExistingJobsDocument, options);
}
export function useExistingJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExistingJobsQuery, ExistingJobsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExistingJobsQuery, ExistingJobsQueryVariables>(ExistingJobsDocument, options);
}
export type ExistingJobsQueryHookResult = ReturnType<typeof useExistingJobsQuery>;
export type ExistingJobsLazyQueryHookResult = ReturnType<typeof useExistingJobsLazyQuery>;
export type ExistingJobsQueryResult = Apollo.QueryResult<ExistingJobsQuery, ExistingJobsQueryVariables>;
export const GetJobDocument = gql`
  query getJob($id: String!, $shared: Boolean) {
    job(id: $id, shared: $shared) {
      id
      organisation {
        id
        name
      }
      name
      description
      location {
        latitude
        longitude
        address
      }
      startTime
      endTime
      areaSprayed {
        value
        unit
      }
      nozzleWidth {
        value
        unit
      }
      deviceAssignments {
        id
        user {
          id
          fullName
        }
        device {
          id
          alias
        }
        sprayLogs {
          c {
            x
            y
          }
          v {
            value
            unit
          }
          w
          t
        }
        volumeDispensed {
          value
          unit
        }
      }
      sharedOrganisations {
        organisation {
          id
          name
        }
        status
      }
      weedAssignments {
        id
        name
      }
      recipe {
        id
        name
        concentrations {
          chemical {
            id
            name
            type
          }
          amount {
            unit
            value
          }
        }
      }
      status
      volumeDispensed {
        value
        unit
      }
      weatherInformation {
        temperature {
          value
          unit
        }
        humidity {
          unit
          value
        }
        windSpeed {
          value
          unit
        }
        windBearing {
          value
          unit
        }
        deltaT {
          value
          unit
        }
        timestamp
      }
      completedAt
      applicationType
    }
  }
`;

/**
 * __useGetJobQuery__
 *
 * To run a query within a React component, call `useGetJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *      shared: // value for 'shared'
 *   },
 * });
 */
export function useGetJobQuery(baseOptions: Apollo.QueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
}
export function useGetJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobQuery, GetJobQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJobQuery, GetJobQueryVariables>(GetJobDocument, options);
}
export type GetJobQueryHookResult = ReturnType<typeof useGetJobQuery>;
export type GetJobLazyQueryHookResult = ReturnType<typeof useGetJobLazyQuery>;
export type GetJobQueryResult = Apollo.QueryResult<GetJobQuery, GetJobQueryVariables>;
export const JobsByOrganisationIdDocument = gql`
  query jobsByOrganisationId(
    $id: String!
    $first: Int
    $after: String
    $where: JobFilterInput
    $order: [JobSortInput!]
  ) {
    jobsByOrganisationId(id: $id, first: $first, after: $after, where: $where, order: $order) {
      edges {
        node {
          id
          organisation {
            id
          }
          name
          description
          startTime
          endTime
          status
          location {
            address
            latitude
            longitude
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useJobsByOrganisationIdQuery__
 *
 * To run a query within a React component, call `useJobsByOrganisationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsByOrganisationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsByOrganisationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useJobsByOrganisationIdQuery(
  baseOptions: Apollo.QueryHookOptions<JobsByOrganisationIdQuery, JobsByOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobsByOrganisationIdQuery, JobsByOrganisationIdQueryVariables>(
    JobsByOrganisationIdDocument,
    options,
  );
}
export function useJobsByOrganisationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobsByOrganisationIdQuery, JobsByOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobsByOrganisationIdQuery, JobsByOrganisationIdQueryVariables>(
    JobsByOrganisationIdDocument,
    options,
  );
}
export type JobsByOrganisationIdQueryHookResult = ReturnType<typeof useJobsByOrganisationIdQuery>;
export type JobsByOrganisationIdLazyQueryHookResult = ReturnType<typeof useJobsByOrganisationIdLazyQuery>;
export type JobsByOrganisationIdQueryResult = Apollo.QueryResult<
  JobsByOrganisationIdQuery,
  JobsByOrganisationIdQueryVariables
>;
export const JobsByUserIdDocument = gql`
  query jobsByUserId($first: Int, $after: String, $where: JobFilterInput, $order: [JobSortInput!]) {
    myJobs(first: $first, after: $after, where: $where, order: $order) {
      edges {
        node {
          id
          name
          description
          startTime
          endTime
          status
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useJobsByUserIdQuery__
 *
 * To run a query within a React component, call `useJobsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsByUserIdQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useJobsByUserIdQuery(
  baseOptions?: Apollo.QueryHookOptions<JobsByUserIdQuery, JobsByUserIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobsByUserIdQuery, JobsByUserIdQueryVariables>(JobsByUserIdDocument, options);
}
export function useJobsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<JobsByUserIdQuery, JobsByUserIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobsByUserIdQuery, JobsByUserIdQueryVariables>(JobsByUserIdDocument, options);
}
export type JobsByUserIdQueryHookResult = ReturnType<typeof useJobsByUserIdQuery>;
export type JobsByUserIdLazyQueryHookResult = ReturnType<typeof useJobsByUserIdLazyQuery>;
export type JobsByUserIdQueryResult = Apollo.QueryResult<JobsByUserIdQuery, JobsByUserIdQueryVariables>;
export const JobsSharedWithOrganisationIdDocument = gql`
  query jobsSharedWithOrganisationId($id: String!, $first: Int, $after: String) {
    jobsSharedWithOrganisationId(id: $id, first: $first, after: $after) {
      edges {
        node {
          id
          organisation {
            id
          }
          name
          description
          startTime
          endTime
          status
          location {
            address
            latitude
            longitude
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useJobsSharedWithOrganisationIdQuery__
 *
 * To run a query within a React component, call `useJobsSharedWithOrganisationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsSharedWithOrganisationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsSharedWithOrganisationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useJobsSharedWithOrganisationIdQuery(
  baseOptions: Apollo.QueryHookOptions<JobsSharedWithOrganisationIdQuery, JobsSharedWithOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<JobsSharedWithOrganisationIdQuery, JobsSharedWithOrganisationIdQueryVariables>(
    JobsSharedWithOrganisationIdDocument,
    options,
  );
}
export function useJobsSharedWithOrganisationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    JobsSharedWithOrganisationIdQuery,
    JobsSharedWithOrganisationIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<JobsSharedWithOrganisationIdQuery, JobsSharedWithOrganisationIdQueryVariables>(
    JobsSharedWithOrganisationIdDocument,
    options,
  );
}
export type JobsSharedWithOrganisationIdQueryHookResult = ReturnType<typeof useJobsSharedWithOrganisationIdQuery>;
export type JobsSharedWithOrganisationIdLazyQueryHookResult = ReturnType<
  typeof useJobsSharedWithOrganisationIdLazyQuery
>;
export type JobsSharedWithOrganisationIdQueryResult = Apollo.QueryResult<
  JobsSharedWithOrganisationIdQuery,
  JobsSharedWithOrganisationIdQueryVariables
>;
export const AdminCreateOrganisationDocument = gql`
  mutation adminCreateOrganisation($input: CreateOrganisationInput!) {
    createOrganisation(input: $input) {
      id
      businessNumber
      name
      description
      address
      contactName
      contactEmail
      contactPhone
      enabled
      unitSystem
    }
  }
`;
export type AdminCreateOrganisationMutationFn = Apollo.MutationFunction<
  AdminCreateOrganisationMutation,
  AdminCreateOrganisationMutationVariables
>;

/**
 * __useAdminCreateOrganisationMutation__
 *
 * To run a mutation, you first call `useAdminCreateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateOrganisationMutation, { data, loading, error }] = useAdminCreateOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminCreateOrganisationMutation, AdminCreateOrganisationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateOrganisationMutation, AdminCreateOrganisationMutationVariables>(
    AdminCreateOrganisationDocument,
    options,
  );
}
export type AdminCreateOrganisationMutationHookResult = ReturnType<typeof useAdminCreateOrganisationMutation>;
export type AdminCreateOrganisationMutationResult = Apollo.MutationResult<AdminCreateOrganisationMutation>;
export type AdminCreateOrganisationMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateOrganisationMutation,
  AdminCreateOrganisationMutationVariables
>;
export const AdminDisableOrganisationDocument = gql`
  mutation adminDisableOrganisation($input: ToggleOrganisationInput!) {
    disableOrganisation(input: $input) {
      id
      businessNumber
      name
      description
      address
      contactName
      contactPhone
      contactEmail
      users {
        id
        firstName
        lastName
        role
        enabled
      }
      devices {
        id
        alias
      }
      enabled
    }
  }
`;
export type AdminDisableOrganisationMutationFn = Apollo.MutationFunction<
  AdminDisableOrganisationMutation,
  AdminDisableOrganisationMutationVariables
>;

/**
 * __useAdminDisableOrganisationMutation__
 *
 * To run a mutation, you first call `useAdminDisableOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDisableOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDisableOrganisationMutation, { data, loading, error }] = useAdminDisableOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDisableOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminDisableOrganisationMutation, AdminDisableOrganisationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDisableOrganisationMutation, AdminDisableOrganisationMutationVariables>(
    AdminDisableOrganisationDocument,
    options,
  );
}
export type AdminDisableOrganisationMutationHookResult = ReturnType<typeof useAdminDisableOrganisationMutation>;
export type AdminDisableOrganisationMutationResult = Apollo.MutationResult<AdminDisableOrganisationMutation>;
export type AdminDisableOrganisationMutationOptions = Apollo.BaseMutationOptions<
  AdminDisableOrganisationMutation,
  AdminDisableOrganisationMutationVariables
>;
export const AdminEnableOrganisationDocument = gql`
  mutation adminEnableOrganisation($input: ToggleOrganisationInput!) {
    enableOrganisation(input: $input) {
      id
      businessNumber
      name
      description
      address
      contactName
      contactPhone
      contactEmail
      users {
        id
        firstName
        lastName
        role
        enabled
      }
      devices {
        id
        alias
      }
      enabled
    }
  }
`;
export type AdminEnableOrganisationMutationFn = Apollo.MutationFunction<
  AdminEnableOrganisationMutation,
  AdminEnableOrganisationMutationVariables
>;

/**
 * __useAdminEnableOrganisationMutation__
 *
 * To run a mutation, you first call `useAdminEnableOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEnableOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEnableOrganisationMutation, { data, loading, error }] = useAdminEnableOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEnableOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminEnableOrganisationMutation, AdminEnableOrganisationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEnableOrganisationMutation, AdminEnableOrganisationMutationVariables>(
    AdminEnableOrganisationDocument,
    options,
  );
}
export type AdminEnableOrganisationMutationHookResult = ReturnType<typeof useAdminEnableOrganisationMutation>;
export type AdminEnableOrganisationMutationResult = Apollo.MutationResult<AdminEnableOrganisationMutation>;
export type AdminEnableOrganisationMutationOptions = Apollo.BaseMutationOptions<
  AdminEnableOrganisationMutation,
  AdminEnableOrganisationMutationVariables
>;
export const AdminUpdateOrganisationDocument = gql`
  mutation adminUpdateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      id
      businessNumber
      name
      description
      address
      contactName
      contactEmail
      contactPhone
      unitSystem
    }
  }
`;
export type AdminUpdateOrganisationMutationFn = Apollo.MutationFunction<
  AdminUpdateOrganisationMutation,
  AdminUpdateOrganisationMutationVariables
>;

/**
 * __useAdminUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useAdminUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateOrganisationMutation, { data, loading, error }] = useAdminUpdateOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUpdateOrganisationMutation, AdminUpdateOrganisationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateOrganisationMutation, AdminUpdateOrganisationMutationVariables>(
    AdminUpdateOrganisationDocument,
    options,
  );
}
export type AdminUpdateOrganisationMutationHookResult = ReturnType<typeof useAdminUpdateOrganisationMutation>;
export type AdminUpdateOrganisationMutationResult = Apollo.MutationResult<AdminUpdateOrganisationMutation>;
export type AdminUpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateOrganisationMutation,
  AdminUpdateOrganisationMutationVariables
>;
export const BatchSetOrganisationFirmwareDocument = gql`
  mutation batchSetOrganisationFirmware($input: BatchSetOrganisationFirmwareInput!) {
    batchSetOrganisationFirmware(input: $input) {
      id
      organisationFirmware {
        firmware {
          id
          status
          createInformation {
            createdBy
            createdAt
          }
          version
          description
        }
        lockStatus
      }
    }
  }
`;
export type BatchSetOrganisationFirmwareMutationFn = Apollo.MutationFunction<
  BatchSetOrganisationFirmwareMutation,
  BatchSetOrganisationFirmwareMutationVariables
>;

/**
 * __useBatchSetOrganisationFirmwareMutation__
 *
 * To run a mutation, you first call `useBatchSetOrganisationFirmwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchSetOrganisationFirmwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchSetOrganisationFirmwareMutation, { data, loading, error }] = useBatchSetOrganisationFirmwareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchSetOrganisationFirmwareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchSetOrganisationFirmwareMutation,
    BatchSetOrganisationFirmwareMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BatchSetOrganisationFirmwareMutation, BatchSetOrganisationFirmwareMutationVariables>(
    BatchSetOrganisationFirmwareDocument,
    options,
  );
}
export type BatchSetOrganisationFirmwareMutationHookResult = ReturnType<typeof useBatchSetOrganisationFirmwareMutation>;
export type BatchSetOrganisationFirmwareMutationResult = Apollo.MutationResult<BatchSetOrganisationFirmwareMutation>;
export type BatchSetOrganisationFirmwareMutationOptions = Apollo.BaseMutationOptions<
  BatchSetOrganisationFirmwareMutation,
  BatchSetOrganisationFirmwareMutationVariables
>;
export const SetOrganisationFirmwareDocument = gql`
  mutation setOrganisationFirmware($input: SetOrganisationFirmwareInput!) {
    setOrganisationFirmware(input: $input) {
      id
      enabled
      organisationFirmware {
        firmware {
          id
          status
          createInformation {
            createdBy
            createdAt
          }
          version
          description
        }
        lockStatus
      }
    }
  }
`;
export type SetOrganisationFirmwareMutationFn = Apollo.MutationFunction<
  SetOrganisationFirmwareMutation,
  SetOrganisationFirmwareMutationVariables
>;

/**
 * __useSetOrganisationFirmwareMutation__
 *
 * To run a mutation, you first call `useSetOrganisationFirmwareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganisationFirmwareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganisationFirmwareMutation, { data, loading, error }] = useSetOrganisationFirmwareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrganisationFirmwareMutation(
  baseOptions?: Apollo.MutationHookOptions<SetOrganisationFirmwareMutation, SetOrganisationFirmwareMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOrganisationFirmwareMutation, SetOrganisationFirmwareMutationVariables>(
    SetOrganisationFirmwareDocument,
    options,
  );
}
export type SetOrganisationFirmwareMutationHookResult = ReturnType<typeof useSetOrganisationFirmwareMutation>;
export type SetOrganisationFirmwareMutationResult = Apollo.MutationResult<SetOrganisationFirmwareMutation>;
export type SetOrganisationFirmwareMutationOptions = Apollo.BaseMutationOptions<
  SetOrganisationFirmwareMutation,
  SetOrganisationFirmwareMutationVariables
>;
export const AdminGetOrganisationDocument = gql`
  query adminGetOrganisation($id: String!) {
    organisation(id: $id) {
      id
      businessNumber
      name
      description
      address
      contactName
      contactEmail
      contactPhone
      unitSystem
      users {
        id
        firstName
        lastName
        role
        enabled
      }
      devices {
        id
        alias
      }
      enabled
      organisationFirmware {
        firmware {
          id
          version
          description
        }
        lockStatus
      }
    }
  }
`;

/**
 * __useAdminGetOrganisationQuery__
 *
 * To run a query within a React component, call `useAdminGetOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetOrganisationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetOrganisationQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetOrganisationQuery, AdminGetOrganisationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetOrganisationQuery, AdminGetOrganisationQueryVariables>(
    AdminGetOrganisationDocument,
    options,
  );
}
export function useAdminGetOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetOrganisationQuery, AdminGetOrganisationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetOrganisationQuery, AdminGetOrganisationQueryVariables>(
    AdminGetOrganisationDocument,
    options,
  );
}
export type AdminGetOrganisationQueryHookResult = ReturnType<typeof useAdminGetOrganisationQuery>;
export type AdminGetOrganisationLazyQueryHookResult = ReturnType<typeof useAdminGetOrganisationLazyQuery>;
export type AdminGetOrganisationQueryResult = Apollo.QueryResult<
  AdminGetOrganisationQuery,
  AdminGetOrganisationQueryVariables
>;
export const AdminGetOrganisationsDocument = gql`
  query adminGetOrganisations(
    $first: Int
    $after: String
    $where: OrganisationFilterInput
    $order: [OrganisationSortInput!]
  ) {
    organisations(first: $first, after: $after, where: $where, order: $order) {
      edges {
        node {
          id
          name
          description
          users {
            id
          }
          devices {
            id
          }
          enabled
          organisationFirmware {
            firmware {
              id
              status
              createInformation {
                createdBy
                createdAt
              }
              version
              description
            }
            lockStatus
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useAdminGetOrganisationsQuery__
 *
 * To run a query within a React component, call `useAdminGetOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetOrganisationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAdminGetOrganisationsQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminGetOrganisationsQuery, AdminGetOrganisationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetOrganisationsQuery, AdminGetOrganisationsQueryVariables>(
    AdminGetOrganisationsDocument,
    options,
  );
}
export function useAdminGetOrganisationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetOrganisationsQuery, AdminGetOrganisationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetOrganisationsQuery, AdminGetOrganisationsQueryVariables>(
    AdminGetOrganisationsDocument,
    options,
  );
}
export type AdminGetOrganisationsQueryHookResult = ReturnType<typeof useAdminGetOrganisationsQuery>;
export type AdminGetOrganisationsLazyQueryHookResult = ReturnType<typeof useAdminGetOrganisationsLazyQuery>;
export type AdminGetOrganisationsQueryResult = Apollo.QueryResult<
  AdminGetOrganisationsQuery,
  AdminGetOrganisationsQueryVariables
>;
export const GetOrganisationDocument = gql`
  query getOrganisation($id: String!) {
    organisation(id: $id) {
      id
      businessNumber
      name
      description
      address
      contactName
      contactEmail
      contactPhone
      unitSystem
    }
  }
`;

/**
 * __useGetOrganisationQuery__
 *
 * To run a query within a React component, call `useGetOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganisationQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrganisationQuery, GetOrganisationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, options);
}
export function useGetOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationQuery, GetOrganisationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(GetOrganisationDocument, options);
}
export type GetOrganisationQueryHookResult = ReturnType<typeof useGetOrganisationQuery>;
export type GetOrganisationLazyQueryHookResult = ReturnType<typeof useGetOrganisationLazyQuery>;
export type GetOrganisationQueryResult = Apollo.QueryResult<GetOrganisationQuery, GetOrganisationQueryVariables>;
export const OrganisationSearchDocument = gql`
  query organisationSearch($first: Int, $where: OrganisationFilterInput, $order: [OrganisationSortInput!]) {
    organisations(first: $first, where: $where, order: $order) {
      nodes {
        id
        name
        unitSystem
      }
    }
  }
`;

/**
 * __useOrganisationSearchQuery__
 *
 * To run a query within a React component, call `useOrganisationSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationSearchQuery({
 *   variables: {
 *      first: // value for 'first'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useOrganisationSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganisationSearchQuery, OrganisationSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganisationSearchQuery, OrganisationSearchQueryVariables>(
    OrganisationSearchDocument,
    options,
  );
}
export function useOrganisationSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganisationSearchQuery, OrganisationSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganisationSearchQuery, OrganisationSearchQueryVariables>(
    OrganisationSearchDocument,
    options,
  );
}
export type OrganisationSearchQueryHookResult = ReturnType<typeof useOrganisationSearchQuery>;
export type OrganisationSearchLazyQueryHookResult = ReturnType<typeof useOrganisationSearchLazyQuery>;
export type OrganisationSearchQueryResult = Apollo.QueryResult<
  OrganisationSearchQuery,
  OrganisationSearchQueryVariables
>;
export const CreateRecipeDocument = gql`
  mutation createRecipe($input: CreateRecipeInput!) {
    createRecipe(input: $input) {
      id
      organisation {
        id
      }
      name
      description
      concentrations {
        chemical {
          id
          name
          description
          type
        }
        amount {
          unit
          value
        }
      }
    }
  }
`;
export type CreateRecipeMutationFn = Apollo.MutationFunction<CreateRecipeMutation, CreateRecipeMutationVariables>;

/**
 * __useCreateRecipeMutation__
 *
 * To run a mutation, you first call `useCreateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipeMutation, { data, loading, error }] = useCreateRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRecipeMutation, CreateRecipeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRecipeMutation, CreateRecipeMutationVariables>(CreateRecipeDocument, options);
}
export type CreateRecipeMutationHookResult = ReturnType<typeof useCreateRecipeMutation>;
export type CreateRecipeMutationResult = Apollo.MutationResult<CreateRecipeMutation>;
export type CreateRecipeMutationOptions = Apollo.BaseMutationOptions<
  CreateRecipeMutation,
  CreateRecipeMutationVariables
>;
export const DeleteRecipeDocument = gql`
  mutation deleteRecipe($id: String!) {
    deleteRecipe(id: $id) {
      id
      name
      deleteInformation {
        deletedAt
      }
    }
  }
`;
export type DeleteRecipeMutationFn = Apollo.MutationFunction<DeleteRecipeMutation, DeleteRecipeMutationVariables>;

/**
 * __useDeleteRecipeMutation__
 *
 * To run a mutation, you first call `useDeleteRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecipeMutation, { data, loading, error }] = useDeleteRecipeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRecipeMutation, DeleteRecipeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRecipeMutation, DeleteRecipeMutationVariables>(DeleteRecipeDocument, options);
}
export type DeleteRecipeMutationHookResult = ReturnType<typeof useDeleteRecipeMutation>;
export type DeleteRecipeMutationResult = Apollo.MutationResult<DeleteRecipeMutation>;
export type DeleteRecipeMutationOptions = Apollo.BaseMutationOptions<
  DeleteRecipeMutation,
  DeleteRecipeMutationVariables
>;
export const UpdateRecipeDocument = gql`
  mutation updateRecipe($input: UpdateRecipeInput!) {
    updateRecipe(input: $input) {
      id
      organisation {
        id
      }
      name
      description
      concentrations {
        chemical {
          id
          name
          description
          type
        }
        amount {
          unit
          value
        }
      }
    }
  }
`;
export type UpdateRecipeMutationFn = Apollo.MutationFunction<UpdateRecipeMutation, UpdateRecipeMutationVariables>;

/**
 * __useUpdateRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeMutation, { data, loading, error }] = useUpdateRecipeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRecipeMutation, UpdateRecipeMutationVariables>(UpdateRecipeDocument, options);
}
export type UpdateRecipeMutationHookResult = ReturnType<typeof useUpdateRecipeMutation>;
export type UpdateRecipeMutationResult = Apollo.MutationResult<UpdateRecipeMutation>;
export type UpdateRecipeMutationOptions = Apollo.BaseMutationOptions<
  UpdateRecipeMutation,
  UpdateRecipeMutationVariables
>;
export const GetRecipeDocument = gql`
  query getRecipe($id: String!, $first: Int, $after: String) {
    recipe(id: $id) {
      id
      organisation {
        id
      }
      name
      description
      concentrations {
        chemical {
          id
          name
          type
        }
        amount {
          unit
          value
        }
      }
      jobs(first: $first, after: $after) {
        edges {
          node {
            id
            name
            description
            startTime
          }
        }
        pageInfo {
          startCursor
          endCursor
        }
        totalCount
      }
      deleteInformation {
        deletedAt
      }
    }
  }
`;

/**
 * __useGetRecipeQuery__
 *
 * To run a query within a React component, call `useGetRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetRecipeQuery(baseOptions: Apollo.QueryHookOptions<GetRecipeQuery, GetRecipeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecipeQuery, GetRecipeQueryVariables>(GetRecipeDocument, options);
}
export function useGetRecipeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecipeQuery, GetRecipeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecipeQuery, GetRecipeQueryVariables>(GetRecipeDocument, options);
}
export type GetRecipeQueryHookResult = ReturnType<typeof useGetRecipeQuery>;
export type GetRecipeLazyQueryHookResult = ReturnType<typeof useGetRecipeLazyQuery>;
export type GetRecipeQueryResult = Apollo.QueryResult<GetRecipeQuery, GetRecipeQueryVariables>;
export const GetRecipeFromJobDocument = gql`
  query getRecipeFromJob($id: String!) {
    job(id: $id) {
      recipe {
        id
        organisation {
          id
        }
        name
        description
        concentrations {
          chemical {
            id
            name
            type
          }
          amount {
            unit
            value
          }
        }
      }
      completedAt
    }
  }
`;

/**
 * __useGetRecipeFromJobQuery__
 *
 * To run a query within a React component, call `useGetRecipeFromJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeFromJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeFromJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecipeFromJobQuery(
  baseOptions: Apollo.QueryHookOptions<GetRecipeFromJobQuery, GetRecipeFromJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecipeFromJobQuery, GetRecipeFromJobQueryVariables>(GetRecipeFromJobDocument, options);
}
export function useGetRecipeFromJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecipeFromJobQuery, GetRecipeFromJobQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecipeFromJobQuery, GetRecipeFromJobQueryVariables>(GetRecipeFromJobDocument, options);
}
export type GetRecipeFromJobQueryHookResult = ReturnType<typeof useGetRecipeFromJobQuery>;
export type GetRecipeFromJobLazyQueryHookResult = ReturnType<typeof useGetRecipeFromJobLazyQuery>;
export type GetRecipeFromJobQueryResult = Apollo.QueryResult<GetRecipeFromJobQuery, GetRecipeFromJobQueryVariables>;
export const GetRecipeUsageDocument = gql`
  query getRecipeUsage($input: GetRecipeUsageInput!) {
    recipeUsage(input: $input) {
      usage {
        key
        value {
          unit
          value
        }
      }
    }
  }
`;

/**
 * __useGetRecipeUsageQuery__
 *
 * To run a query within a React component, call `useGetRecipeUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeUsageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRecipeUsageQuery(
  baseOptions: Apollo.QueryHookOptions<GetRecipeUsageQuery, GetRecipeUsageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecipeUsageQuery, GetRecipeUsageQueryVariables>(GetRecipeUsageDocument, options);
}
export function useGetRecipeUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecipeUsageQuery, GetRecipeUsageQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecipeUsageQuery, GetRecipeUsageQueryVariables>(GetRecipeUsageDocument, options);
}
export type GetRecipeUsageQueryHookResult = ReturnType<typeof useGetRecipeUsageQuery>;
export type GetRecipeUsageLazyQueryHookResult = ReturnType<typeof useGetRecipeUsageLazyQuery>;
export type GetRecipeUsageQueryResult = Apollo.QueryResult<GetRecipeUsageQuery, GetRecipeUsageQueryVariables>;
export const GetRecipeUsageSummaryDocument = gql`
  query getRecipeUsageSummary($input: RecipeUsageSummaryInput!) {
    recipeUsageSummary(input: $input) {
      recipe {
        id
        name
        concentrations {
          chemical {
            id
            name
            type
          }
          amount {
            unit
            value
          }
        }
      }
      volume {
        value
        unit
      }
    }
  }
`;

/**
 * __useGetRecipeUsageSummaryQuery__
 *
 * To run a query within a React component, call `useGetRecipeUsageSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeUsageSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeUsageSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRecipeUsageSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<GetRecipeUsageSummaryQuery, GetRecipeUsageSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecipeUsageSummaryQuery, GetRecipeUsageSummaryQueryVariables>(
    GetRecipeUsageSummaryDocument,
    options,
  );
}
export function useGetRecipeUsageSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecipeUsageSummaryQuery, GetRecipeUsageSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecipeUsageSummaryQuery, GetRecipeUsageSummaryQueryVariables>(
    GetRecipeUsageSummaryDocument,
    options,
  );
}
export type GetRecipeUsageSummaryQueryHookResult = ReturnType<typeof useGetRecipeUsageSummaryQuery>;
export type GetRecipeUsageSummaryLazyQueryHookResult = ReturnType<typeof useGetRecipeUsageSummaryLazyQuery>;
export type GetRecipeUsageSummaryQueryResult = Apollo.QueryResult<
  GetRecipeUsageSummaryQuery,
  GetRecipeUsageSummaryQueryVariables
>;
export const GetRecipesByOrganisationIdDocument = gql`
  query getRecipesByOrganisationId(
    $id: String!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: RecipeFilterInput
    $order: [RecipeSortInput!]
  ) {
    recipesByOrganisationId(
      id: $id
      first: $first
      after: $after
      last: $last
      before: $before
      where: $where
      order: $order
    ) {
      edges {
        node {
          id
          organisation {
            id
          }
          name
          description
          concentrations {
            chemical {
              id
              name
              type
            }
            amount {
              unit
              value
            }
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetRecipesByOrganisationIdQuery__
 *
 * To run a query within a React component, call `useGetRecipesByOrganisationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipesByOrganisationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipesByOrganisationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetRecipesByOrganisationIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetRecipesByOrganisationIdQuery, GetRecipesByOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecipesByOrganisationIdQuery, GetRecipesByOrganisationIdQueryVariables>(
    GetRecipesByOrganisationIdDocument,
    options,
  );
}
export function useGetRecipesByOrganisationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecipesByOrganisationIdQuery, GetRecipesByOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecipesByOrganisationIdQuery, GetRecipesByOrganisationIdQueryVariables>(
    GetRecipesByOrganisationIdDocument,
    options,
  );
}
export type GetRecipesByOrganisationIdQueryHookResult = ReturnType<typeof useGetRecipesByOrganisationIdQuery>;
export type GetRecipesByOrganisationIdLazyQueryHookResult = ReturnType<typeof useGetRecipesByOrganisationIdLazyQuery>;
export type GetRecipesByOrganisationIdQueryResult = Apollo.QueryResult<
  GetRecipesByOrganisationIdQuery,
  GetRecipesByOrganisationIdQueryVariables
>;
export const RecipeSearchDocument = gql`
  query recipeSearch($id: String!, $first: Int, $where: RecipeFilterInput) {
    recipesByOrganisationId(id: $id, first: $first, where: $where) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useRecipeSearchQuery__
 *
 * To run a query within a React component, call `useRecipeSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeSearchQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRecipeSearchQuery(
  baseOptions: Apollo.QueryHookOptions<RecipeSearchQuery, RecipeSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecipeSearchQuery, RecipeSearchQueryVariables>(RecipeSearchDocument, options);
}
export function useRecipeSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecipeSearchQuery, RecipeSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecipeSearchQuery, RecipeSearchQueryVariables>(RecipeSearchDocument, options);
}
export type RecipeSearchQueryHookResult = ReturnType<typeof useRecipeSearchQuery>;
export type RecipeSearchLazyQueryHookResult = ReturnType<typeof useRecipeSearchLazyQuery>;
export type RecipeSearchQueryResult = Apollo.QueryResult<RecipeSearchQuery, RecipeSearchQueryVariables>;
export const GetUnassignedSprayLogsDocument = gql`
  query getUnassignedSprayLogs($input: UnassignedSprayLogInput!) {
    unassignedSprayLogs(input: $input) {
      device {
        id
        alias
      }
      startTime
      endTime
      volumeDispensed {
        value
        unit
      }
    }
  }
`;

/**
 * __useGetUnassignedSprayLogsQuery__
 *
 * To run a query within a React component, call `useGetUnassignedSprayLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnassignedSprayLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnassignedSprayLogsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUnassignedSprayLogsQuery(
  baseOptions: Apollo.QueryHookOptions<GetUnassignedSprayLogsQuery, GetUnassignedSprayLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnassignedSprayLogsQuery, GetUnassignedSprayLogsQueryVariables>(
    GetUnassignedSprayLogsDocument,
    options,
  );
}
export function useGetUnassignedSprayLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUnassignedSprayLogsQuery, GetUnassignedSprayLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnassignedSprayLogsQuery, GetUnassignedSprayLogsQueryVariables>(
    GetUnassignedSprayLogsDocument,
    options,
  );
}
export type GetUnassignedSprayLogsQueryHookResult = ReturnType<typeof useGetUnassignedSprayLogsQuery>;
export type GetUnassignedSprayLogsLazyQueryHookResult = ReturnType<typeof useGetUnassignedSprayLogsLazyQuery>;
export type GetUnassignedSprayLogsQueryResult = Apollo.QueryResult<
  GetUnassignedSprayLogsQuery,
  GetUnassignedSprayLogsQueryVariables
>;
export const AdminGetBusinessOverviewDocument = gql`
  query adminGetBusinessOverview {
    organisationCount
    userCount
    deviceCount
  }
`;

/**
 * __useAdminGetBusinessOverviewQuery__
 *
 * To run a query within a React component, call `useAdminGetBusinessOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetBusinessOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetBusinessOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetBusinessOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<AdminGetBusinessOverviewQuery, AdminGetBusinessOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetBusinessOverviewQuery, AdminGetBusinessOverviewQueryVariables>(
    AdminGetBusinessOverviewDocument,
    options,
  );
}
export function useAdminGetBusinessOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetBusinessOverviewQuery, AdminGetBusinessOverviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetBusinessOverviewQuery, AdminGetBusinessOverviewQueryVariables>(
    AdminGetBusinessOverviewDocument,
    options,
  );
}
export type AdminGetBusinessOverviewQueryHookResult = ReturnType<typeof useAdminGetBusinessOverviewQuery>;
export type AdminGetBusinessOverviewLazyQueryHookResult = ReturnType<typeof useAdminGetBusinessOverviewLazyQuery>;
export type AdminGetBusinessOverviewQueryResult = Apollo.QueryResult<
  AdminGetBusinessOverviewQuery,
  AdminGetBusinessOverviewQueryVariables
>;
export const AdminCreateUserDocument = gql`
  mutation adminCreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      organisation {
        id
      }
      email
      firstName
      lastName
      role
      enabled
    }
  }
`;
export type AdminCreateUserMutationFn = Apollo.MutationFunction<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;

/**
 * __useAdminCreateUserMutation__
 *
 * To run a mutation, you first call `useAdminCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateUserMutation, { data, loading, error }] = useAdminCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminCreateUserMutation, AdminCreateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminCreateUserMutation, AdminCreateUserMutationVariables>(
    AdminCreateUserDocument,
    options,
  );
}
export type AdminCreateUserMutationHookResult = ReturnType<typeof useAdminCreateUserMutation>;
export type AdminCreateUserMutationResult = Apollo.MutationResult<AdminCreateUserMutation>;
export type AdminCreateUserMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;
export const AdminDisableUserDocument = gql`
  mutation adminDisableUser($input: ToggleUserInput!) {
    disableUser(input: $input) {
      id
      enabled
      firstName
      lastName
    }
  }
`;
export type AdminDisableUserMutationFn = Apollo.MutationFunction<
  AdminDisableUserMutation,
  AdminDisableUserMutationVariables
>;

/**
 * __useAdminDisableUserMutation__
 *
 * To run a mutation, you first call `useAdminDisableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDisableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDisableUserMutation, { data, loading, error }] = useAdminDisableUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDisableUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminDisableUserMutation, AdminDisableUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminDisableUserMutation, AdminDisableUserMutationVariables>(
    AdminDisableUserDocument,
    options,
  );
}
export type AdminDisableUserMutationHookResult = ReturnType<typeof useAdminDisableUserMutation>;
export type AdminDisableUserMutationResult = Apollo.MutationResult<AdminDisableUserMutation>;
export type AdminDisableUserMutationOptions = Apollo.BaseMutationOptions<
  AdminDisableUserMutation,
  AdminDisableUserMutationVariables
>;
export const AdminEnableUserDocument = gql`
  mutation adminEnableUser($input: ToggleUserInput!) {
    enableUser(input: $input) {
      id
      enabled
      firstName
      lastName
    }
  }
`;
export type AdminEnableUserMutationFn = Apollo.MutationFunction<
  AdminEnableUserMutation,
  AdminEnableUserMutationVariables
>;

/**
 * __useAdminEnableUserMutation__
 *
 * To run a mutation, you first call `useAdminEnableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminEnableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminEnableUserMutation, { data, loading, error }] = useAdminEnableUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminEnableUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminEnableUserMutation, AdminEnableUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminEnableUserMutation, AdminEnableUserMutationVariables>(
    AdminEnableUserDocument,
    options,
  );
}
export type AdminEnableUserMutationHookResult = ReturnType<typeof useAdminEnableUserMutation>;
export type AdminEnableUserMutationResult = Apollo.MutationResult<AdminEnableUserMutation>;
export type AdminEnableUserMutationOptions = Apollo.BaseMutationOptions<
  AdminEnableUserMutation,
  AdminEnableUserMutationVariables
>;
export const AdminResendInviteDocument = gql`
  mutation adminResendInvite($input: ResendInviteInput!) {
    resendInvite(input: $input) {
      id
      firstName
      lastName
      email
      role
      enabled
      organisation {
        id
        name
      }
      userStatus
      registrationDate
    }
  }
`;
export type AdminResendInviteMutationFn = Apollo.MutationFunction<
  AdminResendInviteMutation,
  AdminResendInviteMutationVariables
>;

/**
 * __useAdminResendInviteMutation__
 *
 * To run a mutation, you first call `useAdminResendInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminResendInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminResendInviteMutation, { data, loading, error }] = useAdminResendInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminResendInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminResendInviteMutation, AdminResendInviteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminResendInviteMutation, AdminResendInviteMutationVariables>(
    AdminResendInviteDocument,
    options,
  );
}
export type AdminResendInviteMutationHookResult = ReturnType<typeof useAdminResendInviteMutation>;
export type AdminResendInviteMutationResult = Apollo.MutationResult<AdminResendInviteMutation>;
export type AdminResendInviteMutationOptions = Apollo.BaseMutationOptions<
  AdminResendInviteMutation,
  AdminResendInviteMutationVariables
>;
export const AdminUpdateUserDocument = gql`
  mutation adminUpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      organisation {
        id
      }
      email
      firstName
      lastName
      role
      enabled
    }
  }
`;
export type AdminUpdateUserMutationFn = Apollo.MutationFunction<
  AdminUpdateUserMutation,
  AdminUpdateUserMutationVariables
>;

/**
 * __useAdminUpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUserMutation, { data, loading, error }] = useAdminUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>(
    AdminUpdateUserDocument,
    options,
  );
}
export type AdminUpdateUserMutationHookResult = ReturnType<typeof useAdminUpdateUserMutation>;
export type AdminUpdateUserMutationResult = Apollo.MutationResult<AdminUpdateUserMutation>;
export type AdminUpdateUserMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateUserMutation,
  AdminUpdateUserMutationVariables
>;
export const AdminGetUserDocument = gql`
  query adminGetUser($id: String!, $first: Int, $after: String) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      role
      enabled
      organisation {
        id
        name
      }
      userStatus
      registrationDate
      jobs(first: $first, after: $after) {
        edges {
          node {
            id
            name
            startTime
            endTime
            deviceAssignments(where: { userId: { eq: $id } }) {
              device {
                id
                alias
              }
              user {
                id
              }
            }
            recipe {
              id
              name
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
`;

/**
 * __useAdminGetUserQuery__
 *
 * To run a query within a React component, call `useAdminGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAdminGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetUserQuery, AdminGetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetUserQuery, AdminGetUserQueryVariables>(AdminGetUserDocument, options);
}
export function useAdminGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetUserQuery, AdminGetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetUserQuery, AdminGetUserQueryVariables>(AdminGetUserDocument, options);
}
export type AdminGetUserQueryHookResult = ReturnType<typeof useAdminGetUserQuery>;
export type AdminGetUserLazyQueryHookResult = ReturnType<typeof useAdminGetUserLazyQuery>;
export type AdminGetUserQueryResult = Apollo.QueryResult<AdminGetUserQuery, AdminGetUserQueryVariables>;
export const AdminGetUsersDocument = gql`
  query adminGetUsers($id: String!, $first: Int, $after: String, $where: UserFilterInput, $order: [UserSortInput!]) {
    usersByOrganisationId(id: $id, first: $first, after: $after, where: $where, order: $order) {
      edges {
        node {
          id
          email
          firstName
          lastName
          role
          organisation {
            id
          }
          enabled
          userStatus
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useAdminGetUsersQuery__
 *
 * To run a query within a React component, call `useAdminGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAdminGetUsersQuery(
  baseOptions: Apollo.QueryHookOptions<AdminGetUsersQuery, AdminGetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetUsersQuery, AdminGetUsersQueryVariables>(AdminGetUsersDocument, options);
}
export function useAdminGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdminGetUsersQuery, AdminGetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetUsersQuery, AdminGetUsersQueryVariables>(AdminGetUsersDocument, options);
}
export type AdminGetUsersQueryHookResult = ReturnType<typeof useAdminGetUsersQuery>;
export type AdminGetUsersLazyQueryHookResult = ReturnType<typeof useAdminGetUsersLazyQuery>;
export type AdminGetUsersQueryResult = Apollo.QueryResult<AdminGetUsersQuery, AdminGetUsersQueryVariables>;
export const GetTopSprayersDocument = gql`
  query getTopSprayers($input: TopSprayersInput!) {
    topSprayers(input: $input) {
      edges {
        node {
          user {
            id
            fullName
            role
          }
          volume {
            value
            unit
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetTopSprayersQuery__
 *
 * To run a query within a React component, call `useGetTopSprayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopSprayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopSprayersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTopSprayersQuery(
  baseOptions: Apollo.QueryHookOptions<GetTopSprayersQuery, GetTopSprayersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTopSprayersQuery, GetTopSprayersQueryVariables>(GetTopSprayersDocument, options);
}
export function useGetTopSprayersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTopSprayersQuery, GetTopSprayersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTopSprayersQuery, GetTopSprayersQueryVariables>(GetTopSprayersDocument, options);
}
export type GetTopSprayersQueryHookResult = ReturnType<typeof useGetTopSprayersQuery>;
export type GetTopSprayersLazyQueryHookResult = ReturnType<typeof useGetTopSprayersLazyQuery>;
export type GetTopSprayersQueryResult = Apollo.QueryResult<GetTopSprayersQuery, GetTopSprayersQueryVariables>;
export const GetUserDocument = gql`
  query getUser($id: String!, $first: Int, $after: String) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      role
      jobs(first: $first, after: $after) {
        edges {
          node {
            id
            name
            startTime
            endTime
            deviceAssignments(where: { userId: { eq: $id } }) {
              device {
                id
                alias
              }
              user {
                id
              }
            }
            recipe {
              id
              name
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const SuperAdminGetAllUsersDocument = gql`
  query superAdminGetAllUsers($first: Int, $after: String, $where: UserFilterInput, $order: [UserSortInput!]) {
    users(first: $first, after: $after, where: $where, order: $order) {
      edges {
        node {
          id
          fullName
          email
          role
          enabled
          userStatus
          organisation {
            name
            id
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useSuperAdminGetAllUsersQuery__
 *
 * To run a query within a React component, call `useSuperAdminGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuperAdminGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuperAdminGetAllUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSuperAdminGetAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<SuperAdminGetAllUsersQuery, SuperAdminGetAllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuperAdminGetAllUsersQuery, SuperAdminGetAllUsersQueryVariables>(
    SuperAdminGetAllUsersDocument,
    options,
  );
}
export function useSuperAdminGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuperAdminGetAllUsersQuery, SuperAdminGetAllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuperAdminGetAllUsersQuery, SuperAdminGetAllUsersQueryVariables>(
    SuperAdminGetAllUsersDocument,
    options,
  );
}
export type SuperAdminGetAllUsersQueryHookResult = ReturnType<typeof useSuperAdminGetAllUsersQuery>;
export type SuperAdminGetAllUsersLazyQueryHookResult = ReturnType<typeof useSuperAdminGetAllUsersLazyQuery>;
export type SuperAdminGetAllUsersQueryResult = Apollo.QueryResult<
  SuperAdminGetAllUsersQuery,
  SuperAdminGetAllUsersQueryVariables
>;
export const UserSearchDocument = gql`
  query userSearch($id: String!, $first: Int, $where: UserFilterInput) {
    usersByOrganisationId(id: $id, first: $first, where: $where) {
      nodes {
        id
        fullName
      }
    }
  }
`;

/**
 * __useUserSearchQuery__
 *
 * To run a query within a React component, call `useUserSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSearchQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserSearchQuery(baseOptions: Apollo.QueryHookOptions<UserSearchQuery, UserSearchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSearchQuery, UserSearchQueryVariables>(UserSearchDocument, options);
}
export function useUserSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserSearchQuery, UserSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSearchQuery, UserSearchQueryVariables>(UserSearchDocument, options);
}
export type UserSearchQueryHookResult = ReturnType<typeof useUserSearchQuery>;
export type UserSearchLazyQueryHookResult = ReturnType<typeof useUserSearchLazyQuery>;
export type UserSearchQueryResult = Apollo.QueryResult<UserSearchQuery, UserSearchQueryVariables>;
export const CreateWebhookDocument = gql`
  mutation CreateWebhook($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      apiKey
      apiKeyHeader
      webhook {
        id
        name
        url
        events
        description
        status
      }
    }
  }
`;
export type CreateWebhookMutationFn = Apollo.MutationFunction<CreateWebhookMutation, CreateWebhookMutationVariables>;

/**
 * __useCreateWebhookMutation__
 *
 * To run a mutation, you first call `useCreateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookMutation, { data, loading, error }] = useCreateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWebhookMutation, CreateWebhookMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWebhookMutation, CreateWebhookMutationVariables>(CreateWebhookDocument, options);
}
export type CreateWebhookMutationHookResult = ReturnType<typeof useCreateWebhookMutation>;
export type CreateWebhookMutationResult = Apollo.MutationResult<CreateWebhookMutation>;
export type CreateWebhookMutationOptions = Apollo.BaseMutationOptions<
  CreateWebhookMutation,
  CreateWebhookMutationVariables
>;
export const DeleteWebhookDocument = gql`
  mutation deleteWebhook($input: DeleteWebhookInput!) {
    deleteWebhook(input: $input) {
      id
    }
  }
`;
export type DeleteWebhookMutationFn = Apollo.MutationFunction<DeleteWebhookMutation, DeleteWebhookMutationVariables>;

/**
 * __useDeleteWebhookMutation__
 *
 * To run a mutation, you first call `useDeleteWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebhookMutation, { data, loading, error }] = useDeleteWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteWebhookMutation, DeleteWebhookMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteWebhookMutation, DeleteWebhookMutationVariables>(DeleteWebhookDocument, options);
}
export type DeleteWebhookMutationHookResult = ReturnType<typeof useDeleteWebhookMutation>;
export type DeleteWebhookMutationResult = Apollo.MutationResult<DeleteWebhookMutation>;
export type DeleteWebhookMutationOptions = Apollo.BaseMutationOptions<
  DeleteWebhookMutation,
  DeleteWebhookMutationVariables
>;
export const UpdateWebhookDocument = gql`
  mutation UpdateWebhook($input: UpdateWebhookInput!) {
    updateWebhook(input: $input) {
      id
      name
      url
      events
      description
      status
    }
  }
`;
export type UpdateWebhookMutationFn = Apollo.MutationFunction<UpdateWebhookMutation, UpdateWebhookMutationVariables>;

/**
 * __useUpdateWebhookMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookMutation, { data, loading, error }] = useUpdateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWebhookMutation, UpdateWebhookMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWebhookMutation, UpdateWebhookMutationVariables>(UpdateWebhookDocument, options);
}
export type UpdateWebhookMutationHookResult = ReturnType<typeof useUpdateWebhookMutation>;
export type UpdateWebhookMutationResult = Apollo.MutationResult<UpdateWebhookMutation>;
export type UpdateWebhookMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebhookMutation,
  UpdateWebhookMutationVariables
>;
export const WebhookByIdDocument = gql`
  query webhookById($id: String!) {
    webhookById(id: $id) {
      id
      name
      description
      events
      url
      status
      infrastructureStatus {
        status
        detail
      }
    }
  }
`;

/**
 * __useWebhookByIdQuery__
 *
 * To run a query within a React component, call `useWebhookByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebhookByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebhookByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWebhookByIdQuery(baseOptions: Apollo.QueryHookOptions<WebhookByIdQuery, WebhookByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WebhookByIdQuery, WebhookByIdQueryVariables>(WebhookByIdDocument, options);
}
export function useWebhookByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WebhookByIdQuery, WebhookByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WebhookByIdQuery, WebhookByIdQueryVariables>(WebhookByIdDocument, options);
}
export type WebhookByIdQueryHookResult = ReturnType<typeof useWebhookByIdQuery>;
export type WebhookByIdLazyQueryHookResult = ReturnType<typeof useWebhookByIdLazyQuery>;
export type WebhookByIdQueryResult = Apollo.QueryResult<WebhookByIdQuery, WebhookByIdQueryVariables>;
export const WebhooksByOrganisationIdDocument = gql`
  query webhooksByOrganisationId(
    $organisationId: String!
    $first: Int
    $after: String
    $where: WebhookFilterInput
    $order: [WebhookSortInput!]
  ) {
    webhooksByOrganisationId(
      organisationId: $organisationId
      first: $first
      after: $after
      where: $where
      order: $order
    ) {
      edges {
        node {
          id
          name
          description
          url
          status
          infrastructureStatus {
            status
            detail
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useWebhooksByOrganisationIdQuery__
 *
 * To run a query within a React component, call `useWebhooksByOrganisationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebhooksByOrganisationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebhooksByOrganisationIdQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useWebhooksByOrganisationIdQuery(
  baseOptions: Apollo.QueryHookOptions<WebhooksByOrganisationIdQuery, WebhooksByOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WebhooksByOrganisationIdQuery, WebhooksByOrganisationIdQueryVariables>(
    WebhooksByOrganisationIdDocument,
    options,
  );
}
export function useWebhooksByOrganisationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WebhooksByOrganisationIdQuery, WebhooksByOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WebhooksByOrganisationIdQuery, WebhooksByOrganisationIdQueryVariables>(
    WebhooksByOrganisationIdDocument,
    options,
  );
}
export type WebhooksByOrganisationIdQueryHookResult = ReturnType<typeof useWebhooksByOrganisationIdQuery>;
export type WebhooksByOrganisationIdLazyQueryHookResult = ReturnType<typeof useWebhooksByOrganisationIdLazyQuery>;
export type WebhooksByOrganisationIdQueryResult = Apollo.QueryResult<
  WebhooksByOrganisationIdQuery,
  WebhooksByOrganisationIdQueryVariables
>;
export const CreateWeedDocument = gql`
  mutation createWeed($input: CreateWeedInput!) {
    createWeed(input: $input) {
      id
      organisation {
        id
      }
      name
      species
      description
      imageUrl
    }
  }
`;
export type CreateWeedMutationFn = Apollo.MutationFunction<CreateWeedMutation, CreateWeedMutationVariables>;

/**
 * __useCreateWeedMutation__
 *
 * To run a mutation, you first call `useCreateWeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWeedMutation, { data, loading, error }] = useCreateWeedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWeedMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWeedMutation, CreateWeedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateWeedMutation, CreateWeedMutationVariables>(CreateWeedDocument, options);
}
export type CreateWeedMutationHookResult = ReturnType<typeof useCreateWeedMutation>;
export type CreateWeedMutationResult = Apollo.MutationResult<CreateWeedMutation>;
export type CreateWeedMutationOptions = Apollo.BaseMutationOptions<CreateWeedMutation, CreateWeedMutationVariables>;
export const UpdateWeedDocument = gql`
  mutation updateWeed($input: UpdateWeedInput!) {
    updateWeed(input: $input) {
      id
      organisation {
        id
      }
      name
      species
      description
      imageUrl
    }
  }
`;
export type UpdateWeedMutationFn = Apollo.MutationFunction<UpdateWeedMutation, UpdateWeedMutationVariables>;

/**
 * __useUpdateWeedMutation__
 *
 * To run a mutation, you first call `useUpdateWeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWeedMutation, { data, loading, error }] = useUpdateWeedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWeedMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateWeedMutation, UpdateWeedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateWeedMutation, UpdateWeedMutationVariables>(UpdateWeedDocument, options);
}
export type UpdateWeedMutationHookResult = ReturnType<typeof useUpdateWeedMutation>;
export type UpdateWeedMutationResult = Apollo.MutationResult<UpdateWeedMutation>;
export type UpdateWeedMutationOptions = Apollo.BaseMutationOptions<UpdateWeedMutation, UpdateWeedMutationVariables>;
export const GetWeedDocument = gql`
  query getWeed($id: String!, $first: Int, $after: String) {
    weed(id: $id) {
      id
      organisation {
        id
      }
      name
      species
      description
      imageUrl
      jobs(first: $first, after: $after) {
        edges {
          node {
            id
            name
            description
            startTime
          }
        }
        pageInfo {
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
`;

/**
 * __useGetWeedQuery__
 *
 * To run a query within a React component, call `useGetWeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetWeedQuery(baseOptions: Apollo.QueryHookOptions<GetWeedQuery, GetWeedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWeedQuery, GetWeedQueryVariables>(GetWeedDocument, options);
}
export function useGetWeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeedQuery, GetWeedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWeedQuery, GetWeedQueryVariables>(GetWeedDocument, options);
}
export type GetWeedQueryHookResult = ReturnType<typeof useGetWeedQuery>;
export type GetWeedLazyQueryHookResult = ReturnType<typeof useGetWeedLazyQuery>;
export type GetWeedQueryResult = Apollo.QueryResult<GetWeedQuery, GetWeedQueryVariables>;
export const WeedSearchDocument = gql`
  query weedSearch($id: String!, $first: Int, $where: WeedFilterInput) {
    weedsByOrganisationId(id: $id, first: $first, where: $where) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useWeedSearchQuery__
 *
 * To run a query within a React component, call `useWeedSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeedSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeedSearchQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWeedSearchQuery(baseOptions: Apollo.QueryHookOptions<WeedSearchQuery, WeedSearchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WeedSearchQuery, WeedSearchQueryVariables>(WeedSearchDocument, options);
}
export function useWeedSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WeedSearchQuery, WeedSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WeedSearchQuery, WeedSearchQueryVariables>(WeedSearchDocument, options);
}
export type WeedSearchQueryHookResult = ReturnType<typeof useWeedSearchQuery>;
export type WeedSearchLazyQueryHookResult = ReturnType<typeof useWeedSearchLazyQuery>;
export type WeedSearchQueryResult = Apollo.QueryResult<WeedSearchQuery, WeedSearchQueryVariables>;
export const WeedByCoordinatesDocument = gql`
  query weedByCoordinates($input: WeedByCoordinatesInput!) {
    weedByCoordinates(input: $input) {
      id
      name
      recipe {
        id
        name
      }
      deviceAssignments {
        device {
          id
          alias
        }
        user {
          id
          fullName
        }
        sprayLogs {
          c {
            x
            y
          }
        }
      }
    }
  }
`;

/**
 * __useWeedByCoordinatesQuery__
 *
 * To run a query within a React component, call `useWeedByCoordinatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeedByCoordinatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeedByCoordinatesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWeedByCoordinatesQuery(
  baseOptions: Apollo.QueryHookOptions<WeedByCoordinatesQuery, WeedByCoordinatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WeedByCoordinatesQuery, WeedByCoordinatesQueryVariables>(WeedByCoordinatesDocument, options);
}
export function useWeedByCoordinatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WeedByCoordinatesQuery, WeedByCoordinatesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WeedByCoordinatesQuery, WeedByCoordinatesQueryVariables>(
    WeedByCoordinatesDocument,
    options,
  );
}
export type WeedByCoordinatesQueryHookResult = ReturnType<typeof useWeedByCoordinatesQuery>;
export type WeedByCoordinatesLazyQueryHookResult = ReturnType<typeof useWeedByCoordinatesLazyQuery>;
export type WeedByCoordinatesQueryResult = Apollo.QueryResult<WeedByCoordinatesQuery, WeedByCoordinatesQueryVariables>;
export const GetWeedsByOrganisationIdDocument = gql`
  query getWeedsByOrganisationId(
    $id: String!
    $first: Int
    $after: String
    $where: WeedFilterInput
    $order: [WeedSortInput!]
  ) {
    weedsByOrganisationId(id: $id, first: $first, after: $after, where: $where, order: $order) {
      edges {
        node {
          id
          organisation {
            id
          }
          name
          species
          description
          imageUrl
        }
      }
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

/**
 * __useGetWeedsByOrganisationIdQuery__
 *
 * To run a query within a React component, call `useGetWeedsByOrganisationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeedsByOrganisationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeedsByOrganisationIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetWeedsByOrganisationIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetWeedsByOrganisationIdQuery, GetWeedsByOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWeedsByOrganisationIdQuery, GetWeedsByOrganisationIdQueryVariables>(
    GetWeedsByOrganisationIdDocument,
    options,
  );
}
export function useGetWeedsByOrganisationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWeedsByOrganisationIdQuery, GetWeedsByOrganisationIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWeedsByOrganisationIdQuery, GetWeedsByOrganisationIdQueryVariables>(
    GetWeedsByOrganisationIdDocument,
    options,
  );
}
export type GetWeedsByOrganisationIdQueryHookResult = ReturnType<typeof useGetWeedsByOrganisationIdQuery>;
export type GetWeedsByOrganisationIdLazyQueryHookResult = ReturnType<typeof useGetWeedsByOrganisationIdLazyQuery>;
export type GetWeedsByOrganisationIdQueryResult = Apollo.QueryResult<
  GetWeedsByOrganisationIdQuery,
  GetWeedsByOrganisationIdQueryVariables
>;
