export const FAQContent = [
  {
    title: 'How can I invite a new member to my organisation?',
    children: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim erat, tincidunt sed mattis in,
              condimentum a lectus. Sed at nisi elit. Nunc dictum arcu vulputate leo aliquam tincidunt. Aenean posuere
              metus blandit pulvinar sagittis. Nullam hendrerit risus non mi semper venenatis. Morbi finibus, velit non
              luctus condimentum, tortor dui pharetra tellus, ut finibus sapien diam sit amet diam. Cras vehicula neque
              in turpis pretium, nec tempus erat fermentum.`,
  },
  {
    title: 'How do I activate a new device?',
    children: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim erat, tincidunt sed mattis in,
              condimentum a lectus. Sed at nisi elit. Nunc dictum arcu vulputate leo aliquam tincidunt. Aenean posuere
              metus blandit pulvinar sagittis. Nullam hendrerit risus non mi semper venenatis. Morbi finibus, velit non
              luctus condimentum, tortor dui pharetra tellus, ut finibus sapien diam sit amet diam. Cras vehicula neque
              in turpis pretium, nec tempus erat fermentum.`,
  },
  {
    title: 'How do I get access to more UFT devices for my organisation?',
    children: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim erat, tincidunt sed mattis in,
              condimentum a lectus. Sed at nisi elit. Nunc dictum arcu vulputate leo aliquam tincidunt. Aenean posuere
              metus blandit pulvinar sagittis. Nullam hendrerit risus non mi semper venenatis. Morbi finibus, velit non
              luctus condimentum, tortor dui pharetra tellus, ut finibus sapien diam sit amet diam. Cras vehicula neque
              in turpis pretium, nec tempus erat fermentum.`,
  },
  {
    title: 'The data from one of my devices is not being uploaded, what do I do?',
    children: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim erat, tincidunt sed mattis in,
              condimentum a lectus. Sed at nisi elit. Nunc dictum arcu vulputate leo aliquam tincidunt. Aenean posuere
              metus blandit pulvinar sagittis. Nullam hendrerit risus non mi semper venenatis. Morbi finibus, velit non
              luctus condimentum, tortor dui pharetra tellus, ut finibus sapien diam sit amet diam. Cras vehicula neque
              in turpis pretium, nec tempus erat fermentum.`,
  },
  {
    title: 'Where can I access the operator manual online?',
    children: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim erat, tincidunt sed mattis in,
              condimentum a lectus. Sed at nisi elit. Nunc dictum arcu vulputate leo aliquam tincidunt. Aenean posuere
              metus blandit pulvinar sagittis. Nullam hendrerit risus non mi semper venenatis. Morbi finibus, velit non
              luctus condimentum, tortor dui pharetra tellus, ut finibus sapien diam sit amet diam. Cras vehicula neque
              in turpis pretium, nec tempus erat fermentum.`,
  },
];
