import { toast } from 'react-toastify';
import {
  ActivateDeviceInput,
  Device,
  DevicesByOrganisationIdConnection,
  DevicesEdge,
  GetDevicesByOrganisationIdDocument,
  GetDevicesByOrganisationIdQuery,
  GetDevicesByOrganisationIdQueryVariables,
  useActivateDeviceMutation,
} from '../../../graphql/generated';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import { deviceActivationValidator } from '../validation/DeviceActivationValidator';
import DeviceActivationFormContent from './DeviceActivationFormContent';
import { updatePagedQuery } from '../../../shared/utilities/ApolloCacheUtilities';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isOrganisational: boolean;
};

export default function DeviceActivationFormDialog({ isOrganisational, isOpen, onClose }: Props) {
  const [activateDevice] = useActivateDeviceMutation();

  const currentOrganisation = useOrganisationId();

  return (
    <DeviceActivationFormContent<ActivateDeviceInput>
      initialValues={
        deviceActivationValidator.cast({
          organisationId: currentOrganisation,
        }) as ActivateDeviceInput
      }
      isOrganisational={isOrganisational}
      validator={deviceActivationValidator}
      onClose={onClose}
      isOpen={isOpen}
      onSubmit={async (value) => {
        await activateDevice({
          variables: {
            input: value,
          },
          onCompleted(data) {
            toast(`A device with id - ${data.activateDevice.id} has been successfully activated!`, {
              type: 'success',
            });
          },
          update: (_, { data }) =>
            updatePagedQuery<
              Device,
              GetDevicesByOrganisationIdQuery,
              GetDevicesByOrganisationIdQueryVariables,
              DevicesEdge
            >(
              data?.activateDevice as Device,
              GetDevicesByOrganisationIdDocument,
              (query) => (query?.devicesByOrganisationId?.edges as DevicesEdge[]) ?? [],
              'devicesByOrganisationId',
              {
                id: currentOrganisation,
              },
            ),
        });
      }}
    />
  );
}
