import GridTile from '../../shared/layout/tiles/GridTile';
import RSGrid from '../../shared/layout/tiles/RSGrid';
import RSDisclosure from '../../shared/components/rs-disclosure/RSDisclosure';
import { FAQContent } from './FAQContent';
import { SupportContent } from './SupportContent';

export default function SupportPage() {
  const faq = FAQContent.map((disclosure) => (
    <div key={disclosure.title} className="outline outline-1 outline-gray-300 rounded-lg mb-2">
      <RSDisclosure title={disclosure.title}>{disclosure.children}</RSDisclosure>
    </div>
  ));

  const support = SupportContent.map((item) => (
    <div key={item.title} className="outline outline-1 outline-gray-300 rounded-lg mb-2">
      <RSDisclosure title={item.title} icon={item.icon}>
        {item.children}
      </RSDisclosure>
    </div>
  ));

  return (
    <>
      <RSGrid>
        <GridTile colSpan={6} title={'Frequently Asked Questions'}>
          <div className="text-xl p-5">
            You can find answers to common questions about the product below. If you don&apos;t find the answers you are
            looking for, you can{' '}
            <a href="mailto:info@rapidspray.net" className="text-blue-500">
              <u>contact the Rapid Logix support team</u>
            </a>{' '}
            via email.
          </div>
          <div className="p-5">{faq}</div>
        </GridTile>
        <GridTile title="Contact Support" colSpan={6}>
          <div className="text-xl p-5">
            If you have any issues that aren&apos;t covered in our questions and answers you can contact our team
            directly, select the category that best fits your issue below to ensure you can get in contact with the
            right people.
          </div>
          <div className="p-5">{support}</div>
        </GridTile>
      </RSGrid>
    </>
  );
}
