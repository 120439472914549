import convert from 'convert-units';
import * as Yup from 'yup';
import { ValidationError } from 'yup';
import { AnyObject } from 'yup/lib/types';
import { UnitMeasurement, UnitType } from '../../graphql/generated';
import { GetConvertUnit, GetUnitSymbol } from '../utilities/UnitConversionUtilities';

export type UnitValidationFunction = (
  measurement: UnitMeasurement,
  baseUnit: UnitType,
  createError: (params?: Yup.CreateErrorOptions) => Yup.ValidationError,
  path: string,
  context: Yup.TestContext<AnyObject>,
) => boolean | ValidationError | Promise<boolean | ValidationError>;

const defaultTolerance = 0.0000001;

export const ValidateUnit = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return Yup.object<UnitMeasurement>({
    unit: Yup.mixed<UnitType>().oneOf(Object.values(UnitType)).required('Unit is required'),
    value: Yup.number().required('Value is required').typeError('Value must be a number'),
  }).typeError('Value is required');
};

export const UnitTest = (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  schema: Yup.ObjectSchema<UnitMeasurement>,
  targetUnit: UnitType,
  validate: UnitValidationFunction,
) => {
  return schema.test('unit', function (value, context) {
    const { createError } = this;
    if (!value) {
      return createError({ path: `${context.path}.value`, message: 'Value is required' });
    }
    const { unit, value: unitValue } = value;
    if (!unit) {
      return false;
    }

    if (!unitValue) {
      return false;
    }

    const convertedValue = convert(unitValue).from(GetConvertUnit(unit)).to(GetConvertUnit(targetUnit));

    return validate({ unit: targetUnit, value: convertedValue }, unit, createError, context.path, context);
  });
};

export const UnitInclusiveBetween = (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  schema: Yup.ObjectSchema<UnitMeasurement>,
  targetUnit: UnitType,
  min: number,
  max: number,
  tolerance: number = defaultTolerance,
) => {
  return UnitTest(schema, targetUnit, (measurement, baseUnit, createError, path, context) => {
    min = min - tolerance;
    max = max + tolerance;
    if (measurement.value < min || measurement.value > max) {
      const minConverted = convert(min).from(GetConvertUnit(targetUnit)).to(GetConvertUnit(baseUnit));
      const maxConverted = convert(max).from(GetConvertUnit(targetUnit)).to(GetConvertUnit(baseUnit));
      const fieldName = context.path;
      return createError({
        path: path,
        message: `${fieldName} must be between ${minConverted.toFixed(4)} and ${maxConverted.toFixed(
          4,
        )} ${GetUnitSymbol(baseUnit)}`,
      });
    }
    return true;
  });
};

export const UnitGreaterThan = (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  schema: Yup.ObjectSchema<UnitMeasurement>,
  targetUnit: UnitType,
  min: number,
  tolerance: number = defaultTolerance,
) => {
  return UnitTest(schema, targetUnit, (measurement, baseUnit, createError, path, context) => {
    min = min - tolerance;
    if (measurement.value < min) {
      const minConverted = convert(min).from(GetConvertUnit(targetUnit)).to(GetConvertUnit(baseUnit)) - tolerance;
      const fieldName = context.path;

      return createError({
        path: path,
        message: `${fieldName} must be greater than ${minConverted.toFixed(4)} ${GetUnitSymbol(baseUnit)}`,
      });
    }
    return true;
  });
};
