import { FormikProps } from 'formik';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import {
  CreateWebhookInput,
  CreateWebhookResponse,
  useCreateWebhookMutation,
  Webhook,
  WebhooksByOrganisationIdDocument,
  WebhooksByOrganisationIdEdge,
  WebhooksByOrganisationIdQuery,
  WebhooksByOrganisationIdQueryVariables,
} from '../../../graphql/generated';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import useBlocker from '../../../shared/hooks/UseBlocker';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import { updatePagedQuery } from '../../../shared/utilities/ApolloCacheUtilities';
import { getErrorType, ServerErrorCode } from '../../../shared/utilities/ApolloErrorUtils';
import { CreateWebhookDialogProps } from './CreateWebhookDialog';
import WebhookFormContent, { WebhookFormType } from './WebhookFormContent';

type CreateWebhookFormDialogProps = CreateWebhookDialogProps & {
  onComplete: (response?: CreateWebhookResponse) => void;
};

export function CreateWebhookFormDialog({ isOpen, onComplete, onClose }: CreateWebhookFormDialogProps) {
  const [createWebhook] = useCreateWebhookMutation();
  const formikRef = useRef<FormikProps<WebhookFormType>>(null);
  const organisationId = useOrganisationId();
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);

  return (
    <RSDialog
      title={`Create Webhook`}
      isOpen={isOpen}
      actions={
        <>
          <FormConfirmCloseButton
            isBlocking={isBlocking}
            formik={formikRef.current}
            setIsBlocking={setIsBlocking}
            onClose={onClose}
          />
          <RSButton onClick={formikRef.current?.submitForm}>{'Add'}</RSButton>
        </>
      }
    >
      <WebhookFormContent
        onChange={() => {
          setIsBlocking(true);
        }}
        formikRef={formikRef}
        onSubmit={async (values, helpers) => {
          const input: CreateWebhookInput = {
            name: values.name,
            description: values.description,
            url: values.url,
            events: values.events.filter((e) => e.checked).map((e) => e.event),
            organisationId: organisationId,
            status: values.status,
          };

          try {
            const result = await createWebhook({
              variables: {
                input: input,
              },
            });

            toast(`A new webhook named ${input.name} was created with url ${input.url} was created!`, {
              type: 'success',
            });

            const webhookResponse = result.data?.createWebhook as CreateWebhookResponse;

            updatePagedQuery<
              Webhook,
              WebhooksByOrganisationIdQuery,
              WebhooksByOrganisationIdQueryVariables,
              WebhooksByOrganisationIdEdge
            >(
              webhookResponse.webhook,
              WebhooksByOrganisationIdDocument,
              (query) => (query?.webhooksByOrganisationId?.edges as WebhooksByOrganisationIdEdge[]) ?? [],
              'webhooksByOrganisationId',
              {
                organisationId: organisationId,
              },
            );

            onComplete(webhookResponse);
          } catch (error) {
            if (getErrorType(error) === ServerErrorCode.BAD_REQUEST) {
              toast('Your organisation has reached the maximum number of organisations', {
                type: 'error',
              });
            } else {
              toast(
                'An error occurred whilst creating the webhook. If the error persists after 5 minutes, please contact support.',
                {
                  type: 'error',
                },
              );
            }

            onComplete();
          }
        }}
      />
    </RSDialog>
  );
}
