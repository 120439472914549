import { toast } from 'react-toastify';
import {
  AdminGetAllFirmwareDocument,
  AdminGetAllFirmwareQuery,
  AdminGetAllFirmwareQueryVariables,
  CreateFirmwareOutput,
  FirmwareEdge,
  useCreateFirmwareMutation,
} from '../../../graphql/generated';
import { updatePagedQuery } from '../../../shared/utilities/ApolloCacheUtilities';
import { firmwareValidator } from '../pages/FirmwareValidator';
import CreateFirmwareFormContent from './CreateFirmwareFormContent';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function CreateFirmwareFormDialog({ isOpen, onClose }: Props) {
  const [createFirmware] = useCreateFirmwareMutation();

  return (
    <CreateFirmwareFormContent
      validator={firmwareValidator}
      onClose={onClose}
      isOpen={isOpen}
      onSubmit={async (value) => {
        return (
          await createFirmware({
            variables: {
              input: {
                description: value.description,
                version: value.version,
              },
            },
            onCompleted(data) {
              toast(`A new firmware with version - ${data.createFirmware.version} has been succesfully created.`, {
                type: 'success',
              });
            },
            update: (_, { data }) =>
              updatePagedQuery<
                CreateFirmwareOutput,
                AdminGetAllFirmwareQuery,
                AdminGetAllFirmwareQueryVariables,
                FirmwareEdge
              >(
                data?.createFirmware as CreateFirmwareOutput,
                AdminGetAllFirmwareDocument,
                (query) => (query?.firmware?.edges as FirmwareEdge[]) ?? [],
                'firmware',
              ),
          })
        ).data?.createFirmware as CreateFirmwareOutput;
      }}
    />
  );
}
