import { userInviteValidator, userUpdateValidator } from '../validation/UserValidators';
import { toast } from 'react-toastify';
import {
  AdminGetUsersDocument,
  AdminGetUsersQuery,
  AdminGetUsersQueryVariables,
  CreateUserInput,
  UpdateUserInput,
  useAdminCreateUserMutation,
  useAdminUpdateUserMutation,
  User,
  UsersByOrganisationIdEdge,
} from '../../../graphql/generated';
import UserFormContent from './UserFormContent';
import useCurrentUserStore from '../../../shared/hooks/stores/UseCurrentUserStore';
import { globalRoles } from '../utilities/UserRoleUtilities';
import { updatePagedQuery } from '../../../shared/utilities/ApolloCacheUtilities';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';

type Props = {
  initialValues?: User;
  isOpen: boolean;
  onClose: () => void;
  isOrganisational: boolean;
};

export default function UserFormDialog({ initialValues, isOpen, onClose, isOrganisational }: Props) {
  const [createUser] = useAdminCreateUserMutation();
  const [updateUser] = useAdminUpdateUserMutation();
  const currentOrganisation = useOrganisationId();
  const isSuperAdmin = useCurrentUserStore((state) => state.isSuperAdmin());
  const currentUserId = useCurrentUserStore((state) => state.currentUser?.username) as string;

  if (initialValues) {
    return (
      <UserFormContent<UpdateUserInput>
        isCurrentUser={currentUserId === initialValues.id}
        isSuperAdmin={isSuperAdmin}
        isOrganisational={isOrganisational}
        isEdit
        // Map the provided user details to the mutation's input
        initialValues={
          userUpdateValidator.cast(
            { ...initialValues, organisationId: initialValues.organisation?.id },
            { stripUnknown: true },
          ) as UpdateUserInput
        }
        validator={userUpdateValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          const result = await updateUser({
            variables: {
              input: value,
            },
            onCompleted(data) {
              toast(`${data.updateUser.firstName} ${data.updateUser.lastName} has been successfully updated!`, {
                type: 'success',
              });
            },
          });

          return !(result.errors && result.errors?.length >= 1);
        }}
      />
    );
  } else {
    return (
      <UserFormContent<CreateUserInput>
        isCurrentUser={false}
        isSuperAdmin={isSuperAdmin}
        isOrganisational={isOrganisational}
        initialValues={
          userInviteValidator.cast({
            organisationId: currentOrganisation,
          }) as CreateUserInput
        }
        validator={isSuperAdmin ? userInviteValidator : userInviteValidator.omit(['organisationId'])}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          const result = await createUser({
            variables: {
              input: { ...value, organisationId: globalRoles.includes(value.role) ? undefined : value.organisationId },
            },
            onCompleted(data) {
              toast(`${data.createUser.firstName} ${data.createUser.lastName} has been successfully created!`, {
                type: 'success',
              });
            },
            update: (_, { data }) =>
              updatePagedQuery<User, AdminGetUsersQuery, AdminGetUsersQueryVariables, UsersByOrganisationIdEdge>(
                data?.createUser as User,
                AdminGetUsersDocument,
                (query) => (query?.usersByOrganisationId?.edges as UsersByOrganisationIdEdge[]) ?? [],
                'usersByOrganisationId',
                {
                  id: currentOrganisation ?? '',
                },
              ),
          });

          return !(result.errors && result.errors?.length >= 1);
        }}
      />
    );
  }
}
