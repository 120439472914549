import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBriefcase,
  faFlaskVial,
  faGauge,
  faMicrochip,
  faPieChart,
  faScrewdriverWrench,
  faSeedling,
  faSitemap,
  faUserGroup,
  faUsers,
  faCircleQuestion,
  faMapLocationDot,
  faVial,
  faCircleArrowDown,
  faCircleArrowRight,
  faCode,
  faPlug,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import BusinessOverviewPage from '../../features/business-overview/pages/BusinessOverviewPage';
import ManufacturingDevicePage from '../../features/devices/pages/ManufacturingDevicePage';
import ManufacturingManagementPage from '../../features/devices/pages/ManufacturingManagementPage';
import OrganisationManagementPage from '../../features/organisations/pages/OrganisationManagementPage';
import OrganisationPage from '../../features/organisations/pages/OrganisationPage';
import UserAdministrationPage from '../../features/users/pages/UserAdministrationPage';
import UserManagementPage from '../../features/users/pages/UserManagementPage';
import UserPage from '../../features/users/pages/UserPage';
import { UserRole } from '../../graphql/generated';
import { localRoutingConstants } from '../constants/LocalRoutingConstants';
import SupportPage from '../../features/support/SupportPage';
import DeviceManagementPage from '../../features/devices/pages/DeviceManagementPage';
import DashboardPage from '../../features/dashboard/pages/DashboardPage';
import JobsPage from '../../features/jobs/pages/JobsPage';
import MyJobsPage from '../../features/jobs/pages/MyJobsPage';
import RecipesPage from '../../features/recipes/pages/RecipesPage';
import WeedsPage from '../../features/weeds/pages/WeedsPage';
import WeedsMap from '../../features/locations/pages/WeedsMap';
import DevicesPage from '../../features/devices/pages/DevicesPage';
import ChemicalPage from '../../features/chemicals/pages/ChemicalPage';
import WeedPage from '../../features/weeds/pages/WeedPage';
import RecipePage from '../../features/recipes/pages/RecipePage';
import JobPage from '../../features/jobs/pages/JobPage';
import DevicePage from '../../features/devices/pages/DevicePage';
import ChemicalsPage from '../../features/chemicals/pages/ChemicalsPage';
import JobOrganisationInvitationPage from '../../features/jobs/pages/JobOrganisationShareInvitationPage';
import SharedJobsPage from '../../features/jobs/pages/SharedJobsPage';
import FirmwaresPage from '../../features/firmware/pages/FirmwaresPage';
import FirmwarePage from '../../features/firmware/pages/FirmwarePage';
import { WebhooksPage } from '../../features/webhooks/pages/WebhooksPage';
import WebhookPage from '../../features/webhooks/pages/WebhookPage';

interface AuthenticatedRoute {
  title: string;
  path: string;
  icon: IconProp;
  element: ReactElement;
}

const businessOverview: AuthenticatedRoute = {
  title: 'Business Overview',
  icon: faPieChart,
  path: localRoutingConstants.business.root,
  element: <BusinessOverviewPage />,
};

const organisations: AuthenticatedRoute = {
  title: 'Organisations',
  icon: faSitemap,
  path: localRoutingConstants.organisations.root,
  element: <OrganisationManagementPage />,
};

const manageUsers: AuthenticatedRoute = {
  title: 'Manage Users',
  icon: faUserGroup,
  path: localRoutingConstants.manageUsers.root,
  element: <UserAdministrationPage />,
};

const manageViewUsers: AuthenticatedRoute = {
  title: 'View User',
  icon: faUserGroup,
  path: localRoutingConstants.manageUsers.viewUser,
  element: <UserPage />,
};

const manageDevices: AuthenticatedRoute = {
  title: 'Manage Devices',
  icon: faMicrochip,
  path: localRoutingConstants.manageDevices.root,
  element: <DeviceManagementPage />,
};

const manageViewDevice: AuthenticatedRoute = {
  title: 'View Device',
  icon: faMicrochip,
  path: localRoutingConstants.manageDevices.viewDevice,
  element: <ManufacturingDevicePage />,
};

const manufacturing: AuthenticatedRoute = {
  title: 'Manufacturing',
  icon: faScrewdriverWrench,
  path: localRoutingConstants.manufacturing.root,
  element: <ManufacturingManagementPage />,
};

const dashboard: AuthenticatedRoute = {
  title: 'Dashboard',
  icon: faGauge,
  path: '/dashboard',
  element: <DashboardPage />,
};

const jobs: AuthenticatedRoute = {
  title: 'Jobs',
  icon: faBriefcase,
  path: localRoutingConstants.jobs.root,
  element: <JobsPage />,
};

const myJobs: AuthenticatedRoute = {
  title: 'My Jobs',
  icon: faBriefcase,
  path: localRoutingConstants.myJobs.root,
  element: <MyJobsPage />,
};

const sharedJobs: AuthenticatedRoute = {
  title: 'Jobs shared with me',
  icon: faCircleArrowRight,
  path: localRoutingConstants.sharedJobs.root,
  element: <SharedJobsPage />,
};

const devices: AuthenticatedRoute = {
  title: 'Devices',
  icon: faMicrochip,
  path: localRoutingConstants.devices.root,
  element: <DevicesPage />,
};

const viewDevice: AuthenticatedRoute = {
  title: 'View Device',
  icon: faMicrochip,
  path: localRoutingConstants.devices.viewDevice,
  element: <DevicePage />,
};

const firmwares: AuthenticatedRoute = {
  title: 'Firmware',
  icon: faCode,
  path: localRoutingConstants.firmware.root,
  element: <FirmwaresPage />,
};

const viewFirmware: AuthenticatedRoute = {
  title: 'View Firmware',
  icon: faCode,
  path: localRoutingConstants.firmware.viewFirmware,
  element: <FirmwarePage />,
};

const recipes: AuthenticatedRoute = {
  title: 'Recipes',
  icon: faFlaskVial,
  path: localRoutingConstants.recipes.root,
  element: <RecipesPage />,
};

const chemicals: AuthenticatedRoute = {
  title: 'Chemicals',
  icon: faVial,
  path: localRoutingConstants.chemicals.root,
  element: <ChemicalsPage />,
};

const weeds: AuthenticatedRoute = {
  title: 'Weeds',
  icon: faSeedling,
  path: localRoutingConstants.weeds.root,
  element: <WeedsPage />,
};

const users: AuthenticatedRoute = {
  title: 'Users',
  icon: faUsers,
  path: localRoutingConstants.users.root,
  element: <UserManagementPage />,
};

const locations: AuthenticatedRoute = {
  title: 'Weed Map',
  icon: faMapLocationDot,
  path: localRoutingConstants.weedMap,
  element: <WeedsMap />,
};

const viewOrganisation: AuthenticatedRoute = {
  title: 'View Organisation',
  icon: faSitemap,
  path: localRoutingConstants.organisations.viewOrganisation,
  element: <OrganisationPage />,
};

const viewUser: AuthenticatedRoute = {
  title: 'View User',
  icon: faUsers,
  path: localRoutingConstants.users.viewUser,
  element: <UserPage />,
};

const manufacturingViewDevice: AuthenticatedRoute = {
  title: 'Manufacturing',
  icon: faScrewdriverWrench,
  path: localRoutingConstants.manufacturing.viewDevice,
  element: <ManufacturingDevicePage />,
};

const viewChemical: AuthenticatedRoute = {
  title: 'View Chemical',
  icon: faScrewdriverWrench,
  path: localRoutingConstants.chemicals.viewChemical,
  element: <ChemicalPage />,
};

const viewWeed: AuthenticatedRoute = {
  title: 'View Weed',
  icon: faScrewdriverWrench,
  path: localRoutingConstants.weeds.viewWeed,
  element: <WeedPage />,
};

const viewRecipe: AuthenticatedRoute = {
  title: 'View Recipe',
  icon: faScrewdriverWrench,
  path: localRoutingConstants.recipes.viewRecipe,
  element: <RecipePage />,
};

const viewJob: AuthenticatedRoute = {
  title: 'View Job',
  icon: faScrewdriverWrench,
  path: localRoutingConstants.jobs.viewJob,
  element: <JobPage />,
};

const viewMyJob: AuthenticatedRoute = {
  title: 'View My Job',
  icon: faScrewdriverWrench,
  path: localRoutingConstants.myJobs.viewUserJob,
  element: <JobPage />,
};

const viewSharedJob: AuthenticatedRoute = {
  title: 'View Shared Job',
  icon: faScrewdriverWrench,
  path: localRoutingConstants.sharedJobs.viewSharedJob,
  element: <JobPage />,
};

const support: AuthenticatedRoute = {
  title: 'Support',
  icon: faCircleQuestion,
  path: '/support',
  element: <SupportPage />,
};

const invitationPage: AuthenticatedRoute = {
  title: 'View Invitation',
  icon: faCircleArrowDown,
  path: '/invitation',
  element: <JobOrganisationInvitationPage />,
};

const webhooks: AuthenticatedRoute = {
  title: 'Webhooks',
  icon: faPlug,
  path: localRoutingConstants.webhooks.root,
  element: <WebhooksPage />,
};

const viewWebhook: AuthenticatedRoute = {
  title: 'View Webhook',
  icon: faPlug,
  path: localRoutingConstants.webhooks.viewWebhook,
  element: <WebhookPage />,
};

export const authenticatedRoutes: Array<AuthenticatedRoute> = [
  businessOverview,
  organisations,
  viewOrganisation,
  users,
  viewUser,
  viewChemical,
  viewWeed,
  viewRecipe,
  manufacturingViewDevice,
  manageViewDevice,
  viewSharedJob,
  sharedJobs,
  jobs,
  manageViewUsers,
  viewJob,
  viewMyJob,
  myJobs,
  devices,
  viewFirmware,
  firmwares,
  webhooks,
  viewWebhook,
];

interface AuthenticatedRouteOptions {
  onSidebar: Array<AuthenticatedRoute>;
  otherRoutes: Array<AuthenticatedRoute>;
}

export interface SuperAdminRoutedOptions extends AuthenticatedRouteOptions {
  organisationRoutes: Array<AuthenticatedRoute>;
}

export const routes = new Map<UserRole, AuthenticatedRouteOptions>([
  [
    UserRole.SuperAdmin,
    {
      onSidebar: [businessOverview, organisations, manufacturing, manageUsers, manageDevices, firmwares],
      otherRoutes: [
        viewOrganisation,
        viewUser,
        viewChemical,
        viewWeed,
        viewRecipe,
        viewJob,
        manageViewDevice,
        manageViewUsers,
        support,
        viewDevice,
        viewFirmware,
        viewWebhook,
      ],
      organisationRoutes: [dashboard, jobs, devices, recipes, chemicals, weeds, users, locations, webhooks],
    } as SuperAdminRoutedOptions,
  ],
  [
    UserRole.Manufacturer,
    {
      onSidebar: [manufacturing, firmwares],
      otherRoutes: [manufacturingViewDevice, viewFirmware],
    },
  ],
  [
    UserRole.OrganisationAdmin,
    {
      onSidebar: [dashboard, jobs, devices, recipes, chemicals, weeds, users, locations, webhooks],
      otherRoutes: [
        viewUser,
        viewChemical,
        viewWeed,
        viewRecipe,
        viewJob,
        viewDevice,
        invitationPage,
        viewSharedJob,
        sharedJobs,
        viewFirmware,
      ],
    },
  ],
  [
    UserRole.SkilledOperator,
    {
      onSidebar: [jobs, devices, recipes, chemicals, weeds, locations],
      otherRoutes: [viewChemical, viewWeed, viewRecipe, viewJob, viewDevice, viewFirmware],
    },
  ],
  [
    UserRole.Operator,
    {
      onSidebar: [myJobs, devices, recipes, chemicals, weeds],
      otherRoutes: [viewChemical, viewWeed, viewRecipe, viewMyJob, viewDevice, viewFirmware],
    },
  ],
]);
