import * as Yup from 'yup';
import { ConcentrationInput, UnitSystem, UnitType } from '../../../graphql/generated';
import {
  MaximumDescriptionLength,
  MaximumLongNameLength,
  MongoIdLength,
} from '../../../shared/constants/ValidationConstants';

const UnitSystemToDefaultConcentration: { [key in UnitSystem]: UnitType } = {
  [UnitSystem.Imperial]: UnitType.FluidOuncesPerUsGallon,
  [UnitSystem.Metric]: UnitType.MillilitresPerHundredLitres,
};

export function GetDefaultConcentration(system: UnitSystem): ConcentrationInput {
  return {
    chemicalId: '',
    amount: {
      value: 1,
      unit: UnitSystemToDefaultConcentration[system],
    },
  };
}

export const recipeValidator = Yup.object({
  organisationId: Yup.string().required().length(MongoIdLength),
  name: Yup.string().required('You must provide a name.').max(MaximumLongNameLength).default(''),
  description: Yup.string().required('You must provide a description.').max(MaximumDescriptionLength).default(''),
  concentrations: Yup.array(
    Yup.object({
      amount: Yup.object({
        unit: Yup.mixed<UnitType>().oneOf(Object.values(UnitType)).required('Unit is required'),
        value: Yup.number().required('Value is required').typeError('Value must be a number').positive(),
      }).typeError('Value is required'),
      chemicalId: Yup.string().required(),
    }),
  )
    .required()
    .min(1, 'You must supply at least one concentration.'),
});

export const recipeUpdateValidator = recipeValidator
  .shape({
    id: Yup.string().required().length(MongoIdLength),
  })
  .omit(['organisationId']);
