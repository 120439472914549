import { toast } from 'react-toastify';
import {
  AdminUpdateDeviceInput,
  CreateDeviceInput,
  Device,
  GetManufacturingDevicesDocument,
  GetManufacturingDevicesQuery,
  UpdateDeviceInput,
  UserRole,
  useAdminUpdateDeviceMutation,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
} from '../../../graphql/generated';
import useCurrentUserStore from '../../../shared/hooks/stores/UseCurrentUserStore';
import { updateQueryWithoutVariables } from '../../../shared/utilities/ApolloCacheUtilities';
import {
  adminDeviceUpdateValidator,
  deviceRegistrationValidator,
  deviceUpdateValidator,
  GetDefaultDevice,
} from '../../devices/validation/DeviceValidator';
import DeviceFormContent from './DeviceFormContent';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';

type Props = {
  initialValues?: Device;
  isOpen: boolean;
  onClose: () => void;
  orgLock: string | undefined;
};

export default function DeviceFormDialog({ initialValues, isOpen, onClose, orgLock }: Props) {
  const role = useCurrentUserStore((state) => state.getCurrentUserRole());
  const isSuperAdmin = (role?.includes(UserRole.Manufacturer) || role?.includes(UserRole.SuperAdmin)) ?? false;
  const { unitSystem } = useSelectedOrganisationIdStore();

  if (initialValues) {
    if (isSuperAdmin) {
      const [updateDevice] = useAdminUpdateDeviceMutation();
      return (
        <DeviceFormContent<AdminUpdateDeviceInput>
          isEdit
          isSuperAdmin={isSuperAdmin}
          orgLock={orgLock}
          // Map the provided user details to the mutation's input
          initialValues={{
            ...initialValues,
            firmwareId:
              initialValues.firmwareInformation?.candidate?.firmware.id ??
              initialValues.firmwareInformation?.slot1?.firmware.id,
            firmwareLockStatus: initialValues.firmwareInformation.lockStatus,
          }}
          validator={adminDeviceUpdateValidator}
          onClose={onClose}
          isOpen={isOpen}
          onSubmit={async (value) => {
            const result = await updateDevice({
              variables: {
                input: {
                  alias: value.alias,
                  calibrationVolume: {
                    value: value.calibrationVolume.value,
                    unit: value.calibrationVolume.unit,
                  },
                  calibrationTimeout: value.calibrationTimeout,
                  rinseVolume: {
                    value: value.rinseVolume.value,
                    unit: value.rinseVolume.unit,
                  },
                  rinseTimeout: value.rinseTimeout,
                  loggingPeriod: value.loggingPeriod,
                  sleepTimeout: value.sleepTimeout,
                  gpsAccuracy: {
                    value: value.gpsAccuracy.value,
                    unit: value.gpsAccuracy.unit,
                  },
                  id: value.id,
                  firmwareId: value.firmwareId,
                  firmwareLockStatus: value.firmwareLockStatus,
                },
              },
              onCompleted(data) {
                toast(`A device with name - ${data.adminUpdateDevice.alias} has been successfully updated!`, {
                  type: 'success',
                });
                onClose();
              },
            });
            return result;
          }}
        />
      );
    } else {
      const [updateDevice] = useUpdateDeviceMutation();
      return (
        <DeviceFormContent<UpdateDeviceInput>
          isEdit
          isSuperAdmin={isSuperAdmin}
          orgLock={orgLock}
          // Map the provided user details to the mutation's input
          initialValues={
            deviceUpdateValidator.cast(
              {
                ...initialValues,
              },
              { stripUnknown: true },
            ) as UpdateDeviceInput
          }
          validator={deviceUpdateValidator}
          onClose={onClose}
          isOpen={isOpen}
          onSubmit={async (value) => {
            const result = await updateDevice({
              variables: {
                input: {
                  alias: value.alias,
                  rinseVolume: {
                    value: value.rinseVolume.value,
                    unit: value.rinseVolume.unit,
                  },
                  rinseTimeout: value.rinseTimeout,
                  sleepTimeout: value.sleepTimeout,
                  gpsAccuracy: {
                    value: value.gpsAccuracy.value,
                    unit: value.gpsAccuracy.unit,
                  },
                  id: value.id,
                },
              },
              onCompleted(data) {
                toast(`A device with name - ${data.updateDevice.alias} has been successfully updated!`, {
                  type: 'success',
                });
                onClose();
              },
            });
            return result;
          }}
        />
      );
    }
  } else {
    const [createDevice] = useCreateDeviceMutation();
    return (
      <DeviceFormContent<CreateDeviceInput>
        isSuperAdmin={isSuperAdmin}
        orgLock={orgLock}
        // Map the provided user details to the mutation's input
        initialValues={GetDefaultDevice(unitSystem)}
        validator={deviceRegistrationValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          const result = await createDevice({
            variables: {
              input: value,
            },
            onCompleted(data) {
              toast(`A new device with name - ${data.createDevice.alias} has been successfully created!`, {
                type: 'success',
              });
            },
            update: (_, { data }) =>
              updateQueryWithoutVariables<Device, GetManufacturingDevicesQuery>(
                data?.createDevice as Device,
                GetManufacturingDevicesDocument,
                (query) => query?.manufacturingDevices?.edges?.map((e) => e.node) as Device[],
                'manufacturingDevices',
              ),
          });

          return result;
        }}
      />
    );
  }
}
