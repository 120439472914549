import { toast } from 'react-toastify';
import {
  AdminGetOrganisationsDocument,
  AdminGetOrganisationsQuery,
  AdminGetOrganisationsQueryVariables,
  CreateOrganisationInput,
  Organisation,
  OrganisationsEdge,
  UpdateOrganisationInput,
  useAdminCreateOrganisationMutation,
  useAdminUpdateOrganisationMutation,
} from '../../../graphql/generated';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';
import { updatePagedQuery } from '../../../shared/utilities/ApolloCacheUtilities';
import {
  organisationValidator as organisationCreateValidator,
  organisationUpdateValidator,
} from '../validation/OrganisationValidator';
import OrganisationFormContent from './OrganisationFormContent';

type Props = {
  initialValues?: Organisation;
  isOpen: boolean;
  onClose: () => void;
};

export default function OrganisationFormDialog({ initialValues, isOpen, onClose }: Props) {
  const [createOrganisation] = useAdminCreateOrganisationMutation();
  const [updateOrganisation] = useAdminUpdateOrganisationMutation();

  const { setSelectedOrganisation, selectedOrganisation } = useSelectedOrganisationIdStore();

  if (initialValues) {
    return (
      <OrganisationFormContent<UpdateOrganisationInput>
        initialValues={
          organisationUpdateValidator.cast(initialValues, { stripUnknown: true }) as UpdateOrganisationInput
        }
        validator={organisationUpdateValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          await updateOrganisation({
            variables: {
              input: value,
            },
            onCompleted(data) {
              toast(`A new organisation with name - ${data.updateOrganisation.name} has been successfully updated!`, {
                type: 'success',
              });

              if (data.updateOrganisation.id === selectedOrganisation?.id) {
                setSelectedOrganisation(data.updateOrganisation as Organisation);
              }
            },
          });
        }}
      />
    );
  } else {
    return (
      <OrganisationFormContent<CreateOrganisationInput>
        initialValues={initialValues}
        validator={organisationCreateValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          await createOrganisation({
            variables: {
              input: value,
            },
            onCompleted(data) {
              toast(`A new organisation with name - ${data.createOrganisation.name} has been successfully created!`, {
                type: 'success',
              });
            },
            update: (_, { data }) =>
              updatePagedQuery<
                Organisation,
                AdminGetOrganisationsQuery,
                AdminGetOrganisationsQueryVariables,
                OrganisationsEdge
              >(
                data?.createOrganisation as Organisation,
                AdminGetOrganisationsDocument,
                (query) => (query?.organisations?.edges as OrganisationsEdge[]) ?? [],
                'organisations',
              ),
          });
        }}
      />
    );
  }
}
