import { faFingerprint, faDownload, faKey, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'formik';
import FormTextInput from '../../../shared/components/forms/form-text-input/FormTextInput';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import RSNotification from '../../../shared/components/rs-notification/RSNotification';
import RSPopover from '../../../shared/components/rs-popover/RSPopover';
import { CSVLink } from 'react-csv';
import useBlocker from '../../../shared/hooks/UseBlocker';
import { useState } from 'react';

interface NewDeviceCredentials {
  activationToken?: string;
  authenticationToken?: string;
  imei?: string;
  alias?: string;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setModalSwitch: (value: React.SetStateAction<boolean>) => void;
  newDeviceCredentials: NewDeviceCredentials | undefined;
};

export default function DevicePostRegistrationContent({
  isOpen,
  onClose,
  setModalSwitch,
  newDeviceCredentials,
}: Props) {
  const [isBlocking, setIsBlocking] = useState<boolean>(true);
  useBlocker(() => 'Unsaved form data!', isBlocking && isOpen);
  const csvData = [
    ['Device IMEI', 'Device Alias', 'Activiation Token', 'Authentication Token'],
    [
      newDeviceCredentials?.imei,
      newDeviceCredentials?.alias,
      newDeviceCredentials?.activationToken,
      newDeviceCredentials?.authenticationToken,
    ],
  ];
  return (
    <RSDialog
      title={'Register Device'}
      isOpen={isOpen}
      onClose={() => {
        setIsBlocking(false);
      }}
      actions={
        <>
          <div>
            <RSPopover buttonLabel="Close" buttonType="secondary">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="bg-gray-50 w-max">
                  <RSNotification variant="danger" title="Are you sure you want to close this dialogue?">
                    This information cannot be retrieved again
                    <div className="pt-2 flex justify-end">
                      <RSButton
                        variant="danger"
                        onClick={() => {
                          onClose();
                          setIsBlocking(false);
                          setModalSwitch(false);
                        }}
                        className="text-lg"
                      >
                        <FontAwesomeIcon icon={faCircleXmark} className="pr-3" />I Am Sure
                      </RSButton>
                    </div>
                  </RSNotification>
                </div>
              </div>
            </RSPopover>
          </div>
        </>
      }
    >
      <RSNotification title="Device Successfully Registered" variant="primary">
        Please keep note of the device credentials below. This is the only time that device secret keys can be viewed or
        downloaded.
      </RSNotification>
      <Form>
        <FormTextInput
          label="IMEI"
          name="imei"
          icon={faFingerprint}
          placeholder="The device IMEI"
          disabled
          value={newDeviceCredentials?.imei}
        />
        <FormTextInput
          label="Activation Token"
          name="activationToken"
          icon={faDownload}
          placeholder="The Activation Token"
          disabled
          value={newDeviceCredentials?.activationToken}
        />
        <FormTextInput
          label="Authentication Token"
          name="authenticationToken"
          icon={faKey}
          placeholder="The Authentication Token"
          disabled
          value={newDeviceCredentials?.authenticationToken}
        />
      </Form>
      <RSButton
        variant="primary"
        style={{
          width: 'auto',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <FontAwesomeIcon icon={faDownload} />
        <span>
          <CSVLink filename={'export_' + newDeviceCredentials?.imei} data={csvData}>
            Export to CSV
          </CSVLink>
        </span>
      </RSButton>
    </RSDialog>
  );
}
