import { CreateWebhookResponse } from '../../../graphql/generated';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import RSNotification from '../../../shared/components/rs-notification/RSNotification';
import RSPopover from '../../../shared/components/rs-popover/RSPopover';
import useBlocker from '../../../shared/hooks/UseBlocker';
import { CreateWebhookDialogProps } from './CreateWebhookDialog';
import { PostCreateWebhookContent } from './PostWebhookCreationContent';
import { PostWebhookCreationCsvExportButton } from './PostWebhookCreationCsvExportButton';

type PostCreationDialogProps = CreateWebhookDialogProps & {
  onComplete: () => void;
  response: CreateWebhookResponse;
};

export function PostWebhookCreationDialog({ isOpen, onComplete, response }: PostCreationDialogProps) {
  const { setIsBlocking } = useBlocker(() => 'Credentials can only be viewed once. Are you sure?', isOpen);

  return (
    <RSDialog
      title={`Create Webhook`}
      isOpen={isOpen}
      actions={
        <>
          <PostWebhookCreationCsvExportButton response={response} />
          <RSPopover buttonLabel="Close" buttonType="secondary">
            <RSNotification variant="danger" title="Are you sure?">
              {`You will not be able to view these credentials again.`}
              <div className="pt-2 flex gap-x-2 justify-end">
                <RSButton variant="secondary">Cancel</RSButton>
                <RSButton
                  variant="danger"
                  onClick={() => {
                    setIsBlocking(false);
                    onComplete();
                  }}
                >
                  Confirm
                </RSButton>
              </div>
            </RSNotification>
          </RSPopover>
        </>
      }
    >
      <PostCreateWebhookContent response={response} />
    </RSDialog>
  );
}
