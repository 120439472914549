import { FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { UpdateWebhookInput, useUpdateWebhookMutation, Webhook, WebhookEventKind } from '../../../graphql/generated';
import FormConfirmCloseButton from '../../../shared/components/forms/form-confirm-close-button/FormConfirmCloseButton';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSDialog from '../../../shared/components/rs-dialog/RSDialog';
import useBlocker from '../../../shared/hooks/UseBlocker';
import { WebhookEventDefinitions } from '../WebhookEventUtils';
import WebhookFormContent, { WebhookEventKindCheckBox, WebhookFormType } from './WebhookFormContent';

type UpdateWebhookDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  webhook: Webhook;
};

export function UpdateWebhookDialog({ isOpen, onClose, webhook }: UpdateWebhookDialogProps) {
  const { isBlocking, setIsBlocking } = useBlocker(() => 'Unsaved form data!', isOpen);
  const formikRef = useRef<FormikProps<WebhookFormType>>(null);
  const [updateWebhook] = useUpdateWebhookMutation();
  const formInitialValues: WebhookFormType = {
    name: webhook.name,
    description: webhook.description,
    url: webhook.url,
    status: webhook.status,
    events: Object.keys(WebhookEventDefinitions).map((x) => {
      const event = x as WebhookEventKind;

      const eventCheck: WebhookEventKindCheckBox = {
        checked: webhook.events.includes(event),
        event: event,
      };

      return eventCheck;
    }),
  };

  return (
    <RSDialog
      title={`Update Webhook`}
      isOpen={isOpen}
      actions={
        <>
          <FormConfirmCloseButton
            isBlocking={isBlocking}
            formik={formikRef.current}
            setIsBlocking={setIsBlocking}
            onClose={onClose}
          />
          <RSButton onClick={formikRef.current?.submitForm}>{'Update'}</RSButton>
        </>
      }
    >
      <WebhookFormContent
        initialValues={formInitialValues}
        onChange={() => {
          setIsBlocking(true);
        }}
        formikRef={formikRef}
        onSubmit={async (values) => {
          const input: UpdateWebhookInput = {
            name: values.name,
            description: values.description,
            url: values.url,
            events: values.events.filter((e) => e.checked).map((e) => e.event),
            id: webhook.id,
            status: values.status,
          };

          try {
            await updateWebhook({
              variables: {
                input: input,
              },
            });

            toast(`${input.name} has been successfully updated!`, {
              type: 'success',
            });
          } catch (error) {
            toast(
              `An error occurred whilst updating webhook ${input.name}. If the error persists after 5 minutes, please contact support.`,
              {
                type: 'error',
              },
            );
          }

          onClose();
        }}
      />
    </RSDialog>
  );
}
