import { faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { menuPopOutAnimation } from '../../constants/HeadlessAnimationConstants';
import { Auth } from 'aws-amplify';
import { UserInfo } from '../../../features/auth/UserInfo';
import apolloClient from '../../../shared/utilities/ApolloClient';
import { useNavigate } from 'react-router-dom';
import useCurrentUserStore from '../../hooks/stores/UseCurrentUserStore';
import { localRoutingConstants } from '../../constants/LocalRoutingConstants';
import useSelectedOrganisationIdStore from '../../hooks/stores/UseSelectedOrganisationIdStore';

type Props = {};

interface ProfileMenuItemProps {
  text: string;
  onClick?: () => void;
}

export default function ProfileMenu({}: Props) {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const resetUser = useCurrentUserStore((state) => state.reset);
  const userOrganisation = useCurrentUserStore((state) => state.getCurrentUserOrganisationId());
  const { clearSelectedOrganisation } = useSelectedOrganisationIdStore();
  const navigate = useNavigate();
  useEffect(() => {
    async function getUserInfo() {
      setUserInfo((await Auth.currentUserInfo()) as UserInfo);
    }
    if (!userInfo) {
      getUserInfo();
    }
  });

  return (
    <Menu as="div" className="z-50 relative mx-4">
      <Menu.Button className="p-2 px-4 my-auto flex justify-center items-center rounded transition-all  hover:bg-gray-200 cursor-pointer">
        <div className="w-8 h-8 rounded-full bg-gray-300 flex justify-center items-center">
          <FontAwesomeIcon icon={faUser} className={`text-lg text-black'`} />
        </div>
        <div className="ml-3">
          <div className="font-bold text-left">{userInfo?.attributes.email}</div>
          <div className="-mt-2 text-sm hidden md:block text-left">{userInfo?.attributes['custom:userRole']}</div>
        </div>
        <div className="ml-3">
          <FontAwesomeIcon icon={faChevronDown} className={`text-sm text-black'`} />
        </div>
      </Menu.Button>
      <Transition as={Fragment} {...menuPopOutAnimation}>
        <Menu.Items className="absolute right-0 mt-2 left-0 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <ProfileMenuItem text="Profile" />
          {userOrganisation && (
            <ProfileMenuItem
              text="My Organisation"
              onClick={() => navigate(`${localRoutingConstants.organisations.root}/${userOrganisation}`)}
            />
          )}
          <ProfileMenuItem
            text="FAQ"
            onClick={() => {
              navigate('/support');
            }}
          />
          <ProfileMenuItem text="Log Out" onClick={signOut} />
        </Menu.Items>
      </Transition>
    </Menu>
  );
  async function signOut() {
    try {
      clearSelectedOrganisation();
      resetUser();
      navigate('/');
      apolloClient.resetStore();
      await Auth.signOut();
      window.location.reload();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log('Error occurred during sign out: ', error.toString());
    }
  }
}

function ProfileMenuItem({ text, onClick }: ProfileMenuItemProps) {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`${
            active ? 'bg-primary text-white' : 'text-gray-900'
          } z-50 group flex w-full items-center rounded-md px-2 py-2 text-sm`}
          onClick={onClick}
        >
          {text}
        </button>
      )}
    </Menu.Item>
  );
}
