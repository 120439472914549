import convert, { Unit } from 'convert-units';
import { UnitMeasurement, UnitSystem, UnitType } from '../../graphql/generated';

type UnitDefinition = {
  symbol: string;
  convertUnit?: Unit;
  unitSystems: UnitSystem[];
};

const UnitDefinitions: { [key in UnitType]: UnitDefinition } = {
  [UnitType.Celsius]: {
    symbol: '°C',
    convertUnit: 'C',
    unitSystems: [UnitSystem.Metric],
  },
  [UnitType.Fahrenheit]: {
    symbol: '°F',
    convertUnit: 'F',
    unitSystems: [UnitSystem.Imperial],
  },
  [UnitType.Feet]: {
    symbol: 'ft',
    convertUnit: 'ft',
    unitSystems: [UnitSystem.Imperial],
  },
  [UnitType.UsGallon]: {
    symbol: 'gal',
    convertUnit: 'gal',
    unitSystems: [UnitSystem.Imperial],
  },
  [UnitType.Gram]: {
    symbol: 'g',
    convertUnit: 'g',
    unitSystems: [UnitSystem.Metric],
  },
  [UnitType.Litre]: {
    symbol: 'L',
    convertUnit: 'l',
    unitSystems: [UnitSystem.Metric],
  },
  [UnitType.Metre]: {
    symbol: 'm',
    convertUnit: 'm',
    unitSystems: [UnitSystem.Metric],
  },
  [UnitType.Mile]: {
    symbol: 'mi',
    convertUnit: 'mi',
    unitSystems: [UnitSystem.Imperial],
  },
  [UnitType.MetresPerSecond]: {
    symbol: 'm/s',
    convertUnit: 'm/s',
    unitSystems: [UnitSystem.Metric],
  },
  [UnitType.MilesPerHour]: {
    symbol: 'mph',
    convertUnit: 'm/h',
    unitSystems: [UnitSystem.Imperial],
  },
  [UnitType.Millilitre]: {
    symbol: 'ml',
    convertUnit: 'ml',
    unitSystems: [UnitSystem.Metric],
  },
  [UnitType.Ounce]: {
    symbol: 'oz',
    convertUnit: 'oz',
    unitSystems: [UnitSystem.Imperial],
  },
  [UnitType.SquareFeet]: {
    symbol: 'ft²',
    convertUnit: 'ft2',
    unitSystems: [UnitSystem.Imperial],
  },
  [UnitType.SquareMetre]: {
    symbol: 'm²',
    convertUnit: 'm2',
    unitSystems: [UnitSystem.Metric],
  },
  [UnitType.Hectare]: {
    symbol: 'ha',
    convertUnit: 'ha',
    unitSystems: [UnitSystem.Metric],
  },
  [UnitType.Acre]: {
    symbol: 'ac',
    convertUnit: 'ac',
    unitSystems: [UnitSystem.Imperial],
  },
  [UnitType.FluidOuncesPerUsGallon]: {
    symbol: 'fl oz/gal',
    unitSystems: [UnitSystem.Imperial],
  },
  [UnitType.GramsPerHundredLitres]: {
    symbol: 'g/100L',
    unitSystems: [UnitSystem.Metric],
  },
  [UnitType.MillilitresPerHundredLitres]: {
    symbol: 'ml/100L',
    unitSystems: [UnitSystem.Metric],
  },
  [UnitType.OuncePerUsGallon]: {
    symbol: 'oz/gal',
    unitSystems: [UnitSystem.Imperial],
  },
  [UnitType.Kilometer]: {
    symbol: 'km',
    convertUnit: 'km',
    unitSystems: [UnitSystem.Metric],
  },
  [UnitType.FluidOunce]: {
    symbol: 'fl oz',
    convertUnit: 'fl-oz',
    unitSystems: [UnitSystem.Imperial],
  },
  [UnitType.Degree]: {
    symbol: '°',
    convertUnit: 'deg',
    unitSystems: [UnitSystem.Metric, UnitSystem.Imperial],
  },
  [UnitType.Percent]: {
    symbol: '%',
    unitSystems: [UnitSystem.Imperial, UnitSystem.Metric],
  },
};

export function GetLargeAreaDisplay(unit: UnitMeasurement | undefined, decimalPoints?: number) {
  return GetPreferredUnitDisplay(
    unit,
    [
      { system: UnitSystem.Metric, unit: UnitType.Hectare },
      { system: UnitSystem.Imperial, unit: UnitType.Acre },
    ],
    decimalPoints,
  );
}

export function IsUnitImperial(unit?: UnitType) {
  if (!unit) {
    return false;
  }

  return UnitDefinitions[unit].unitSystems.includes(UnitSystem.Imperial);
}

export function GetPreferredUnitDisplay(
  unit: UnitMeasurement | undefined,
  units: { system: UnitSystem; unit: UnitType }[],
  decimalPoints?: number,
): string {
  if (!unit) {
    return '';
  }

  const unitDefinition = UnitDefinitions[unit.unit];
  let value = unit.value;

  // Find preferred unit within the provided units
  const preferredUnit = units.find((u) => unitDefinition.unitSystems.includes(u.system));
  if (!preferredUnit) {
    return GetFullUnitDisplay(unit, decimalPoints);
  }

  const preferredUnitDefinition = UnitDefinitions[preferredUnit.unit];
  if (!(preferredUnitDefinition.convertUnit && unitDefinition.convertUnit)) {
    throw new Error(`Unknown conversion between ${unit.unit} and ${preferredUnit.unit}`);
  }

  value = convert(value).from(unitDefinition.convertUnit).to(preferredUnitDefinition.convertUnit);
  const formattedValue = value.toFixed(decimalPoints);
  return `${formattedValue} ${preferredUnitDefinition.symbol}`;
}

export function GetFullUnitDisplay(unit: UnitMeasurement | undefined, decimalPoints?: number): string {
  if (!unit || !unit.value) {
    return '';
  }

  const unitDefinition = UnitDefinitions[unit.unit];
  const formattedValue = unit.value.toFixed(decimalPoints);
  return `${formattedValue} ${unitDefinition.symbol}`;
}

function ExtractUnit(unit: UnitType | UnitMeasurement): UnitDefinition {
  if (typeof unit === 'object' && 'unit' in unit) {
    return UnitDefinitions[unit.unit];
  }

  return UnitDefinitions[unit as UnitType] || undefined;
}

export function GetUnitSymbol(unit: UnitType | undefined | UnitMeasurement): string {
  if (!unit) {
    return '';
  }

  return ExtractUnit(unit)?.symbol || '';
}

export function GetConvertUnitOrDefault(unit: UnitType | undefined | UnitMeasurement): Unit | undefined {
  if (!unit) {
    return undefined;
  }

  return ExtractUnit(unit)?.convertUnit;
}

export function GetConvertUnit(unit: UnitType | UnitMeasurement): Unit {
  if (!unit) {
    throw new Error('Unit Not definmed');
  }

  const result = ExtractUnit(unit)?.convertUnit;
  if (!result) {
    throw new Error(`Cannot find convert unit for ${unit}`);
  }

  return result;
}
