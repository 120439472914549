import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GetUnassignedSprayLogsDocument,
  Job,
  JobFilterInput,
  JobSortInput,
  useJobsByOrganisationIdQuery,
} from '../../../graphql/generated';
import PermissionGuard from '../../../shared/components/PermissionGuard';
import SearchSortFilterBar from '../../../shared/components/input/SearchSortFilterBar';
import RSButton from '../../../shared/components/input/rs-button/RSButton';
import RSBadge from '../../../shared/components/tags/RSBadge';
import { localRoutingConstants } from '../../../shared/constants/LocalRoutingConstants';
import useOrganisationId from '../../../shared/hooks/UseOrganisationId';
import useSearchSortFilter from '../../../shared/hooks/UseSearchSortFilter';
import RSTable, { BatchOperation, BatchSelection, RSTableColumnDefinition } from '../../../shared/layout/table/RSTable';
import GridTile from '../../../shared/layout/tiles/GridTile';
import RSGrid from '../../../shared/layout/tiles/RSGrid';
import apolloClient from '../../../shared/utilities/ApolloClient';
import { AuthPolicy } from '../../../shared/utilities/AuthPolicy';
import BatchJobExportDialog from '../components/BatchJobExportDialog';
import JobFormDialog from '../components/JobFormDialog';
import LocationTag from '../components/LocationTag';
import UnassignedDeviceLogs from '../components/UnassignedDeviceLogs';
import { jobStatusToBadgeType, jobStatusToText } from '../utilities/JobStatusUtilities';

export default function JobsPage() {
  const navigate = useNavigate();
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [showBatchExport, setShowBatchExport] = useState<boolean>(false);
  const [currentBatch, setCurrentBatch] = useState<BatchSelection | null>(null);
  const currentOrganisation = useOrganisationId();

  const { searchString, where, setSearchString, order, setSort } = useSearchSortFilter<JobFilterInput>({
    searchableFieldNames: ['name', 'description', 'location.address'],
    initialSort: { fieldName: 'endTime', ascending: false },
  });

  const { data, loading, fetchMore } = useJobsByOrganisationIdQuery({
    variables: {
      id: currentOrganisation,
      where: where,
      order: order,
    },
  });

  const [job, setJob] = useState<Partial<Job>>();
  const setExistingJobToUpdateHandler = (job: Partial<Job>) => {
    setJob(job);
    setShowCreate(true);
  };

  //columns for the table
  const cols = [
    {
      title: 'Name',
      id: 'name',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: Type instantiation is excessively deep and possibly infinite
      accessor: (x) => x.name,
    },
    {
      title: 'Description',
      id: 'description',
      accessor: (x) => x.description,
    },
    {
      title: 'Location',
      id: 'location',
      accessor: (x) => <LocationTag location={x.location} />,
    },
    {
      title: 'Status',
      id: 'status',
      accessor: (x) => <RSBadge title={jobStatusToText[x.status]} type={jobStatusToBadgeType[x.status]} />,
    },
    {
      title: 'Start Time',
      id: 'startTime',
      accessor: (x) => (x.startTime ? moment(x.startTime).toLocaleString() : 'N/A'),
    },
    {
      title: 'End Time',
      id: 'endTime',
      accessor: (x) => (x.endTime ? moment(x.endTime).toLocaleString() : 'N/A'),
    },
  ] as Array<RSTableColumnDefinition<Job>>;

  //batch operations the user can do
  const actions = [
    {
      title: 'Batch Export',
      permission: AuthPolicy.JobManagement,
      action: (selection) => {
        setCurrentBatch(selection);
        setShowBatchExport(true);
      },
    },
  ] as Array<BatchOperation<Job>>;

  return (
    <>
      <RSGrid>
        <GridTile
          colSpan={6}
          title={'All Jobs'}
          actions={
            <>
              <PermissionGuard policy={AuthPolicy.OrganisationMember}>
                <RSButton onClick={() => navigate(localRoutingConstants.myJobs.root)}>My Jobs</RSButton>
              </PermissionGuard>
              <PermissionGuard policy={AuthPolicy.OrganisationManagement}>
                <RSButton onClick={() => navigate(localRoutingConstants.sharedJobs.root)}>Shared Jobs</RSButton>
              </PermissionGuard>
              <PermissionGuard policy={AuthPolicy.JobManagement}>
                <RSButton onClick={() => setShowCreate(true)}>
                  <FontAwesomeIcon icon={faPlus} />
                  Create Job
                </RSButton>
              </PermissionGuard>
            </>
          }
        >
          <SearchSortFilterBar<JobSortInput>
            searchString={searchString}
            onChangeSearch={setSearchString}
            onChangeSort={setSort}
            sortOptions={['name', 'startTime', 'endTime', 'status']}
            initialOption={order}
          />
          <RSTable<Job>
            isLoading={loading}
            columns={cols}
            data={(data?.jobsByOrganisationId?.edges?.map((e) => e.node) as Job[]) ?? []}
            totalCount={data?.jobsByOrganisationId?.totalCount as number}
            isChecked={true}
            batchOperationActions={actions}
            fetchMore={async (_, pageSize, increment) => {
              await fetchMore({
                variables: {
                  first: pageSize,
                  after: increment ? (data?.jobsByOrganisationId?.pageInfo.endCursor as string) : undefined,
                  where: where,
                  order: order,
                },
              });
            }}
          />
        </GridTile>
        <PermissionGuard policy={AuthPolicy.JobManagement}>
          <UnassignedDeviceLogs setJob={setExistingJobToUpdateHandler} />
        </PermissionGuard>
      </RSGrid>
      <PermissionGuard policy={AuthPolicy.JobManagement} isVisible={showCreate}>
        <JobFormDialog
          currentOrganisation={currentOrganisation}
          isOpen={true}
          onClose={(submitted) => {
            if (submitted && job) {
              setJob(undefined);

              apolloClient.refetchQueries({
                include: [GetUnassignedSprayLogsDocument],
              });
            }
            return setShowCreate(false);
          }}
          initialValues={job}
        />
      </PermissionGuard>
      <PermissionGuard policy={AuthPolicy.JobManagement} isVisible={showBatchExport}>
        {currentBatch && (
          <BatchJobExportDialog
            isOpen={showBatchExport}
            onClose={() => {
              setShowBatchExport(false);
              setCurrentBatch(null);
            }}
            selectAll={false}
            selection={currentBatch}
            organizationId={currentOrganisation}
          />
        )}
      </PermissionGuard>
    </>
  );
}
