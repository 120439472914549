import convert from 'convert-units';
import * as Yup from 'yup';
import { CreateDeviceInput, FirmwareLockStatus, UnitSystem, UnitType } from '../../../graphql/generated';
import { DeviceIdLength, MaximumNameLength, MongoIdLength } from '../../../shared/constants/ValidationConstants';
import { GetConvertUnit } from '../../../shared/utilities/UnitConversionUtilities';
import { UnitInclusiveBetween, ValidateUnit } from '../../../shared/validations/unitValidation';

export const DeviceBounds = {
  calibrationTimeout: { min: 1, max: 60 },
  calibrationVolume: { min: 1, max: 10, validationUnit: UnitType.Litre },
  rinseVolume: { min: 1, max: 50, validationUnit: UnitType.Litre },
  rinseTimeout: { min: 1, max: 60 },
  loggingPeriod: { min: 1, max: 300 },
  sleepTimeout: { min: 1, max: 71580 },
  gpsAccuracy: { min: 1, max: 1000, validationUnit: UnitType.Metre },
};

export const UnitSystemToDefaultDeviceUnit: {
  [key in UnitSystem]: { calibrationVolume: UnitType; rinseVolume: UnitType; gpsAccuracy: UnitType };
} = {
  [UnitSystem.Imperial]: {
    calibrationVolume: UnitType.UsGallon,
    rinseVolume: UnitType.UsGallon,
    gpsAccuracy: UnitType.Feet,
  },
  [UnitSystem.Metric]: {
    calibrationVolume: UnitType.Litre,
    rinseVolume: UnitType.Litre,
    gpsAccuracy: UnitType.Metre,
  },
};

export function GetDefaultDevice(system: UnitSystem): CreateDeviceInput {
  const units = UnitSystemToDefaultDeviceUnit[system];

  return {
    imei: '',
    alias: '',
    calibrationVolume: {
      value: convert(2)
        .from(GetConvertUnit(DeviceBounds.calibrationVolume.validationUnit))
        .to(GetConvertUnit(units.calibrationVolume)),
      unit: units.calibrationVolume,
    },
    calibrationTimeout: 10,
    rinseVolume: {
      value: convert(20)
        .from(GetConvertUnit(DeviceBounds.rinseVolume.validationUnit))
        .to(GetConvertUnit(units.rinseVolume)),
      unit: UnitSystemToDefaultDeviceUnit[system].rinseVolume,
    },
    rinseTimeout: 10,
    loggingPeriod: 3,
    sleepTimeout: 30,
    gpsAccuracy: {
      value: convert(3)
        .from(GetConvertUnit(DeviceBounds.gpsAccuracy.validationUnit))
        .to(GetConvertUnit(units.gpsAccuracy)),
      unit: UnitSystemToDefaultDeviceUnit[system].gpsAccuracy,
    },
    firmwareLockStatus: FirmwareLockStatus.Unlocked,
    firmwareId: '',
  };
}

export const deviceRegistrationValidator = Yup.object({
  imei: Yup.string().required().length(DeviceIdLength).default(''),
  alias: Yup.string().required().max(MaximumNameLength).default(''),
  calibrationVolume: UnitInclusiveBetween(
    ValidateUnit(),
    DeviceBounds.calibrationVolume.validationUnit,
    DeviceBounds.calibrationVolume.min,
    DeviceBounds.calibrationVolume.max,
  ),
  calibrationTimeout: Yup.number()
    .required()
    .positive()
    .integer()
    .min(DeviceBounds.calibrationTimeout.min)
    .max(DeviceBounds.calibrationTimeout.max)
    .typeError('Calibration Timeout must be a number')
    .default(10),
  rinseVolume: UnitInclusiveBetween(
    ValidateUnit(),
    DeviceBounds.rinseVolume.validationUnit,
    DeviceBounds.rinseVolume.min,
    DeviceBounds.rinseVolume.max,
  ),
  rinseTimeout: Yup.number()
    .required()
    .positive()
    .integer()
    .min(DeviceBounds.rinseTimeout.min)
    .max(DeviceBounds.rinseTimeout.max)
    .typeError('Rinse Timeout must be a number')
    .default(10),
  loggingPeriod: Yup.number()
    .required()
    .positive()
    .integer()
    .min(DeviceBounds.loggingPeriod.min)
    .max(DeviceBounds.loggingPeriod.max)
    .typeError('Logging Period must be a number')
    .default(3),
  sleepTimeout: Yup.number()
    .required()
    .positive()
    .integer()
    .min(DeviceBounds.sleepTimeout.min)
    .max(DeviceBounds.sleepTimeout.max)
    .typeError('Sleep Timeout must be a number')
    .default(30),
  gpsAccuracy: UnitInclusiveBetween(
    ValidateUnit(),
    DeviceBounds.gpsAccuracy.validationUnit,
    DeviceBounds.gpsAccuracy.min,
    DeviceBounds.gpsAccuracy.max,
  ),
  firmwareLockStatus: Yup.mixed<FirmwareLockStatus>()
    .oneOf([FirmwareLockStatus.Locked, FirmwareLockStatus.Unlocked])
    .default(FirmwareLockStatus.Unlocked),
  firmwareId: Yup.string().required().length(MongoIdLength),
});
export const adminDeviceUpdateValidator = deviceRegistrationValidator
  .shape({
    id: Yup.string().required().length(DeviceIdLength),
  })
  .omit(['imei']);

export const deviceUpdateValidator = adminDeviceUpdateValidator.omit([
  'imei',
  'calibrationVolume',
  'calibrationTimeout',
  'loggingPeriod',
  'firmwareLockStatus',
  'firmwareId',
]);
