import { Autocomplete, GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useFormikContext } from 'formik';
import { uniqueId } from 'lodash';
import { CSSProperties, useMemo, useState } from 'react';
import { Location, LocationInput } from '../../../graphql/generated';
import BubbleLoader from '../../layout/loading/BubbleLoader';
import FormLabel from '../forms/FormLabel';
import env from '@beam-australia/react-env';

const mapContainerStyle: CSSProperties = {
  height: '400px',
  width: 'w-full',
};

interface MapWithAutoCompleteProps {
  name: string;
  location?: Location;
}

type Libraries = 'places'[];
const libraries: Libraries = ['places'];
const zoomValue = 15;
export default function MapWithAutoCompleteWrapper(props: MapWithAutoCompleteProps) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: env('GOOGLE_MAP_KEY') ?? '',
    libraries: libraries,
  });

  return isLoaded ? <MapWithAutoComplete {...props} /> : <BubbleLoader />;
}

function MapWithAutoComplete(props: MapWithAutoCompleteProps) {
  const form = useFormikContext();
  const [map, setMap] = useState<google.maps.Map | undefined>();

  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
  const geocoder = useMemo(() => {
    return new google.maps.Geocoder();
  }, []);
  const [inputValue, setInputValue] = useState<string>(props.location?.address ?? '');

  const [center, setCenter] = useState<Location>({
    latitude: props.location?.latitude ?? 0,
    longitude: props.location?.longitude ?? 0,
    address: props.location?.address ?? '',
  });

  const [markerVisible, setMarkerVisible] = useState<boolean>(props.location ? true : false);
  const [showInfoWindow, setShowInfoWindow] = useState<boolean>(props.location ? true : false);

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete?.getPlace();
      if (place) {
        const location = {
          latitude: place?.geometry?.location?.lat() ?? 0,
          longitude: place?.geometry?.location?.lng() ?? 0,
          address: place.formatted_address ?? '',
        };
        const latLng = LocationToLatLng(location);
        updateMapViewAndFormValues(latLng);
      }
    }
  };

  //Helper function to handle updating the map view as well as updating the form
  async function updateMapViewAndFormValues(latLng: google.maps.LatLngLiteral) {
    const address = (await geocoder.geocode({ location: latLng })).results[0]?.formatted_address ?? undefined;
    const location: Location = {
      latitude: latLng.lat,
      longitude: latLng.lng,
      address: address,
    };
    setCenter(location);
    setMarkerVisible(true);
    map?.setZoom(zoomValue);
    setFieldValue(location);
  }

  function LocationToLatLng(location: LocationInput) {
    return {
      lat: location.latitude,
      lng: location.longitude,
    };
  }

  //Sets Value for the for the form then sets the text in that input
  function setFieldValue(location: Location) {
    form.setFieldValue(props.name, location);
    setInputValue(location.address);
  }

  const onAutocompleteLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onMapLoad = (map: google.maps.Map) => {
    setMap(map);
    handleSetCurrentLocation();
  };

  //Listens to when centre changes and then updates for the map
  const latLng = useMemo(() => {
    return LocationToLatLng(center);
  }, [center]);

  //Uses navigateor to get the current position if it has been allows and then sets that on the map
  const handleSetCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      const location: Location = {
        latitude,
        longitude,
        address: '',
      };

      geocoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
        if (results) {
          if (status === 'OK' && results[0]) {
            const latLng = LocationToLatLng(location);
            updateMapViewAndFormValues(latLng);
          }
        }
      });
    });
    //Sets zoom on intial load
    map?.setZoom(zoomValue);
  };

  return (
    <div>
      <div id="searchbox">
        <FormLabel label="Location" />
        <Autocomplete
          onPlaceChanged={onPlaceChanged}
          onLoad={onAutocompleteLoad}
          fields={['geometry.location', 'name', 'formatted_address']}
        >
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="rs-form-input w-full pl-4"
            type="text"
            placeholder="Job location"
          />
        </Autocomplete>
      </div>
      <div>
        <GoogleMap
          mapTypeId="hybrid"
          center={latLng}
          zoom={zoomValue}
          mapContainerStyle={mapContainerStyle}
          onLoad={onMapLoad}
          onClick={(e) => {
            const latLng: google.maps.LatLngLiteral = {
              lat: e.latLng?.lat() ?? 0,
              lng: e.latLng?.lng() ?? 0,
            };
            updateMapViewAndFormValues(latLng);
          }}
        >
          <Marker
            key={uniqueId()}
            title={`Latitude: ${center.latitude.toFixed(3)}, Longitude: ${center.longitude.toFixed(3)}`}
            position={latLng}
            visible={markerVisible}
            onClick={() => setShowInfoWindow(true)}
          />
          {showInfoWindow && (
            <InfoWindow key={'infoWindow'} position={latLng} onCloseClick={() => setShowInfoWindow(false)}>
              <div>{center.address}</div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </div>
  );
}
