import { toast } from 'react-toastify';
import { jobUpdateValidator, jobValidator } from '../validation/JobValidator';
import {
  CreateJobInput,
  GetJobDocument,
  Job,
  JobsByOrganisationIdDocument,
  JobsByOrganisationIdEdge,
  JobsByOrganisationIdQuery,
  JobsByOrganisationIdQueryVariables,
  UpdateJobInput,
  useCreateJobMutation,
  useUpdateJobMutation,
} from '../../../graphql/generated';
import JobFormContent from './JobFormContent';
import moment from 'moment';
import { updatePagedQuery } from '../../../shared/utilities/ApolloCacheUtilities';
import { DateTimeWithTimezone } from '../../../shared/utilities/TimeUtilities';

type Props = {
  currentOrganisation: string;
  initialValues?: Partial<Job>;
  isOpen: boolean;
  onClose: (submitted: boolean) => void;
};

export default function JobFormDialog({ currentOrganisation, initialValues, isOpen, onClose }: Props) {
  const [createJob] = useCreateJobMutation();
  const [updateJob] = useUpdateJobMutation();

  if (initialValues && initialValues.id) {
    return (
      <JobFormContent<UpdateJobInput>
        currentOrganisation={currentOrganisation}
        isEdit
        initialValues={initialValues}
        initialValuesMapper={(initialValues) =>
          jobUpdateValidator.cast(
            {
              ...initialValues,
              startTime: initialValues?.startTime ? moment(initialValues.startTime) : undefined,
              endTime: initialValues?.endTime ? moment(initialValues.endTime) : undefined,
              weedAssignments: initialValues?.weedAssignments?.map((assignment) => assignment?.id),
              deviceAssignments: initialValues?.deviceAssignments?.map((assignment) => ({
                deviceId: assignment?.device.id,
                userId: assignment?.user.id,
              })),
              recipeId: initialValues?.recipe?.id,
            },
            { stripUnknown: true },
          ) as UpdateJobInput
        }
        validator={jobUpdateValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          await updateJob({
            variables: {
              input: {
                ...value,
                startTime: moment(value.startTime).format(DateTimeWithTimezone),
                endTime: moment(value.endTime).format(DateTimeWithTimezone),
              },
            },
            onCompleted(data) {
              toast(`A job with name - ${data.updateJob.name} has been successfully updated!`, {
                type: 'success',
              });
            },
            refetchQueries: [GetJobDocument],
          });
        }}
      />
    );
  } else {
    return (
      <JobFormContent<CreateJobInput>
        currentOrganisation={currentOrganisation}
        initialValues={initialValues}
        initialValuesMapper={(initialValues) =>
          jobValidator.cast(
            {
              ...initialValues,
              organisationId: currentOrganisation,
              deviceAssignments: initialValues?.deviceAssignments?.map((assignment) => ({
                deviceId: assignment?.device.id,
              })),
            },
            { stripUnknown: true },
          ) as CreateJobInput
        }
        validator={jobValidator}
        onClose={onClose}
        isOpen={isOpen}
        onSubmit={async (value) => {
          await createJob({
            variables: {
              input: {
                ...value,
                startTime: moment(value.startTime).format(DateTimeWithTimezone),
                endTime: moment(value.endTime).format(DateTimeWithTimezone),
              },
            },
            onCompleted(data) {
              toast(`A new job with name - ${data.createJob.name} has been successfully created!`, {
                type: 'success',
              });
            },
            update: (_, { data }) =>
              updatePagedQuery<
                Job,
                JobsByOrganisationIdQuery,
                JobsByOrganisationIdQueryVariables,
                JobsByOrganisationIdEdge
              >(
                data?.createJob as Job,
                JobsByOrganisationIdDocument,
                (query) => (query?.jobsByOrganisationId?.edges as JobsByOrganisationIdEdge[]) ?? [],
                'jobsByOrganisationId',
                {
                  id: currentOrganisation,
                },
              ),
          });
        }}
      />
    );
  }
}
