import { Chemical, useGetRecipeUsageSummaryQuery } from '../../../graphql/generated';
import GridTile from '../../../shared/layout/tiles/GridTile';
import ReactECharts from 'echarts-for-react';
import { useMemo } from 'react';
import DateOfForm, { DateOfType } from '../../../shared/components/forms/DateOfForm';
import moment from 'moment';
import { DateTimeWithTimezone } from '../../../shared/utilities/TimeUtilities';
import {
  CalculateAmountUsed,
  concentrationUnitUsageForChemicalType,
  ConcentrationYAxis,
} from '../../recipes/utilities/ConcentrationUtilities';
import useSelectedOrganisationIdStore from '../../../shared/hooks/stores/UseSelectedOrganisationIdStore';
import { GetUnitSymbol } from '../../../shared/utilities/UnitConversionUtilities';

interface RecipeUsageSummaryGraphProps {
  organisationId: string;
}

export default function RecipeUsageSummaryGraph({ organisationId }: RecipeUsageSummaryGraphProps) {
  const monthOf = useMemo(() => moment(), []);
  const { unitSystem } = useSelectedOrganisationIdStore();

  const { data, refetch } = useGetRecipeUsageSummaryQuery({
    variables: {
      input: {
        organisationId,
        // Use the start of the month so that new queries aren't made for the same month based on a change in day
        monthOf: monthOf.clone().startOf('month').format(DateTimeWithTimezone),
      },
    },
  });

  const { dates, series, chemicals } = useMemo(() => {
    const dates: Array<string> = [];
    const chemicalUsageMap = new Map<string, echarts.BarSeriesOption>();
    const chemicals: { [x: string]: Chemical } = {};

    // Get the data for each chemical in the recipe to display
    const numRecipes = data?.recipeUsageSummary.length ?? 0;
    data?.recipeUsageSummary.forEach((summary, index) => {
      // Get the dates to display
      dates.push(summary.recipe.name);

      // How many hundreds of litres were sprayed of this recipe
      summary.recipe.concentrations.forEach((concentration) => {
        const chemicalId = concentration.chemical.id;
        //TODO: Verify this works
        const volume = summary.volume ? CalculateAmountUsed(summary.volume, concentration.amount) ?? 0 : 0;

        // See if there is already information for the chemical that has been processed
        const chemicalData = chemicalUsageMap.get(chemicalId);
        if (chemicalData && chemicalData.data) {
          // Set the appropriate volume used for the appropriate recipe
          chemicalData.data[index] = volume;
        } else {
          // Construct a new array and fill it 0 for instances where the chemical wasn't used in a recipe
          const data = new Array<number>(numRecipes).fill(0);
          // Set the appropriate volume used for the appropriate recipe
          data[index] = volume;

          chemicals[chemicalId] = concentration.chemical as Chemical;

          // Add a series for the chemical
          chemicalUsageMap.set(chemicalId, {
            id: chemicalId,
            name: concentration.chemical.name,
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
              formatter: (ctx) => {
                const val = ctx.value as number;
                return val !== 0
                  ? `${val.toFixed(2)} ${GetUnitSymbol(
                      concentrationUnitUsageForChemicalType[concentration.chemical.type][unitSystem],
                    )}`
                  : '';
              },
            },
            emphasis: {
              focus: 'series',
            },
            data: data,
          });
        }
      });
    });
    return { dates, series: chemicalUsageMap, chemicals };
  }, [data]);

  const options: echarts.EChartsOption = {
    tooltip: {
      show: true,
      formatter: (params) => {
        const chemicalId = (params as { seriesId: string }).seriesId;
        const chemical = chemicals[chemicalId];
        const total = ((series.get(chemicalId)?.data ?? []) as number[]).reduce((p, c) => (p += c), 0);
        return `Total: ${total.toFixed(2)} ${GetUnitSymbol(
          concentrationUnitUsageForChemicalType[chemical.type][unitSystem],
        )}`;
      },
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: '#999',
        },
      },
    },
    legend: {
      top: '3%',
      type: 'scroll',
    },
    grid: {
      left: '3%',
      right: '3%',
      bottom: '3%',
      containLabel: true,
    },
    yAxis: [
      {
        type: 'value',
        name: ConcentrationYAxis(unitSystem),
        nameRotate: 90,
        nameLocation: 'middle',
      },
    ],
    xAxis: {
      type: 'category',
      data: dates,
    },
    series: Array.from(series.values()),
  };

  return (
    <GridTile
      className="col-span-3 md:col-span-6"
      title={'Monthly Chemical Usage'}
      actions={
        <DateOfForm
          initialDate={monthOf}
          type={DateOfType.month}
          onSubmit={({ dateOf }) =>
            refetch({
              input: {
                organisationId,
                monthOf: moment(dateOf).startOf('month').format(DateTimeWithTimezone),
              },
            })
          }
        />
      }
    >
      <ReactECharts option={options} notMerge={true} />
    </GridTile>
  );
}
