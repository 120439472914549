import { faBug, faComments, faCircleQuestion, faMicrochip } from '@fortawesome/free-solid-svg-icons';

export const SupportContent = [
  {
    title: 'Request a new feature for the platform',
    children: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim erat, tincidunt sed mattis in,
              condimentum a lectus. Sed at nisi elit. Nunc dictum arcu vulputate leo aliquam tincidunt. Aenean posuere
              metus blandit pulvinar sagittis. Nullam hendrerit risus non mi semper venenatis. Morbi finibus, velit non
              luctus condimentum, tortor dui pharetra tellus, ut finibus sapien diam sit amet diam. Cras vehicula neque
              in turpis pretium, nec tempus erat fermentum.`,
    icon: faComments,
  },
  {
    title: 'Report a bug',
    children: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim erat, tincidunt sed mattis in,
              condimentum a lectus. Sed at nisi elit. Nunc dictum arcu vulputate leo aliquam tincidunt. Aenean posuere
              metus blandit pulvinar sagittis. Nullam hendrerit risus non mi semper venenatis. Morbi finibus, velit non
              luctus condimentum, tortor dui pharetra tellus, ut finibus sapien diam sit amet diam. Cras vehicula neque
              in turpis pretium, nec tempus erat fermentum.`,
    icon: faBug,
  },
  {
    title: 'Ask a question about the platform',
    children: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim erat, tincidunt sed mattis in,
              condimentum a lectus. Sed at nisi elit. Nunc dictum arcu vulputate leo aliquam tincidunt. Aenean posuere
              metus blandit pulvinar sagittis. Nullam hendrerit risus non mi semper venenatis. Morbi finibus, velit non
              luctus condimentum, tortor dui pharetra tellus, ut finibus sapien diam sit amet diam. Cras vehicula neque
              in turpis pretium, nec tempus erat fermentum.`,
    icon: faCircleQuestion,
  },
  {
    title: 'Purchase additional UFT devices for your oganisation',
    children: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim erat, tincidunt sed mattis in,
              condimentum a lectus. Sed at nisi elit. Nunc dictum arcu vulputate leo aliquam tincidunt. Aenean posuere
              metus blandit pulvinar sagittis. Nullam hendrerit risus non mi semper venenatis. Morbi finibus, velit non
              luctus condimentum, tortor dui pharetra tellus, ut finibus sapien diam sit amet diam. Cras vehicula neque
              in turpis pretium, nec tempus erat fermentum.`,
    icon: faMicrochip,
  },
];
